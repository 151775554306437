import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL;
// const token = localStorage.getItem("token");
// const token = JSON.parse(localStorage.getItem('authUser'))

const secretKey = process.env.REACT_APP_API_KEY;

// ==================== All Api Function ===================
export const checkAuthentication = async (token) => {
  return token ? Promise.resolve() : Promise.reject("Authentication failed");
};
const makeApiRequest = async (url, method, formData) => {
  try {
      const token = JSON.parse(localStorage.getItem('authUser'));
      await checkAuthentication(token);
    const response = await axios({
      method,
      url: `${API_BASE_URL}${url}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const makeNonAuthApiRequest = async (url, method, formData) => {
  try {
    const response = await axios({
      method,
      url: `${API_BASE_URL}${url}`,
      data: formData,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};


export const fetchUserProfile = async () => {
    return makeApiRequest("/api/v2/get/getvault/getMerchantProfileData", "get");
  };

  // merchant profile data
  export const fetchMerchantProfile= async () => {
    return makeApiRequest("/api/v2/get/getvault/gMerchantProfile", "get");
  }

   export const updateprofileData = async (formData) => {
    return makeApiRequest("/api/v2/get/getvault/updateprofileData", "put", formData);
  }
  // get all merchant payment

  export const fetchmerchantPayment = async (formData)=>{
    return makeApiRequest("/api/v2/get/getvault/getMerchantPaymentTransactions", "post", formData)
  }
  
  export const fetchmerchantRefundPaynmentDetails = async (formData)=>{
    return makeApiRequest("/api/v2/get/getvault/getMerchantRefundPaymentDetails", "post", formData)
  }

  // ------------- withdrawal -----------------
  // add bank account
  export const addbankAccount = async (formData) =>{
    return makeApiRequest("/api/v2/get/getvault/addMerchantBankDetails", "post", formData)
  }

  // get merchant bank details
  export const getMerchantBankDetailsapi = async()=>{
    return makeApiRequest("/api/v2/get/getvault/getMerchantsAllBankDetails","get")
  }

// delete merchant bank details 
export const deleteMerchantbankDetails = async (formData)=>{
  return makeApiRequest("/api/v2/get/getvault/deleteMerchantbankDetails", "delete", formData)
}
export const postWalletToBankTransfer = async (formData)=>{
  return makeApiRequest("/api/v2/get/getvault/merchantBankWithdrawal", "post", formData)
}

// get merchant bank names
export const getMerchantBank_names = async (formData)=>{
  return makeApiRequest("/api/v2/get/getvault/getMerchantBanks","get")
}
// get all merchant bank details 
export const getMerchantBankDetailsForSelect = async ()=>{
  return makeApiRequest("/api/v2/get/getvault/getMerchantBankDetailsForInputSelect", "get")
}

// get bank details using name
export const getMerchantBankDetailsUsingNameapi = async (formData)=>{
  return makeApiRequest("/api/v2/get/getvault/getMerchantssBankDetailsUsingBankName", "post", formData)
}

// deposit money

export const postBankToWallet = async (formData)=>{
  return makeApiRequest("/api/v2/get/getvault/addmerchanttopupAmount", "post", formData)
}

// send mooney to user wallet
export const sendMoneyToUserWallet = async (formData)=>{
  return makeApiRequest("/api/v2/get/getvault/merchantSendMoney", "post", formData)
}

// Request money 
export const requestMoneyFromMerchantToUser = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/requestMoneyMerchantToUser", "post", formData)
}
 
// get All Merchant transaction history

export const getAllTransactionHistory = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/getAllMerchantTransactionDetails", "post", formData)
}
// update the merchant profile
export const updateTheUserProfile = async (formData)=>{
return makeApiRequest("/api/v2/get/getvault/updateMerchantprofileImage", "put", formData) 
}

// check merchant pin

export const fetchCheckmerchantSecreatePIn =async () =>{
  return makeApiRequest("/api/v2/get/getvault/checkScreatePinSetOrNotForMerchant", "get")
}
// send otp by twilio 
export const generateotpForTheMerchantSecreatPin = async () =>{
  return makeApiRequest("/api/v2/get/getvault/generateotpForTheMerchantSecreatPin", "get")
}
// create and set secrat pin

export const setAndUpdateThesecreatePin = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/createandSetscreatePinForMerchant", "post", formData)
} 

// currency convert

export const currencyConvert = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/currencyConversion", "post", formData)
}

// update and change password
 export const updateAndChangeThePassword  = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/updateAndChangeMerchantLoginPassword", "put", formData)
 }

 // Reset merchant pin
 export const ResetMerchantPin = async (formData) =>{
  console.log(formData);
  return makeApiRequest("/api/v2/get/getvault/resetMerchantTransactionPin", "put", formData)
 }

 // get deposit money history
 export const getAllDeposithistory = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/getdepositMoneyTransaction", "post", formData)
 }
 
 // get Request money istory
 export const getRequestMoneyHistoryApi = async (formData) =>{
  return makeApiRequest("/api/v2/get/merchants/getRequestMoneyHistory", "post", formData)
 }
 
 // update merchant logo
 export const updateTheLogoOfMerchant = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/updateMerchantLogo","put",formData)
 }

 // get logo of metrchant
 export const fetchLogoOfmerchant = async () =>{
return makeApiRequest("/api/v2/get/getvault/merchantLogo","get")
 }

 // lock the screen 
 export const dashboardLockApi = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/merchantVaultScreenLock","put", formData)
 }
 // check lock screen status 
 export const fetchForTheLockScreen = async () =>{
  return makeApiRequest("/api/v2/get/getvault/merchantVaultLockAndUnlockCheck","get")
 }
 //locl and password check
 export const postApiForUnLockScreenAndPassword = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/merchantVaultLockAndPasswordCompare","post", formData)
 }

 // read notification
 export const ReadNotificationStatus = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/readMerchantNotification", "put", formData)
 }

 
// deposit money

export const checkTaxCalclations = async (formData)=>{
  return makeApiRequest("/api/v2/get/usersmodal/getTaxCalaculationWhileEnteringTheAmount", "post", formData)
}

// register Status Check
export const merchantVaulRegisterStatusCheck = async(formData)=>{
  return makeApiRequest("/api/v2/get/getvault/merchantVaulRegisterStatus", "get", formData)
}

// update register form
export const updateMerchantRegisterForm = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/updateRegisterForm", "post", formData)
}


export const refundAmount = async (formData)=>{
  return makeApiRequest("/api/v2/get/getvault/refundAmount", "post",formData)
}

// dashboard

export const getDashboardWidget = async (formData)=>{
  return makeApiRequest("/api/v2/get/getvault/getDashboardWidget", "post", formData)
}


export const gTotalTranscationPerDay = async (formData)=>{
  return makeApiRequest("/api/v2/get/getvault/getTotalTranscationPerDay", "get")
}

export const gTotalInsights= async ()=>{
  return makeApiRequest("/api/v2/get/getvault/getTotalInsights", "get")
}

export const gNoOfTransaction = async ()=>{
  return makeApiRequest("/api/v2/get/getvault/getNoOfTransaction", "get")
}

// Refund Tax calculation
export const refundTaxCalclations = async (formData)=>{
  return makeApiRequest("/api/v2/get/getvault/getTaxCalaculationForRefund", "post", formData)
}

// add and create merchant role`
export const AddAndCreatemerchantRoles = async (formData)=>{
  return makeApiRequest("/api/v2/get/getvault/merchantRoles", "post", formData)
}

// get Merchant Roles
export const fMerchantRoles = async ()=>{
  return makeApiRequest("/api/v2/get/getvault/getMerchantRoles", "get")
}

// merchant role delete
export const merchantRoleDelete = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/deleteMerchantRoles", "delete", formData)
}

// fetch edit data
export const fMerchantRoleEditData = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/getMerchantEditRole", "post", formData)
}

// get Roles For Select box
export const fRolesForSelectBox = async () =>{
  return makeApiRequest("/api/v2/get/getvault/getMerchantRolesForSelectBox", "get",)
}

// get Roles For Select box
export const merchantPermissionsForSubAdmin = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/merchantPermission", "post", formData)
}


export const fMerchantPermission = async () =>{
  return makeApiRequest("/api/v2/get/getvault/getMerchantPermission", "get",)
}

// get permission for Edit
export const getPermissionForEdit = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/getMerchantEditPermission", "post", formData)
}


// delete permission
export const deletePermission = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/deleteMerchantPermission", "delete", formData)
}

// add And update Offers
export const updateAndAddMerchantOffers = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/merchantOffersAddAndUpdate", "post", formData)
}

export const gMerchantType = async () =>{
  return makeApiRequest("/api/v2/get/getvault/getMerchantType", "get")
}

// get Merchant offers
export const gMerchantOffers = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/getMerchantOffers","post", formData)
}

// get Merchant offers for Edit
export const gMerchantOffersForEdit = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/getMerchantOffersForEdit","post",formData)
}

// Update offers
export const updateTheCampaignStatus = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/updateCampaignStatus","put", formData)
}

// delete Offers
export const deleteOffers = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/deleteCampaign","delete", formData)
}


export const gMerchantOfferRedeemed = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/getMerchantRedeemedHistory","post", formData)
}

// get merchant privacy and polict

// export const gMerchantPolicy = async (formData) =>{
//   return makeNonAuthApiRequest("/api/v2/get/getvault/getMerchantPolicy","post", formData)
// }

export const gMerchantPolicy = async (formData) =>{
  return makeNonAuthApiRequest("/api/v2/get/getvault/getlegal","post", formData)
}

// merchant pdf
export const sendEmailFromMerchant = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/merchantSendPdf","post", formData)
}

// reset merchant subAdmin password
export const  resetMerchantSubAdminPassword = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/resetSubAdminPasswords","post", formData)
}

export const updateSubAdminProfileData = async (formData) => {
  return makeApiRequest("/api/v2/get/getvault/updateSubProfileData", "put", formData);
}

// Sub Admin update and change password
export const updateAndChangeSubAdminPassword  = async (formData) =>{
  return makeApiRequest("/api/v2/get/getvault/updateAndChangeSubAdminLoginPassword", "put", formData)
 }
 

 export const gMerchantActiveLog  = async () =>{
  return makeApiRequest("/api/v2/get/getvault/getMerchantActiveLog", "post", )
 }
import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { fetchUserProfile } from "Context/ContextApi";
import 'toastr/build/toastr.min.css';
import dowloadIcon from "../../assets/images/NewImage/download.png"
import viewIcon from "../../assets/images/NewImage/icons8-eye-64.png"
import '@react-pdf-viewer/core/lib/styles/index.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import PDFViewer from "pdf-viewer-reactjs";


const API_BASE_URL = process.env.REACT_APP_API_URL;
function setting() {
  const [isPopupFornationalId, setIsPopupFornationalId] = useState(false);
  const [selectedImageDetails, setSelectedImageDetails] = useState(null);
  const [isNextandPrev, setIsNextandPrev] = useState("NationalIDImg1");
  const [userData, setUserData] = useState({})
  const [file, setFile] = useState();

  const [formDatass, setFormData] = useState({
    NationalIDno: "",
    AddressProofNo: "",
  });


  const onChange = (e) => {
    setFormData({ ...formDatass, [e.target.name]: e.target.value })
  }

  const handleOpenPopup = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    const imageUrl = `${API_BASE_URL}/${userData?.id}/kyc/${fileName}`;
    setIsPopupFornationalId(true);

    if (fileExtension === 'pdf') {
      setSelectedImageDetails({ fileName, imageUrl, isPdf: true });
      setFile(imageUrl)
    } else {
      setSelectedImageDetails({ fileName, imageUrl, isPdf: false });
    }

    setIsPopupFornationalId(true);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await fetchUserProfile();
      const result = response.result.result;
      setUserData(result);
      setFormData({
        NationalIDno: result.NationalIDno || "",
        AddressProofNo: result.AddressProofNo || "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownload = (url, fileName) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  document.title = "GET Platform  | Verification";


  // layout 
  const documentTypes = [
    { key: "NationalIDImg1", label: "National ID" },
    { key: "AddressProofImg1", label: "Address Proof" },
    { key: "BusinessAddressProofImg1", label: "Business Address Proof" },
    { key: "CertOfIncImg1", label: "Cert Of Inc" },
    { key: "MemorandumArticleImg1", label: "Memorandum Article" },
    { key: "TradingLicenceImg1", label: "Trading Licence" },
    { key: "UtilityImg1", label: "Utility" },
  ];

  const renderDocumentType = (item) => (
    <span
      key={item.key}
      className={isNextandPrev === item.key ? "setting_layout_text" : ""}
      onClick={() => setIsNextandPrev(item.key)}
    >
      {item.label}
    </span>
  );


  // verification image
  const renderImageSection = (imgKey, label) => (
    userData[imgKey] && (
      <div className="downloadImg_map_inside">
        <img
          src={`${API_BASE_URL}/${userData?.id}/kyc/${userData[imgKey]}`}
          alt="docs"
          className="Document-image"
        />
        <div className="download_img_icon_also_view">
          <img
            src={dowloadIcon}
            alt="download"
            onClick={() => handleDownload(`${API_BASE_URL}/${userData?.id}/kyc/${userData[imgKey]}`, userData[imgKey])}
            className="download_img_and_veiw_img"
          />
          <img
            src={viewIcon}
            alt="download"
            onClick={() => handleOpenPopup(userData[imgKey])}
            className="download_img_and_veiw_img"
          />
        </div>
      </div>
    )
  );

  const renderDocumentSection = (img1Key, img2Key, label, inputKey) => (
    isNextandPrev === img1Key && (
      <>
        <Row>
          <Col md={{ offset: 3, size: 6 }}>
            <div className="form_gruap_for_setting">
              <div className="form_group_for_inside_in_setting">
                <div className="form_drop_leabel_and_verified_text">
                  <label>{label}</label>
                  {inputKey && (<span>Verified</span>)}
                </div>
                {inputKey && (
                  <input
                    type="text"
                    placeholder={`Enter ${label}`}
                    name={inputKey}
                    value={formDatass[inputKey]}
                    onChange={onChange}
                  />
                )}
              </div>
              <div className="form_group_for_inside_in_setting">
                <div className="form_group_for_document_insetting_pagw">
                  <div className="dowload_img">
                    {renderImageSection(img1Key, label)}
                    {renderImageSection(img2Key, label)}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </>
    )
  );

  return (
    <>
      {isPopupFornationalId && selectedImageDetails && (
        <Modal isOpen={isPopupFornationalId} toggle={() => setIsPopupFornationalId(false)} size="lg">
          <ModalHeader toggle={() => setIsPopupFornationalId(false)}>
            {selectedImageDetails.isPdf ? 'PDF Document' : 'Image'}
          </ModalHeader>
          <ModalBody>
            {selectedImageDetails.isPdf ? (
              <>
                <PDFViewer
                  document={{ url: file }}
                />
                <p>{file}</p>
              </>
            ) : (
              <div className="verification_popup_body">
                <img src={selectedImageDetails?.imageUrl} alt={selectedImageDetails.fileName} />
              </div>
            )}
          </ModalBody>
        </Modal>
      )}
      <React.Fragment>
        <div
          className="page-content"
          style={{ marginLeft: "5%", marginRight: "5%" }}
        >
          <div className="container-fluid">
            <Breadcrumbs title="GET" breadcrumbItem="Verification" />
            <Card style={{ paddingTop: "50px", paddingBottom: "50px" }}>
              <Row>
                <Col
                  md={{
                    offset: 0,
                    size: 12,
                  }}
                >
                  <h1 className="text-center mb-4 heading_for_progress_bar">
                    VERIFICATION
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="setting_layout_main_title">
                    {documentTypes
                      .filter((item) => userData[item.key])
                      .map(renderDocumentType)}
                  </div>
                </Col>
              </Row>
              {renderDocumentSection("NationalIDImg1", "NationalIDImg2", "National ID", "NationalIDno")}
              {renderDocumentSection("AddressProofImg1", "AddressProofImg2", "Address Proof", "AddressProofNo")}
              {renderDocumentSection("BusinessAddressProofImg1", "BusinessAddressProofImg2", "Business Address Proof")}
              {renderDocumentSection("CertOfIncImg1", "CertOfIncImg2", "Cert Of Inc")}
              {renderDocumentSection("MemorandumArticleImg1", "MemorandumArticleImg2", "Memorandum Article")}
              {renderDocumentSection("TradingLicenceImg1", "TradingLicenceImg2", "Trading Licence")}
              {renderDocumentSection("UtilityImg1", "UtilityImg2", "Utility")}

            </Card>
          </div>
        </div>
      </React.Fragment>
    </>
  );
}
export default setting;

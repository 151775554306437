import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import logo from "../../assets/images/GET-logo.png";
import { CURRENCY } from "components/Common/Currency";

const PrintOut = ({ onClose }) => {
  const printInvoice = () => {
    window.print();
    onClose();
  };

  return (
    <React.Fragment>
      <div className="page-content popup_print_out">
        <Container fluid style={{backgroundColor:"white", paddingLeft:"5%", paddingRight:"15%"}}>
          <Row>
            <Col lg="12">
              <div className="invoice-title">
                <h4 className="float-end font-size-25">
                        GET
                      </h4>
                <div className="mb-4">
                  <img src={logo} alt="logo" height="20" />
                </div>
              </div>
              <hr />
              <Row>
                <Col sm="6">
                  <address>
                    <span>Name</span>
                    <br />
                    {/* {map(
                            invoiceDetail.billingAddress.split(","),
                            (item, key) => ( */}
                    <React.Fragment>
                      <strong>Irish Watson</strong>
                      <br />
                    </React.Fragment>
                    {/* )
                          )} */}
                  </address>
                </Col>
                <Col sm="6" className="text-sm-end">
                  <address className="mt-2 mt-sm-0">
                    <span>Currency</span>
                    <br />
                    {/* {map(
                            invoiceDetail.shippingAddress.split(","),
                            (item, key) => ( */}
                    <React.Fragment>
                      <strong>{CURRENCY}</strong>
                      <br />
                    </React.Fragment>
                    {/* )
                          )} */}
                  </address>
                </Col>
              </Row>
              <Row>
                <Col sm="6" className="mt-3">
                  <address>
                    <span>Payment Method Name</span>
                    <br />
                    <strong>Stripe</strong>
                    <br />
                  </address>
                </Col>
                <Col sm="6" className="mt-3 text-sm-end">
                  <address>
                    <span>Transaction Type</span>
                    <br />
                    <strong> Deposit</strong>
                    <br />
                  </address>
                </Col>
              </Row>
              <Row>
                <Col sm="6" className="mt-3">
                  <address>
                    <span>Transaction ID</span>
                    <br />
                    <strong>E607CF9EEC389</strong>
                    <br />
                  </address>
                </Col>
                <Col sm="6" className="mt-3 text-sm-end">
                  <address>
                    <span>Transaction Date</span>
                    <br />
                    <strong> 22-11-2023 5 : 38 PM</strong>
                    <br />
                  </address>
                </Col>
              </Row>
              <Row>
                <Col sm="6" className="mt-3">
                  <address>
                    <span>Status</span>
                    <br />
                    <strong>Success</strong>
                    <br />
                  </address>
                </Col>
                <Col sm="6" className="mt-3 text-sm-end">
                  <address>
                    <span>Deposited Amount</span>
                    <br />
                    <strong> $ 12</strong>
                    <br />
                  </address>
                </Col>
              </Row>
              <div className="py-2 mt-3">
                <h3 className="font-size-15 font-weight-bold">Description</h3>
              </div>
              <div className="table-responsive">
                <Table className="table-nowrap">
                  <thead>
                    <tr>
                      <th>Sub Total</th>
                      <th className="text-end">$ 12</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {map(invoiceDetail.orderSummary.items, (item, key) => ( */}
                    <tr>
                      <td>Fees</td>
                      <td className="text-end"> $ 1.02</td>
                    </tr>
                    {/* ))} */}
                    <tr>
                      <td>Total</td>
                      <td className="text-end">$ 12.02</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="d-print-none">
                <div className="float-start">
                  <Link
                    to="#"
                    onClick={printInvoice}
                    className="btn btn-success me-1"
                  >
                    <i className="fa fa-print" />
                  </Link>{" "}
                  <Link to="#" className="btn btn-primary w-md">
                    Send
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PrintOut;

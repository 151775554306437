import { PayloadAction, createSlice } from '@reduxjs/toolkit';


const initialState = {

  initial: false
};
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserDetails: (state, action) => {
      // Use a type assertion to allow dynamic string keys
    
    },
    
  },
});
export default userSlice.reducer;
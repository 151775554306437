import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useState } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import "../../pages/DepositMoney/DepositeMoneyStyle.css";
import "./MerchantRegistration.css";
import ReCAPTCHA from "react-google-recaptcha";



function MerchantRegistration() {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);

  const handleProceed = nextStep => {
    if (nextStep >= 1 && nextStep <= 4) {
      setCurrentStep(nextStep);
    } else if (nextStep === 0) {
      setCurrentStep(1);
    }
  };

  const [captchaValue, setCaptchaValue] = useState(null);

  const handleCaptchaChange = value => {
    console.log("Captcha value:", value);
    setCaptchaValue(value);
  };

  const handleSubmit = () => {
    if (captchaValue) {
      console.log("Form submitted!");
      alert("Form submitted!");
      setCurrentStep(1);
    } else {
      console.log("Please complete the CAPTCHA.");
      alert("Please complete the CAPTCHA.");
    }
  };
  const categoryOptions = [
    { value: "artsandcrafts", label: "Arts and crafta" },
    { value: "Baby", label: "Baby" },
    { value: "beautyandfragrances", label: "Beauty and fragrances" },
    { value: "Booksandmagazines", label: "Books and magazines" },
    { value: "Businesstobusiness", label: "Business to business" },
    {
      value: "Clothingaccessoriesandshoes",
      label: "Clothing, accessories, and shoes",
    },
    {
      value: "Computersaccessoriesandservices",
      label: "Computers, accessories, and services",
    },
    { value: "Education", label: "Education" },
    { value: "Electronicsandtelecom", label: "Electronics and telecom" },
    { value: "Entertainmentandmedia", label: "Entertainment and media" },
    {
      value: "Financialservicesandproducts",
      label: "Financial services and products",
    },
    { value: "Foodretailandservice", label: "Food retail and service" },
    { value: "Giftsandflowers", label: "Gifts and flowers" },
    { value: "Government", label: "Government" },
    { value: "Healthandpersonalcare", label: "Health and personal care" },
    { value: "Homeandgarden", label: "Home and garden" },
    { value: "Nonprofit", label: "Nonprofit" },
    { value: "Petsandanimals", label: "Pets and animals" },
    {
      value: "Religionandspiritualityprofit",
      label: "Religion and spirituality (for profit)",
    },
    {
      value: "Retailnotelsewhereclassifies",
      label: "Retail (not elsewhere classifies)",
    },
    { value: "Servicesother", label: "Services - Other" },
    { value: "Sportsandoutdoors", label: "Sports and outdoors" },
    { value: "Toysandhobbies", label: "Toys And Hobbies" },
    { value: "Travel", label: "Travel" },
    { value: "Vehiclssales", label: "Vehicls sales" },
    {
      value: "Vehiclsserviceandassossories",
      label: "Vehicls service and assossories",
    },
  ];

  const subcategoryOptionsMap = {
    artsandcrafts: [
      { value: "sub1.1", label: "Antiques" },
      { value: "sub1.2", label: "Art and crafy supplies" },
      { value: "sub1.3", label: "Art dealers and galleries" },
      { value: "sub1.4", label: "Camera and photographic supplies" },
      { value: "sub1.5", label: "Digital art" },
      { value: "sub1.6", label: "Memorabilia" },
      { value: "sub1.7", label: "Music store (instruments and sheet music)" },
      { value: "sub1.8", label: "Sewing, needlework, and febrics" },
      { value: "sub1.9", label: "Stamp and coin" },
      { value: "sub1.10", label: "Stationary, printing and writing paper" },
      { value: "sub1.11", label: "Vintage and collectibles" },
    ],
    Baby: [
      { value: "sub2.1", label: "Clothing" },
      { value: "sub2.2", label: "Furniture" },
      { value: "sub2.3", label: "Baby products (other)" },
      { value: "sub2.4", label: "Safety and health" },
    ],
    beautyandfragrances: [
      { value: "sub3.1", label: "Bath and body" },
      { value: "sub3.2", label: "Fragrances and perfumes" },
      { value: "sub3.3", label: "Makeup and cosmetics" },
    ],
    Booksandmagazines: [
      { value: "sub4.1", label: "Audio books" },
      { value: "sub4.2", label: "Digital content" },
      { value: "sub4.3", label: "Educational and textbooks" },
      { value: "sub4.4", label: "Fiction and nonfiction" },
      { value: "sub4.5", label: "Magazines" },
      { value: "sub4.6", label: "Publishing and printing" },
      { value: "sub4.7", label: "Rare and used books" },
    ],
    Businesstobusiness: [
      { value: "sub5.1", label: "Accounting" },
      { value: "sub5.2", label: "Advertising" },
      { value: "sub5.3", label: "Agricultural" },
      {
        value: "sub5.4",
        label: "Architectural, enineering, and surveying services",
      },
      { value: "sub5.5", label: "Chemicals and allied products" },
      { value: "sub5.6", label: "Commercial photography, art, and graphics" },
      { value: "sub5.7", label: "Construction" },
      { value: "sub5.8", label: "Consulting services" },
      { value: "sub5.9", label: "Educational services" },
      { value: "sub5.10", label: "Equipment rentals and leasing services" },
      { value: "sub5.11", label: "Equipment repair services" },
      { value: "sub5.12", label: "Hiring services" },
      { value: "sub5.13", label: "Industrial and manufacturing supplies" },
      { value: "sub5.14", label: "Mailling lists" },
      { value: "sub5.15", label: "Multi-level marketing" },
      { value: "sub5.16", label: "Office and commercial furniture" },
      { value: "sub5.17", label: "Office supplies and equipment" },
      { value: "sub5.18", label: "Publishing and priting" },
      { value: "sub5.19", label: "Quick copy and reproduction services" },
    ],
    Clothingaccessoriesandshoes: [
      { value: "sub6.1", label: "Childern's clothing" },
      { value: "sub6.2", label: "Men's clothing" },
      { value: "sub6.3", label: "Women's clothing" },
      { value: "sub6.4", label: "Shoes" },
      { value: "sub6.5", label: "Military and civil service uniforms" },
      { value: "sub6.6", label: "Accessories" },
      { value: "sub6.7", label: "Retail (fine jewelry and watches)" },
      { value: "sub6.8", label: "Wholesale (precious stones and metals)" },
      { value: "sub6.9", label: "Fashion jevelry" },
    ],
    Computersaccessoriesandservices: [
      { value: "sub7.1", label: "Computer and data processing services" },
      { value: "sub7.2", label: "Desktops, laptops, and notebooks" },
      { value: "sub7.3", label: "Digital content" },
      { value: "sub7.4", label: "eCommerce services" },
      { value: "sub7.5", label: "Maintenance and repair services" },
      { value: "sub7.6", label: "Monitors and projectors" },
      { value: "sub7.7", label: "Networking" },
      { value: "sub7.8", label: "Online gaming" },
      { value: "sub7.9", label: "Parts and assessories" },
      { value: "sub7.10", label: "Peripherals" },
      { value: "sub7.11", label: "Software" },
      { value: "sub7.12", label: "Training services" },
      { value: "sub7.13", label: "Web hosting and design" },
    ],
    Education: [
      { value: "sub8.1", label: "Business and secretarial schools" },
      { value: "sub8.2", label: "child day care services" },
      { value: "sub8.3", label: "Colleges and univerities" },
      { value: "sub8.4", label: "Dance halls, studios, and schools" },
      { value: "sub8.5", label: "Primary and secondary schools" },
      { value: "sub8.6", label: "Vacational and trade schools" },
    ],
    Electronicsandtelecom: [
      { value: "sub9.1", label: "Cameras, camcorders, and equipment" },
      { value: "sub9.2", label: "Cell phones, PDAs, and pagers " },
      { value: "sub9.3", label: "General electronic accessories" },
      { value: "sub9.4", label: "Home audio" },
      { value: "sub9.5", label: "Home electronics" },
      { value: "sub9.6", label: "Security and surveillance" },
      { value: "sub9.7", label: "Telecommunication ewuipment and sales" },
      { value: "sub9.8", label: "Telecommunication services" },
      { value: "sub9.9", label: "Telephone cards" },
    ],
    Entertainmentandmedia: [
      { value: "2086", label: "Memorabilia" },
      { value: "2087", label: "Movie tickets" },
      { value: "2088", label: "Movies (DVDs, videotapes)" },
      { value: "2089", label: "Music (CDs, cassettes and albums)" },
      { value: "2090", label: "TV and radio" },
      { value: "2091", label: "Adult digital content" },
      { value: "2092", label: "Concert tickets" },
      { value: "2093", label: "Theater tickets" },
      { value: "2094", label: "Toys and games" },
      { value: "2095", label: "Slot machines" },
      { value: "2096", label: "Digital content" },
      { value: "2097", label: "Entertainers" },
      { value: "2098", label: "Gambling" },
      { value: "2099", label: "Online games" },
      { value: "2100", label: "Video games and systems" },
    ],
    Financialservicesandproducts: [
      { value: "2101", label: "Accounting" },
      { value: "2102", label: "Collection agency" },
      { value: "2103", label: "Commodities and futures exchange" },
      { value: "2104", label: "Consumer credit reporting agencies" },
      { value: "2105", label: "Debt counseling service" },
      { value: "2106", label: "Credit union" },
      { value: "2107", label: "Currency dealer and currency exchange" },
      { value: "2108", label: "Escrow" },
      { value: "2109", label: "Finance company" },
      { value: "2110", label: "Financial and investment advice" },
      { value: "2111", label: "Insurance (auto and home)" },
      { value: "2112", label: "Insurance (life and annuity)" },
      { value: "2113", label: "Investments (general)" },
      { value: "2114", label: "Money service business" },
      { value: "2115", label: "Mortgage brokers or dealers" },
      { value: "2116", label: "Online gaming currency" },
      { value: "2117", label: "Paycheck lender or cash advance" },
      { value: "2118", label: "Prepaid and stored value cards" },
      { value: "2119", label: "Real estate agent" },
      { value: "2120", label: "Remittance" },
      { value: "2121", label: "Rental property management" },
      { value: "2122", label: "Security brokers and dealers" },
      { value: "2123", label: "Wire transfer and money order" },
    ],
    Foodretailandservice: [
      { value: "2124", label: "Alcoholic beverages" },
      { value: "2125", label: "Catering services" },
      { value: "2126", label: "Coffee and tea" },
      { value: "2127", label: "Gourmet foods" },
      { value: "2128", label: "Specialty and miscellaneous food stores" },
      { value: "2129", label: "Restaurant" },
      { value: "2130", label: "Tobacco" },
      { value: "2131", label: "Vitamins and supplements" },
    ],
    Giftsandflowers: [
      { value: "2132", label: "Florist" },
      { value: "2133", label: "Gift, card, novelty, and souvenir shops" },
      { value: "2134", label: "Gourmet foods" },
      { value: "2135", label: "Nursery plants and flowers" },
      { value: "2136", label: "Party supplies" },
    ],
    Government: [
      {
        value: "2137",
        label: "Government services (not elsewhere classified)",
      },
    ],
    Healthandpersonalcare: [
      { value: "2138", label: "Drugstore (excluding prescription drugs)" },
      { value: "2139", label: "Drugstore (including prescription drugs)" },
      { value: "2140", label: "Dental care" },
      { value: "2141", label: "Medical care" },
      { value: "2142", label: "Medical equipment and supplies" },
      { value: "2143", label: "Vision care" },
      { value: "2144", label: "Vitamins and supplements" },
    ],
    Homeandgarden: [
      { value: "2145", label: "Antiques" },
      { value: "2146", label: "Appliances" },
      { value: "2147", label: "Art dealers and galleries" },
      { value: "2148", label: "Bed and bath" },
      { value: "2149", label: "Construction material" },
      { value: "2150", label: "Drapery, window covering, and upholstery" },
      { value: "2151", label: "Exterminating and disinfecting services" },
      { value: "2152", label: "Fireplace, and fireplace screens" },
      { value: "2153", label: "Furniture" },
      { value: "2154", label: "Garden supplies" },
      { value: "2155", label: "Glass, paint, and wallpaper" },
      { value: "2156", label: "Hardware and tools" },
      { value: "2157", label: "Home decor" },
      { value: "2158", label: "Housewares" },
      { value: "2159", label: "Kitchenware" },
      { value: "2160", label: "Landscaping" },
      { value: "2161", label: "Rugs and carpets" },
      { value: "2162", label: "Security and surveillance equipment" },
      { value: "2163", label: "Swimming pools and spas" },
    ],
    Nonprofit: [
      { value: "2164", label: "Charity" },
      { value: "2165", label: "Political" },
      { value: "2166", label: "Religious" },
      { value: "2167", label: "Other" },
      { value: "2168", label: "Personal" },
      { value: "2169", label: "Educational" },
    ],
    Petsandanimals: [
      { value: "2171", label: "Medication and supplements" },
      { value: "2172", label: "Pet shops, pet food, and supplies" },
      { value: "2173", label: "Specialty or rare pets" },
      { value: "2174", label: "Veterinary services" },
    ],
    Religionandspiritualityprofit: [
      { value: "2175", label: "Membership services" },
      { value: "2176", label: "Merchandise" },
      { value: "2177", label: "Services (not elsewhere classified)" },
    ],
    Retailnotelsewhereclassifies: [
      { value: "2178", label: "Chemicals and allied products" },
      { value: "2179", label: "Department store" },
      { value: "2180", label: "Discount store" },
      { value: "2181", label: "Durable goods" },
      { value: "2182", label: "Non-durable goods" },
      { value: "2183", label: "Used and secondhand store" },
      { value: "2184", label: "Variety store" },
    ],
    Servicesother: [
      { value: "2185", label: "Advertising" },
      { value: "2186", label: "Shopping services and buying clubs" },
      { value: "2187", label: "Career services" },
      { value: "2188", label: "Carpentry" },
      { value: "2189", label: "Child care services" },
      { value: "2190", label: "Cleaning and maintenance" },
      { value: "2191", label: "Commercial photography" },
      { value: "2192", label: "Computer and data processing services" },
      { value: "2193", label: "Computer network services" },
      { value: "2194", label: "Consulting services" },
      { value: "2195", label: "Counseling services" },
      { value: "2196", label: "Courier services" },
      { value: "2197", label: "Dental care" },
      { value: "2198", label: "eCommerce services" },
      { value: "2199", label: "Electrical and small appliance repair" },
      { value: "2200", label: "Entertainment" },
      { value: "2201", label: "Equipment rental and leasing services" },
      { value: "2202", label: "Event and wedding planning" },
      { value: "2203", label: "Gambling" },
      { value: "2204", label: "General contractors" },
      { value: "2205", label: "Graphic and commercial design" },
      { value: "2206", label: "Health and beauty spas" },
      { value: "2207", label: "IDs, licenses, and passports" },
      { value: "2208", label: "Importing and exporting" },
      { value: "2209", label: "Information retrieval services" },
      { value: "2210", label: "Insurance - auto and home" },
      { value: "2211", label: "Insurance - life and annuity" },
      { value: "2212", label: "Landscaping and horticultural" },
      { value: "2213", label: "Legal services and attorneys" },
      { value: "2214", label: "Local delivery service" },
      { value: "2215", label: "Lottery and contests" },
      { value: "2216", label: "Medical care" },
      { value: "2217", label: "Membership clubs and organizations" },
      { value: "2218", label: "Misc. publishing and printing" },
      { value: "2219", label: "Moving and storage" },
      { value: "2220", label: "Online dating" },
      { value: "2221", label: "Photofinishing" },
      { value: "2222", label: "Photographic studios - portraits" },
      { value: "2223", label: "Protective and security services" },
      { value: "2224", label: "Quick copy and reproduction services" },
      { value: "2225", label: "Radio, television, and stereo repair" },
      { value: "2226", label: "Real estate agent" },
      { value: "2227", label: "Rental property management" },
      { value: "2228", label: "Reupholstery and furniture repair" },
      { value: "2229", label: "Services (not elsewhere classified)" },
      { value: "2230", label: "Shipping and packing" },
      { value: "2231", label: "Swimming pool services" },
      { value: "2232", label: "Tailors and alterations" },
      { value: "2233", label: "Telecommunication service" },
      { value: "2234", label: "Utilities" },
      { value: "2235", label: "Vision care" },
      { value: "2236", label: "Watch, clock, and jewelry repair" },
    ],
    Servicesother: [
      { value: "2237", label: "Athletic shoes" },
      { value: "2238", label: "Bicycle shop, service, and repair" },
      { value: "2239", label: "Boating, sailing and accessories" },
      { value: "2240", label: "Camping and outdoors" },
      { value: "2241", label: "Dance halls, studios, and schools" },
      { value: "2242", label: "Exercise and fitness" },
      { value: "2243", label: "Fan gear and memorabilia" },
      { value: "2244", label: "Firearm accessories" },
      { value: "2245", label: "Firearms" },
      { value: "2246", label: "Hunting" },
      { value: "2247", label: "Knives" },
      { value: "2248", label: "Martial arts weapons" },
      { value: "2249", label: "Sport games and toys" },
      { value: "2250", label: "Sporting equipment" },
      { value: "2251", label: "Swimming pools and spas" },
    ],
    Sportsandoutdoors: [
      { value: "2237", label: "Athletic shoes" },
      { value: "2238", label: "Bicycle shop, service, and repair" },
      { value: "2239", label: "Boating, sailing and accessories" },
      { value: "2240", label: "Camping and outdoors" },
      { value: "2241", label: "Dance halls, studios, and schools" },
      { value: "2242", label: "Exercise and fitness" },
      { value: "2243", label: "Fan gear and memorabilia" },
      { value: "2244", label: "Firearm accessories" },
      { value: "2245", label: "Firearms" },
      { value: "2246", label: "Hunting" },
      { value: "2247", label: "Knives" },
      { value: "2248", label: "Martial arts weapons" },
      { value: "2249", label: "Sport games and toys" },
      { value: "2250", label: "Sporting equipment" },
      { value: "2251", label: "Swimming pools and spas" },
    ],
    Toysandhobbies: [
      { value: "2252", label: "Arts and crafts" },
      { value: "2253", label: "Camera and photographic supplies" },
      { value: "2254", label: "Hobby, toy, and game shops" },
      { value: "2255", label: "Memorabilia" },
      { value: "2256", label: "Music store - instruments and sheet music" },
      { value: "2257", label: "Stamp and coin" },
      { value: "2258", label: "Stationary, printing, and writing paper" },
      { value: "2259", label: "Vintage and collectibles" },
      { value: "2260", label: "Video games and systems" },
    ],
    Travel: [
      { value: "2261", label: "Airline" },
      { value: "2262", label: "Auto rental" },
      { value: "2263", label: "Bus line" },
      { value: "2264", label: "Cruises" },
      { value: "2265", label: "Lodging and accommodations" },
      { value: "2266", label: "Luggage and leather goods" },
      { value: "2267", label: "Recreational services" },
      { value: "2268", label: "Sporting and recreation camps" },
      { value: "2269", label: "Taxicabs and limousines" },
      { value: "2270", label: "Timeshares" },
      { value: "2271", label: "Tours" },
      { value: "2272", label: "Trailer parks or campgrounds" },
      { value: "2273", label: "Transportation services - other" },
      { value: "2274", label: "Travel agency" },
    ],
    Vehiclssales: [
      { value: "2275", label: "Auto dealer - new and used" },
      { value: "2276", label: "Auto dealer - used only" },
      { value: "2277", label: "Aviation" },
      { value: "2278", label: "Boat dealer" },
      { value: "2279", label: "Mobile home dealer" },
      { value: "2280", label: "Motorcycle dealer" },
      { value: "2281", label: "Recreational and utility trailer dealer" },
      { value: "2282", label: "Recreational vehicle dealer" },
      { value: "2283", label: "Vintage and collectibles" },
    ],
    Vehiclsserviceandassossories: [
      { value: "2284", label: "New parts and supplies - motor vehicle" },
      { value: "2285", label: "Used parts - motor vehicle" },
      { value: "2286", label: "Audio and video" },
      { value: "2287", label: "Auto body repair and paint" },
      { value: "2288", label: "Auto rental" },
      { value: "2289", label: "Auto service" },
      { value: "2290", label: "Automotive tire supply and service" },
      { value: "2291", label: "Boat rental and leases" },
      { value: "2292", label: "Car wash" },
      { value: "2293", label: "Motor home and recreational vehicle rental" },
      { value: "2294", label: "Tools and equipment" },
      { value: "2295", label: "Towing service" },
      { value: "2296", label: "Truck and utility trailer rental" },
      { value: "2297", label: "Accessories" },
    ],
  };
  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setSelectedCategory(selectedCategory);

    setSubcategoryOptions(subcategoryOptionsMap[selectedCategory] || []);
  };


  return (
    <>
      <React.Fragment>
        <div
          className="page-content"
          style={{ marginLeft: "5%", marginRight: "5%" }}
        >
          <div className="container-fluid">
            <Container className="mt-5">
              <Card style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                <Row>
                  <Col
                    md={{
                      offset: 3,
                      size: 6,
                    }}
                  >
                    <h1 className="text-center mb-4 heading_for_progress_bar ">
                      MERCHANT REGISTRATION
                    </h1>
                    <p className="text-center steps_text_progress_bar">
                      Step {currentStep} of 4
                    </p>
                    <div className="progress_bar_main mt-4 mb-4">
                      <div
                        className={`progress_bar_one ${
                          currentStep >= 1 ? "Progress_bar_active" : ""
                        }`}
                      ></div>
                      <div
                        className={`progress_bar_one ${
                          currentStep >= 2 ? "Progress_bar_active" : ""
                        }`}
                      ></div>
                      <div
                        className={`progress_bar_one ${
                          currentStep >= 3 ? "Progress_bar_active" : ""
                        }`}
                      ></div>
                      <div
                        className={`progress_bar_one ${
                          currentStep === 4 ? "Progress_bar_active" : ""
                        }`}
                      ></div>
                    </div>
                    {currentStep === 1 && (
                      <h4 className="text-center after_heading_description_In_progress">
                        Main user details
                      </h4>
                    )}
                    {currentStep === 2 && (
                      <h4 className="text-center after_heading_description_In_progress">
                        Business details
                      </h4>
                    )}
                    {currentStep === 3 && (
                      <h4 className="text-center after_heading_description_In_progress">
                        Business ownership details
                      </h4>
                    )}

                   
                  </Col>
                  <Col
                    md={{
                      offset: "1",
                      size: "10",
                    }}
                  >
                    {currentStep === 2 && (
                      <small className="text-center mt-4 progress_bar_description_text">
                        You must complete this section thoroughly if you wish to
                        accept payment from International payment networks. If
                        you are satisfied with local-only payment methods
                        (Vpayments, Ecocash, Telecash), you do NOT need to enter
                        your ZIMRA BP Number. Only Bonafide, traceable
                        businesses will be permitted to receive Visa and
                        Mastercard payments. Paynow does NOT volunteer your
                        personal information or transaction data to any
                        authority. You also do NOT need a current ITF263 to sign
                        up for Paynow.
                      </small>
                    )}
                  </Col>
                </Row>
                {currentStep === 1 && (
                  <Row className="mt-5">
                    <Col className="ps-5 pe-5">
                      <Col md="4" className="mb-4">
                        <div className="form_group_for_merchant_registration">
                          <label>Title</label>
                          <input type="text" placeholder="title" />
                        </div>
                      </Col>
                      <Row>
                        <Col md="6" className="mb-4">
                          <div className="form_group_for_merchant_registration">
                            <label>First name</label>
                            <input type="text" placeholder="Enter name" />
                          </div>
                        </Col>{" "}
                        <Col md="6" className="mb-4">
                          <div className="form_group_for_merchant_registration">
                            <label>Last name</label>
                            <input type="text" placeholder="enter name" />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" className="mb-4">
                          <div className="form_group_for_merchant_registration">
                            <label>Email address</label>
                            <input type="email" placeholder="Enter email" />
                            <span>
                              An email will be sent to this address to
                              authenticate your username
                            </span>
                          </div>
                        </Col>{" "}
                        <Col md="6" className="mb-4">
                          <div className="form_group_for_merchant_registration">
                            <label>Mobile</label>
                            <div className="form_group_input_text_inline">
                              <input type="text" placeholder="enter mobile" />
                              <span>eg : +23562723637</span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Col md="6" className="mb-4">
                        <div className="form_group_for_merchant_registration">
                          <label>Password</label>
                          <input type="password" placeholder="enter password" />
                        </div>
                      </Col>
                      <Col md="6" className="mb-4">
                        <div className="form_group_for_merchant_registration">
                          <label>Confirm password</label>
                          <input
                            type="password"
                            placeholder="enter confirm password"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <Button
                             className="procces_Button"
                          onClick={() => handleProceed(currentStep + 1)}
                          className="button_prmary"
                        >
                          Proceed 
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                )}
                {currentStep === 2 && (
                  <Row className="mt-5">
                    <Col className="ps-5 pe-5">
                      <Row>
                        <Col md="6" className="mb-4">
                          <div className="form_group_for_merchant_registration">
                            <label>Company/Trading Name</label>
                            <div className="form_group_input_text_inline">
                              <input type="text" placeholder="enter name" />
                            </div>
                          </div>
                        </Col>{" "}
                        <Col md="6" className="mb-4">
                          <div className="form_group_for_merchant_registration">
                            <label>Registered legal name</label>
                            <div className="form_group_input_text_inline">
                              <input type="text" placeholder="enter name" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" className="mb-4">
                          <div className="form_group_for_merchant_registration">
                            <label>Category</label>
                            <select        id="category"
                              value={selectedCategory}
                              onChange={handleCategoryChange}>
                              <option selected hidden>Select</option>
                              {categoryOptions.map((category) => (
                                <option
                                  key={category.value}
                                  value={category.value}
                                >
                                  {category.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col md="6" className="mb-4">
                          <div className="form_group_for_merchant_registration">
                            <label>Business entity</label>
                            <select>
                              <option>Private limited company</option>
                              <option>Private limited company</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Col md="6" className="mb-4">
                        <div className="form_group_for_merchant_registration">
                          <label>Sub category</label>
                          <select     id="subcategory"
                            disabled={subcategoryOptions.length === 0}>
                          <option selected hidden>
                              Select
                            </option>
                            {subcategoryOptions.map((subcategory) => (
                              <option
                                key={subcategory.value}
                                value={subcategory.value}
                              >
                                {subcategory.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Row>
                        <Col md="6" className="mb-4">
                          <div className="form_group_for_merchant_registration">
                            <label> Short business desctription</label>
                            <textarea type="text" placeholder="desctription" />
                            <span>
                              Please indicate the products and services you
                              offer and whether you offer these to the
                              Zimbabwean or International market.
                            </span>
                          </div>
                        </Col>
                        <Col md="6" className="mb-4">
                          <div className="form_group_for_merchant_registration">
                            <label>ZIMRA BP number</label>
                            <div className="form_group_input_text_inline">
                              <input type="text" placeholder="enter number" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Col md="6" className="mb-4 pe-2"></Col>
                    </Col>
                    <div className="Button_for_next_and_back">
                      <Button onClick={() => handleProceed(currentStep - 1)}
                      className="back_Buuton_In_merchant">
                         Back
                      </Button>
                      <Button
                           className="procces_Button"
                        onClick={() => handleProceed(currentStep + 1)}
                      >
                        Confirm 
                      </Button>
                    </div>
                  </Row>
                )}
                {currentStep === 3 && (
                  <Row className="mt-5">
                    <Col className="ps-5 pe-5">
                      <Row>
                        <Col md="6" className="mb-4">
                          <div className="form_group_for_merchant_registration">
                            <label>Owner/CEO Name</label>
                            <div className="form_group_input_text_inline">
                              <input type="text" placeholder="enter name" />
                            </div>
                          </div>
                        </Col>{" "}
                        <Col md="6" className="mb-4">
                          <div className="form_group_for_merchant_registration">
                            <label>Owner/CEO Doc.</label>
                            <select>
                              <option>Zimbeabwe national ID</option>
                              <option>option 2</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" className="mb-4">
                          <div className="form_group_for_merchant_registration">
                            <label>owner/CEO ID No.</label>
                            <div className="form_group_input_text_inline">
                              <input type="text" placeholder="enter number" />
                            </div>
                          </div>
                        </Col>{" "}
                        <Col md="6" className="mb-4">
                          <div className="form_group_for_merchant_registration">
                            <label>Start year of business</label>
                            <div className="form_group_input_text_inline">
                              <input type="year" placeholder="enter year" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" className="mb-4">
                          <div className="form_group_for_merchant_registration">
                            <label>Notification Email</label>
                            <input type="text" placeholder="enter email" />
                            <span>
                              If the main user email above is different to this
                              email it will make a second user with transaction
                              only access.{" "}
                            </span>
                          </div>
                        </Col>
                        <Col md="6" className="mb-4">
                          <div className="form_group_for_merchant_registration">
                            <label>Primary Contact Person</label>
                            <input type="year" placeholder="enter number" />
                            <span>
                              Primary contact is typically assigned to a manager
                              with authority to handle financial matters
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" className="mb-4">
                          <div className="form_group_for_merchant_registration">
                            <label>Company telephone</label>
                            <div className="form_group_input_text_inline">
                              <input type="text" placeholder="enter number" />
                            </div>
                          </div>
                        </Col>{" "}
                        <Col md="6" className="mb-4">
                          <div className="form_group_for_merchant_registration">
                            <label>Company Website</label>
                            <div className="form_group_input_text_inline">
                              <input type="URL" placeholder="enter URL" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" className="mb-4">
                          <div className="form_group_for_merchant_registration">
                            <label> Shipping/Trading Address</label>
                            <textarea type="text" placeholder="desctription" />
                          </div>
                        </Col>
                        <Col md="6" className="mb-4">
                          <div className="form_group_for_merchant_registration">
                            <label>Shipping/Trading Country</label>
                            <div className="form_group_input_text_inline">
                              <input type="text" placeholder="enter country" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <div className="Button_for_next_and_back">
                      <Button onClick={() => handleProceed(currentStep - 1)}>
                         Back
                      </Button>
                      <Button
                           className="procces_Button"
                        onClick={() => handleProceed(currentStep + 1)}
                      >
                        Confirm 
                      </Button>
                    </div>
                  </Row>
                )}
                {currentStep === 4 && (
                  <Row className="mt-2 ms-4">
                    <Col className="cpathc_in_merchabnt_form">
                      <form className="form_for_captch_in_merchant">
                        <ReCAPTCHA
                          sitekey="6LdxGxcpAAAAALjw0AgB5c73HpxN7at4y9jhZcIK"
                          onChange={handleCaptchaChange}
                        />
                        <Button
                          type="button"
                             className="procces_Button"
                          style={{ fontFamily: "Gilroy", fontSize: "16px" }}
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                      </form>
                    </Col>
                  </Row>
                )}
              </Card>
            </Container>
          </div>
        </div>
      </React.Fragment>
    </>
  );
}
export default MerchantRegistration;

import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useState, useEffect, useRef } from "react";
import { Button, Card, Col, Container, Row, Table, CardBody, Modal, Label, FormGroup, Input } from "reactstrap";
import "./DepositeMoneyStyle.css";
import print from "../../assets/images/NewImage/printing.png";
import success from "../../assets/images/NewImage/Invoice-Logo.png";
import { checkTaxCalclations, fetchUserProfile, getMerchantBankDetailsForSelect, getMerchantBankDetailsUsingNameapi, postBankToWallet, postWalletToBankTransfer } from "Context/ContextApi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ReactToPrint from "react-to-print";
import { Sendmoneyprint } from "pages/SendMoney/Sendmoneyprint";
import { CURRENCY } from "components/Common/Currency";
import { useData } from "Context/ContextProvider";

function DepositMoney() {
  const {iPAddress} = useData();
  const [currentStep, setCurrentStep] = useState(1);
  const [valueForBankDetails, setValueForBankDetails] = useState([])
  const [popupOpen, setPopupOpen] = useState(false)
  const [pin, setPin] = useState("")
  const [pinError, setPinError] = useState('');
  const [transactionDetails, setTransactionDetails] = useState({})
  const [validationErrors, setValidationErrors] = useState({});
  const [taxes, setTaxes] = useState([])
  const [netTotal, setNetTotal] = useState('')
  const [netTotalAfterSuccess, setNetTotalAfterSuccess] = useState('')
  const [actionData, setActionData] = useState({})
  const [isPinButton, setIsPinbutton] = useState(true)
  const [sendForm, setSendForm] = useState({
    currency: "ZiG",
    amount: "",
    bankName: ""
  })
  const [bankDetails, setBankDetails] = useState([])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSendForm({ ...sendForm, [name]: value });
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  }
  const validateForm = () => {
    let errors = {};

    // if (!sendForm.currency) {
    //   errors.currency = "Select currency";
    // }
    if (!sendForm.amount || isNaN(sendForm.amount) || sendForm.amount <= 0) {
      errors.amount = "Please enter a valid amount greater than zero.";
    }
    // else {
    // if (sendForm.currency === "USD" && sendForm.amount < 5) {
    //   errors.amount = "Minimum amount for USD is 5";
    // }
    //  else if (sendForm.currency === "ZWD" && sendForm.amount < 1000) {
    //   errors.amount = "Minimum amount for ZWD is 1000";
    // }
    // }

    if (!sendForm.bankName) {
      errors.bankName = "Please select bank";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleProceed = nextStep => {
    const isValid = validateForm();
    if (nextStep >= 1 && nextStep <= 3 && isValid) {
      setCurrentStep(nextStep);
    } else if (nextStep === 0) {
      setCurrentStep(1);
    }
  };

  const handlePopup = () => {
    setPopupOpen(true)
  }
  const handleCheckAndDeposit = () => {
    if (!pin || isNaN(pin) || pin.length !== 4) {
      setPinError('PIN must be a 4-digit number.');
      return;
    }
    setPinError('');
    walletToBankTransafer()
  }
  const walletToBankTransafer = async () => {
    try {
      setIsPinbutton(false)
      setNetTotalAfterSuccess('')
      const selectedBank = bankDetails[0];
      const formData = {
        amount: sendForm.amount,
        currency: CURRENCY,
        bankId: selectedBank ? selectedBank.BankNameId : '',
        pin: pin,
        IPAddress:iPAddress
      }
      const response = await postBankToWallet(formData)
      if (response.status === "success") {
        handleProceed(currentStep + 1);
        setPopupOpen(false)
        toastr.success(response.message)
        setTransactionDetails(response.transactionDetails)
        setSendForm({
          bankName: "",
          amount: ""
        })
      } else if (response.status === 'failed') {
        toastr.warning(response.message)
      }
      setPin('')
      setNetTotalAfterSuccess(response.netTotal)
      setIsPinbutton(true)
    } catch (error) {
      toastr.warning(error)
      setPin('')
      setIsPinbutton(true)
    }
  }

  useEffect(() => {
    fetchMerchantBankDetails()
  }, [])
  const fetchMerchantBankDetails = async () => {
    try {
      const response = await getMerchantBankDetailsForSelect()
      setValueForBankDetails(response.result)
    } catch (error) {
      console.log(error);
    }
  }

  // bank details fetch
  useEffect(() => {
    fetchaBankDetails()
  }, [sendForm.bankName])
  const fetchaBankDetails = async () => {
    const formData = {
      bankName: sendForm.bankName
    }
    try {
      const response = await getMerchantBankDetailsUsingNameapi(formData)
      setBankDetails(response.result)
    } catch (error) {
      console.log(error);
    }
  }
  // check Tax Calculations
  const fecthTaxesFrombackend = async () => {
    try {
      const formData = {
        Amount: parseFloat(sendForm.amount),
        Module: '933ac49c-1e75-4189-be51-aba55385ad5d',
        Module_Type: '0e906fe7-cbb5-4caa-bf4a-a828617678dc',
        Nature: 'BUSINESS'
      }
      const response = await checkTaxCalclations(formData)
      setTaxes(response.result)
      setNetTotal(response.netTotal)
    } catch (error) {
      console.log(error);
    }
  }
  const effact = "effact";
  useEffect(() => {
    fetchData();
  }, [effact]);

  const fetchData = async () => {
    try {
      const response = await fetchUserProfile();
      let jsonparse = response?.result?.subResult ? JSON.parse(response?.result?.subResult?.Permissions) : null;
      setActionData(jsonparse)
    } catch (error) {
      console.log(error);
    }
  };

  const handleResertForm = () => {
    setSendForm({
      amount: "",
      bankName: ""
    })
    setPin('')
  }

  // invoice
  const componentRef = useRef();
  document.title = "GET Platform  | Deposit";

  return (
    <>
      <React.Fragment>
        <Modal
          isOpen={popupOpen}
          role="dialog"
          autoFocus={true}
          centered
        >
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <button
                type="button"
                className="btn-close"
                onClick={() => setPopupOpen(false)}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="text-center mb-4">
                <div className="row justify-content-center">
                  <div className="col-xl-8">
                    <h4 className="model_heading_text" >Verify Your pin</h4>
                    <FormGroup>
                      <Label for="pin" className="generate_pin_label">PIN:</Label>
                      <Input type="password" name="pin" id="pin" placeholder="Enter your pin"
                        className="css_for_generatePin_input" min="0"
                        value={pin} onChange={(e) => setPin(e.target.value)} />
                      {pinError && <small className="text-danger">{pinError}</small>}
                    </FormGroup>
                    {isPinButton && (
                      <Button type="button" className="model_confirm_button" onClick={handleCheckAndDeposit}>
                        Confirm
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <div
          className="page-content"
          style={{ marginLeft: "5%", marginRight: "5%" }}
        >
          <div className="container-fluid">
            <Container className="mt-5">
              <Breadcrumbs title="GET" breadcrumbItem="Deposit" />
              <Card style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                <Row>
                  <Col
                    md={{
                      offset: 3,
                      size: 6,
                    }}
                  >
                    <h1 className="text-center mb-4 heading_for_progress_bar ">
                      DEPOSIT
                    </h1>
                    <p className="text-center steps_text_progress_bar">
                      Step {currentStep} of 3
                    </p>
                    {currentStep === 1 && (
                      <h4 className="text-center after_heading_description_In_progress">
                        Create deposit
                      </h4>
                    )}
                    {currentStep === 3 && (
                      <h4 className="text-center after_heading_description_In_progress">
                        Deposit complete
                      </h4>
                    )}
                    {currentStep === 2 && (
                      <h4 className="text-center after_heading_description_In_progress">
                        Confirm your deposit
                      </h4>
                    )}
                    <div className="progress_bar_main mt-4">
                      <div
                        className={`progress_bar_one ${currentStep >= 1 ? "Progress_bar_active" : ""
                          }`}
                      ></div>
                      <div
                        className={`progress_bar_one ${currentStep >= 2 ? "Progress_bar_active" : ""
                          }`}
                      ></div>
                      <div
                        className={`progress_bar_one ${currentStep === 3 ? "Progress_bar_active" : ""
                          }`}
                      ></div>
                    </div>
                    {currentStep === 1 && (
                      <p className="text-center mt-4 progress_bar_description_text">
                        You can deposit to your wallets using our popular
                        payment methods. Fill the details correctly & the
                        amount you want to deposit.
                      </p>
                    )}
                    {currentStep === 2 && (
                      <p className="text-center mt-4 progress_bar_description_text">
                        Check your deposit information before confirmation.
                      </p>
                    )}
                  </Col>
                </Row>
                {currentStep === 1 && (
                  <Row>
                    <Col
                      md={{
                        offset: 3,
                        size: 6,
                      }}
                    >
                      <div className="form_gruap_for_deposit">
                        <div className="form_group_for_inside_in_deposit">
                          <label>Currency</label>
                          <select name="currency" value={sendForm.currency} onChange={handleChange}>
                            <option value="{CURRENCY}">{CURRENCY}</option>
                          </select>
                          {validationErrors.currency && (
                            <span className="error-message">{validationErrors.currency}</span>
                          )}
                        </div>
                        <div className="form_group_for_inside_in_deposit">
                          <label>Amount</label>
                          <input type="number" placeholder="Enter amount" name="amount"
                            value={sendForm.amount} onChange={handleChange} min="0" />
                          {validationErrors.amount && (
                            <span className="error-message">{validationErrors.amount}</span>
                          )}
                        </div>
                        <div className="form_group_for_inside_in_deposit">
                          <label>Payment method</label>
                          <select name="bankName" value={sendForm.bankName} onChange={handleChange}>
                            <option selected hidden>Select Bank</option>
                            {valueForBankDetails.map((item, index) => (
                              <option key={index} value={item.BankName}>{item.BankName}</option>
                            ))}
                          </select>
                          {validationErrors.bankName && (
                            <span className="error-message">{validationErrors.bankName}</span>
                          )}
                        </div>
                        {actionData === null || (actionData?.['Deposit money']?.action === true) ? (
                          <Button
                            className="procces_Button"
                            onClick={() => {
                              handleProceed(currentStep + 1);
                              fecthTaxesFrombackend()
                            }}
                          >
                            Proceed
                          </Button>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                )}
                {currentStep === 2 && (
                  <Row>
                    <Col
                      md={{
                        offset: 3,
                        size: 6,
                      }}
                    >
                      <div className="deposit_money_secondStep_form">
                        <div className="deposit_money_secondStep_form mt-3">
                          <div className="deposite_money_second_step_text  ">
                            <span>{(taxes[0]?.Title)} </span>
                            <span>{CURRENCY} {parseFloat(taxes[0]?.Value)}</span>
                          </div>
                          <div className="deposite_money_second_step_text  ">
                            <span>Tax </span>
                          </div>
                          {taxes[1]?.Tax?.map((item, index) => (
                            <div className="transaction_details ps-4" key={index}>
                              <div className="transaction_detailsForTaxDetals">
                                <div className="transaction_detail_Title_name">
                                  <label>{item.Particular}</label>
                                  {item.Percentage && (
                                    <small>{item.Percentage} %</small>
                                  )}
                                </div>
                                <div className="transaction_detail_Amount">
                                  <span>{CURRENCY} {parseFloat(item.NetTotal).toFixed(2)}</span>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="transaction_details deposite_money_second_step_text_border_line ">
                            <span>Net Tax </span>
                            <span>{CURRENCY} {parseFloat(taxes[2]?.Value)}</span>
                          </div>
                          <div className="transaction_details deposite_money_second_step_text_border_line">
                            <span>Net Total </span>
                            <span>{CURRENCY} {parseFloat(taxes[3]?.Value)}</span>
                          </div>
                        </div>
                        <Button
                          className="procces_Button mt-3"
                          onClick={() => {
                            handlePopup()
                          }}
                        >
                          Confirm & Deposit
                        </Button>
                        <span
                          className="text-center"
                          onClick={() => handleProceed(currentStep - 1)}
                        >
                          Back
                        </span>
                      </div>
                    </Col>
                  </Row>
                )}
                {currentStep === 3 && (
                  <Row>
                    <Col
                      md={{
                        offset: 3,
                        size: 6,
                      }}
                    >
                      <div className="deposite_money_step_three_main">
                        <img
                          src={success}
                          alt="success"
                          className="success_image"
                        />
                        <h5 className="text-center">Success!</h5>
                        <p className="text-center">
                          Money has been successfully deposited to your
                          wallet. you can see the details under the
                          transaction details.
                        </p>
                        <div className="deposit_money_step_three_box">
                          <h5>Deposited money</h5>
                          <h1>{CURRENCY} {parseFloat(netTotalAfterSuccess)}</h1>
                        </div>
                        <div className="deposit_monet_step_three_button">
                          <ReactToPrint
                            trigger={() => (
                              <Button type="button" className="deposit_monet_step_three_print procces_Button">
                                <img src={print} alt="print" />
                                Print code
                              </Button>
                            )}
                            content={() => componentRef.current}
                          />
                          <Button
                            className="deposit_monet_step_three_deposit_again"
                            onClick={() => { handleProceed(0), handleResertForm() }}
                          >
                            Deposit again
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </Card>
              <Card></Card>
            </Container>
          </div>
        </div>
      </React.Fragment>
      <ReactToPrint content={() => componentRef.current} />
      <div style={{ display: "none" }}>
        <Sendmoneyprint ref={componentRef} transactionDetails={transactionDetails} />
      </div>


    </>
  );
}

export default DepositMoney;

import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {  Link } from "react-router-dom";
import { dashboardLockApi, fetchUserProfile } from "Context/ContextApi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useData } from "Context/ContextProvider";
import wheelLogo from"../../../assets/images/NewImage/Vault_Wheel.png"

const getUserName = () => {
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    return obj;
  }
}

const API_BASE_URL = process.env.REACT_APP_API_URL;
const ProfileMenu = ({ t, success }) => {
  const { mData } = useData();
  const subJasonParse = mData?.subResult?.Permissions ? JSON.parse(mData?.subResult?.Permissions) : null;
  const [userData, setUserData] = useState({});
  const [menu, setMenu] = useState(false);
  const [name, setName] = useState("Admin");

  const toggle = () => {
    setMenu(prevMenu => !prevMenu);
  };

  useEffect(() => {
    const userData = getUserName();
    if (userData) {
      setName(userData.username);
    }
  }, []);

  useEffect(() => {
    const userData = getUserName();
    if (userData && success) {
      setName(userData.username);
    }
  }, [success]);

  const effact = "effact"
  useEffect(() => {
    fetchData();
  }, [effact]);
  const fetchData = async () => {
    try {

      const response = await fetchUserProfile();
      const result = response.result.result;
      setUserData(result);
    } catch (error) {
      console.log(error);
    }
  };
  const handleLock = async () =>{
    try {
      const formData= {
        ScreenLock:'LOCK'
      }
      const response = await dashboardLockApi(formData)
      toastr.success(response.message)
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }
  
  const handleLogout = () =>{
    localStorage.removeItem("paths")
    window.location.reload();
  }

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={userData?.ProfileImage?`${API_BASE_URL}/${userData?.id}/profile/${userData?.ProfileImage}`:wheelLogo}
            alt="Header Avatar"
          />{" "}
          <span className="d-none d-xl-inline-block ms-1">
            {userData.BusinessName}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
        {subJasonParse === null || (subJasonParse?.MProfile?.view === true) ? (
          <DropdownItem tag="a" href="/user-profile">
            <i className="bx bx-user font-size-16 align-middle ms-1" />
            Profile
          </DropdownItem>
        ):null}
         {subJasonParse === null || (subJasonParse?.Profile?.view === true) ? (
          <DropdownItem tag="a" href="/sub-admin-profile">
            <i className="bx bx-user font-size-16 align-middle ms-1" />
            Profile
          </DropdownItem>
        ):null}
          <DropdownItem tag="a" href="auth-lock-screen" onClick={handleLock}>
               <i className="bx bx-lock-open font-size-16 align-middle me-1" />
               Lock screen
             </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item" onClick={handleLogout}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            Logout
          </Link>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

ProfileMenu.propTypes = {
  t: PropTypes.any,
  success: PropTypes.string
};

export default (ProfileMenu);

import React from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
} from "reactstrap";
import "../Transactions/TransactionStyle.css";
import "flatpickr/dist/themes/material_blue.css";
import Breadcrumbs from "components/Common/Breadcrumb";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useData } from "Context/ContextProvider";

const API_BASE_URL = process.env.REACT_APP_API_URL
const Notifications = () => {
    const { notifications } = useData();
    const columns = [
        {
            dataField: "Timestamp",
            text: "Date",
            sort: true,
            formatter: (cell, row) => {
                const options = {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                };
                return new Date(cell).toLocaleString("en-US", options);
            },
        },
        {
            dataField: "Type",
            text: "Type",
            sort: true,
        },
        {
            dataField: "Title",
            text: "Title",
            sort: true,
        },
        {
            dataField: "Message",
            text: "Message",
            formatter: (cell, row) => (
                <div
                style={{
                    fontFamily: "Gilroy",
                    fontWeight: "600",
                    fontSize: "18px",
                    width: "700px",
                    overflow: "hidden",
                    whiteSpace: "wrap",
                    textOverflow: "ellipsis",
                  }}
                >
                    {cell}
                </div>
            ),
        },
        // {
        //     dataField: "Status",
        //     text: "Status",
        //     sort: true,
        //     formatter: (cell, row) => (
        //         <span
        //             style={{
        //                 color: row.Status === "Read" ? "green" : "red",
        //                 fontFamily: "Gilroy",
        //                 fontWeight: "600",
        //                 fontSize: "18px",
        //                 textTransform: "uppercase"
        //             }}
        //         >
        //             {cell}
        //         </span>
        //     ),
        // },
    ];
    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: notifications.length,
        custom: true,
    };

    const selectRow = {
        mode: "checkbox",
    };

    const { SearchBar } = Search;
    const formattedDate = new Date(notifications.Transaction_date).toLocaleString();

    return (
        <React.Fragment>
            <div className="page-content"
                style={{ marginLeft: "5%", marginRight: "5%" }}
            >
                <Breadcrumbs title="GET" breadcrumbItem="Notification" />
                <Row>
                    <Col
                        md={{
                            offset: 3,
                            size: 6,
                        }}
                    >
                        <h2
                            className="text-center mt-5 mb-4 "
                            style={{ fontFamily: "Gilroy", color: "#db9c45" }}
                        >
                            Notifications
                        </h2>
                        <p className="text-center heading_description mb-5">
                            History of all notification
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12" xs="12">
                        <Card className="mt-5">
                            <CardBody>
                                <PaginationProvider
                                    pagination={paginationFactory(pageOptions)}
                                    keyField="id"
                                    columns={columns}
                                    data={notifications}
                                >
                                    {({ paginationProps, paginationTableProps }) => (
                                        <ToolkitProvider
                                            keyField="id"
                                            columns={columns}
                                            data={notifications}
                                            search
                                        >
                                            {toolkitProps => (
                                                <React.Fragment>
                                                    <Row className="mb-2">
                                                        <Col md="4">
                                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                                <div className="position-relative">
                                                                    <SearchBar {...toolkitProps.searchProps} />
                                                                    <i className="bx bx-search-alt search-icon" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col xl="12">
                                                            <div className="table-responsive">
                                                                <BootstrapTable
                                                                    keyField={"id"}
                                                                    responsive
                                                                    bordered={false}
                                                                    striped={false}
                                                                    defaultSorted={defaultSorted}
                                                                    //   selectRow={selectRow}
                                                                    classes={
                                                                        "table align-middle table-nowrap"
                                                                    }
                                                                    headerWrapperClasses={"thead-light"}
                                                                    {...toolkitProps.baseProps}
                                                                    {...paginationTableProps}
                                                                    style={{
                                                                        fontWeight: "Gilroy",
                                                                    }}
                                                                    headerClasses={"custom-header-class"}
                                                                    rowStyle={{
                                                                        fontSize: "15px",
                                                                        fontFamily: "Gilroy",
                                                                        fontWeight: "400",
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className="align-items-md-center mt-30">
                                                        <Col className="inner-custom-pagination d-flex">
                                                            <div className="d-inline">
                                                                <SizePerPageDropdownStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </div>
                                                            <div className="text-md-right ms-auto">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                            )}
                                        </ToolkitProvider>
                                    )}
                                </PaginationProvider>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default Notifications;

//REGISTER

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = `${API_BASE_URL}`
export const POST_FAKE_JWT_LOGIN = `${API_BASE_URL}/api/v2/get/getvault/merchantLoginGETVault`
export const POST_FAKE_PASSWORD_FORGET = `${API_BASE_URL}/api/v2/get/getvault/merchantforgotpassword`
export const USER_PROFILE_DATA = `${API_BASE_URL}/api/v2/get/getvault/getMerchantProfileData`

// export const POST_FAKE_LOGIN = "http://62.138.3.174:4900"
// export const POST_FAKE_JWT_LOGIN = "http://62.138.3.174:4900/api/v2/get/getvault/merchantLoginGETVault"
// export const POST_FAKE_PASSWORD_FORGET = "http://62.138.3.174:4900/api/v2/get/webmerchant/merchant_forgotpassword"

export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"

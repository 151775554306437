import React from "react";
import getLogo from "../../assets/images/NewImage/Vault_Wheel.png";

const Loader = () => {
    return (
        <div className="loader-Module">
            {/* <div className="loader-body">
          <p className="loader"></p>
        </div> */}
            <div className="loader_image_center">
                <img src={getLogo} alt="image" className="rotate_logo" />
            </div>
        </div>
    )
}
export default Loader
import React, { Component } from "react";
import PropTypes from "prop-types";

import { Alert, Card, CardBody, Col, Container, Row, Label } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
// actions
import { apiError, loginUser, socialLogin, loginSuccess } from "../../store/actions";
// import images
import lightlogo from "../../assets/images/GET-logo.png";
import getvault from "../../assets/images/NewImage/GETVault_LOGO.png";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import "./profile.css";
import Merchanttlogo from "../../assets/images/NewImage/Invoice-Logo.png";
import getLogo from "../../assets/images/NewImage/Vault_Wheel.png";
import Loader from "components/Common/Loader";
import LoginFooter from "components/Common/LoginFooter";
import PolicyFooter from "components/Common/TermsAndconditionFooter";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      rememberMe: true,
      isLoading: false,
      IpAddress:"",
    };
    this.handleLinkClick = this.handleLinkClick.bind(this);
  }
  handleLinkClick() {
    const url = "https://hexims.it/";
    window.open(url, "_blank");
  }

  componentDidMount() {
    this.props.apiError("");
    this.props.loginSuccess();
    this.handleFetch();
  }
  handleEyes = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  };

  handleRememberMeChange = () => {
    this.setState(prevState => ({
      rememberMe: !prevState.rememberMe
    }));
  };

  componentDidUpdate(prevProps) {
    if (this.props.error !== prevProps.error && this.props.error === "Login Successfully") {
      this.checkPermission();
    }
  }

  checkPermission = () => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 3000);
  };

  handleFetch (){
  fetch('https://api.ipify.org?format=json')
  .then(response => response.json())
  .then(data => {
    this.setState({IpAddress:data.ip})
  })
  .catch(error => {
    console.error('Error fetching IP address:', error);
  });
  }

  render() {
    document.title = "GET Platform | Login";
    const { showPassword, rememberMe, isLoading, IpAddress } = this.state;
    return (
      <React.Fragment>
        {isLoading && (
          <Loader />
        )}
        <div className="Login_header">
          <img src={Merchanttlogo} alt="image" />
          <Link to="registration">
            <span>SignUp</span>
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5 ">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={8} xl={6}>
                <div className="Sign-in-page">
                  <img src={getLogo} alt="image" className="rotate_logo" />
                  <span>
                    <b>Welcome back! Please Sign in to continue.</b>
                  </span>
                </div>
                <div className="">
                  {this.props.error && this.props.error ? (
                    <Alert color="danger">{this.props.error}</Alert>
                  ) : null}

                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      email: (this.state && this.state.email) || "",
                      password: (this.state && this.state.password) || "",
                    }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string().required("Please Enter Your Email"),
                      password: Yup.string().required(
                        "Please Enter Valid Password"
                      ),
                    })}
                    onSubmit={(values) => {
                      if (this.state.rememberMe) {
                        localStorage.setItem('rememberedEmail', values.email);
                        localStorage.setItem('rememberedPassword', values.password);
                      } else {
                        localStorage.removeItem('rememberedEmail');
                        localStorage.removeItem('rememberedPassword');
                      }
                      const data={
                        ...values,
                        IPAddress:IpAddress
                      }
                      this.props.loginUser(data, this.props.history);
                    }}
                  >
                    {({ errors, status, touched }) => (
                      <Form className="form-horizontal">
                        <div className="mb-3">
                          <Label
                            for="email"
                            className="form-label form_label_login"
                          >
                            Email
                          </Label>
                          <Field
                            name="email"
                            type="text"
                            className={
                              "form-control form_control_login" +
                              (errors.email && touched.email
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="mb-3">
                          <Label
                            for="password"
                            className="form-label form_label_login"
                          >
                            Password
                          </Label>
                          <div className="input-group auth-pass-inputgroup">
                            <Field
                              name="password"
                              type={showPassword ? "text" : "password"}
                              autoComplete="true"
                              className={
                                "form-control form_control_login" +
                                (errors.password && touched.password
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <button
                              className="btn btn-light "
                              type="button"
                              id="password-addon"
                              onClick={this.handleEyes}
                            >
                              <i className={showPassword ? "mdi mdi-eye-off-outline" : "mdi mdi-eye-outline"}></i>
                            </button>
                          </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="d-flex justify-content-between mt-2 mb-2">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                              checked={rememberMe}
                              onChange={this.handleRememberMeChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                              style={{
                                fontFamily: "Gilroy",
                                fontSize: "18px",
                                color: "#000033",
                              }}
                            >
                              Remember me
                            </label>
                          </div>
                          <div className=" text-center">
                            <Link
                              to="/forgot-password"
                              style={{
                                fontFamily: "Gilroy",
                                fontSize: "16px",
                                color: "#000033",
                              }}
                            >
                              <i className="mdi mdi-lock me-1" /> Forgot your
                              password?
                            </Link>
                          </div>
                        </div>
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-block get_login_Button"
                            type="submit"
                          >
                            Login
                          </button>
                        </div>
                        <PolicyFooter />
                      </Form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
          <LoginFooter />
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  loginSuccess: PropTypes.any,
};

const mapStateToProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, loginSuccess })(Login)
);






















// import React, { Component } from "react";
// import PropTypes from "prop-types";

// import { Alert, Card, CardBody, Col, Container, Row, Label } from "reactstrap";

// // Redux
// import { connect } from "react-redux";
// import { Link, withRouter } from "react-router-dom";

// import { Formik, Field, Form, ErrorMessage } from "formik";
// import * as Yup from "yup";
// // actions
// import { apiError, loginUser, socialLogin } from "../../store/actions";
// // import images
// import lightlogo from "../../assets/images/GET-logo.png";
// import getvault from "../../assets/images/NewImage/GETVault_LOGO.png"
// import toastr from "toastr";
// import "toastr/build/toastr.min.css";
// import "./profile.css"

// class Login extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }

//   componentDidMount() {
//     this.props.apiError("");
//   }

//   render() {
//     document.title ="GET Vault! | Merchant login"
//     return (
//       <React.Fragment>
//         <div className="background-login-image">
//         <div className="account-pages my-5 pt-sm-5 ">
//           <Container>
//             <Row className="justify-content-center">
//               <Col md={8} lg={8} xl={6}>
//                 <Card className="overflow-hidden">
//                   <div
//                 className="login-top-background"
//                    >
//                     <Row>
//                       <Col className="col-12">
//                         <div className="text-primary text-center  pt-4 pb-1">
//                           <h4  className="login-top-card-text-head">GET Vault</h4>
//                           <h4  className="login-top-card-login-text mt-3 pt-2">Login</h4>
//                         </div>
//                       </Col>
//                     </Row>
//                   </div>
//                   <CardBody className="pt-0">
//                     <div className="auth-logo">
//                       <Link to="/" className="auth-logo-light">
//                         <div className="avatar-md profile-user-wid mb-4">
//                           <span className="avatar-title rounded-circle bg-light">
//                             <img
//                               src={lightlogo}
//                               alt=""
//                               className="rounded-circle"
//                               height="34"
//                             />
//                           </span>
//                         </div>
//                       </Link>
//                       <Link to="/" className="auth-logo-dark">
//                         <div className="avatar-md profile-user-wid mb-4">
//                           <span className="avatar-title rounded-circle bg-light">
//                             <img
//                               src={getvault}
//                               alt=""
//                               className="rounded-circle"
//                               height="52"
//                             />
//                           </span>
//                         </div>
//                       </Link>
//                     </div>
//                     <div className="p-2">
//                       {this.props.error && this.props.error ? (
//                         <Alert color="danger">{this.props.error}</Alert>
//                       ) : null}

//                       <Formik
//                         enableReinitialize={true}
//                         initialValues={{
//                           email:
//                             (this.state && this.state.email) ||
//                             "",
//                           password:
//                             (this.state && this.state.password) || "",
//                         }}
//                         validationSchema={Yup.object().shape({
//                           email: Yup.string().required(
//                             "Please Enter Your Email"
//                           ),
//                           password: Yup.string().required(
//                             "Please Enter Valid Password"
//                           ),
//                         })}
//                         onSubmit={values => {
//                           this.props.loginUser(values, this.props.history);
//                         }}
//                       >
//                         {({ errors, status, touched }) => (
//                           <Form className="form-horizontal">
//                             <div className="mb-3">
//                               <Label for="email" className="form-label form_label_login">
//                                 Email
//                               </Label>
//                               <Field
//                                 name="email"
//                                 type="text"
//                                 className={
//                                   "form-control form_control_login" +
//                                   (errors.email && touched.email
//                                     ? " is-invalid"
//                                     : "")
//                                 }
//                               />
//                               <ErrorMessage
//                                 name="email"
//                                 component="div"
//                                 className="invalid-feedback"
//                               />
//                             </div>
//                             <div className="mb-3">
//                               <Label for="password" className="form-label form_label_login">
//                                 Password
//                               </Label>
//                               <div className="input-group auth-pass-inputgroup">
//                                 <Field
//                                   name="password"
//                                   type="password"
//                                   autoComplete="true"
//                                   className={
//                                     "form-control form_control_login" +
//                                     (errors.password && touched.password
//                                       ? " is-invalid"
//                                       : "")
//                                   }
//                                 />
//                                 <button
//                                   className="btn btn-light "
//                                   type="button"
//                                   id="password-addon"
//                                 >
//                                   <i className="mdi mdi-eye-outline"></i>
//                                 </button>
//                               </div>
//                               <ErrorMessage
//                                 name="password"
//                                 component="div"
//                                 className="invalid-feedback"
//                               />
//                             </div>

//                             <div className="form-check">
//                               <input
//                                 type="checkbox"
//                                 className="form-check-input"
//                                 id="customControlInline"
//                               />
//                               <label
//                                 className="form-check-label"
//                                 htmlFor="customControlInline"
//                                 style={{fontFamily:"Gilroy", fontSize:"18px", color:"#000033"}}
//                               >
//                                 Remember me
//                               </label>
//                             </div>

//                             <div className="mt-3 d-grid">
//                               <button
//                                 className="btn btn-block get_vault_button"
//                                 type="submit"
//                               >
//                                 Login
//                               </button>
//                             </div>
//                             <div className="mt-4 text-center">
//                               <Link
//                                 to="/forgot-password"
//                                 style={{fontFamily:"Gilroy", fontSize:"16px", color:"#000033"}}
//                               >
//                                 <i className="mdi mdi-lock me-1"  /> Forgot your
//                                 password?
//                               </Link>
//                             </div>
//                           </Form>
//                         )}
//                       </Formik>
//                     </div>
//                   </CardBody>
//                 </Card>
//                 <div className="mt-5 text-center">
//                   <p style={{fontFamily:"Gilroy", fontSize:"16px", color:"white"}}>
//                     Don&apos;t have an account ?
//                     <Link to="registration" className="fw-medium "    style={{fontFamily:"Gilroy", fontSize:"16px", color:"white"}}>
//                        Signup Now
//                     </Link>
//                   </p>

//                 </div>
//               </Col>
//             </Row>
//           </Container>
//         </div>
//         </div>
//       </React.Fragment>
//     );
//   }
// }

// Login.propTypes = {
//   apiError: PropTypes.any,
//   error: PropTypes.any,
//   history: PropTypes.object,
//   loginUser: PropTypes.func,
// };

// const mapStateToProps = state => {
//   const { error } = state.Login;
//   return { error };
// };

// export default withRouter(
//   connect(mapStateToProps, { loginUser, apiError })(Login)
// );

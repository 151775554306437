import React from "react";


const API_BASE_URL = process.env.REACT_APP_API_URL;
import MerchanttImg from "../../assets/images/NewImage/MERCHANT.png"
import voultImg from "../../assets/images/NewImage/VAULT.png"
import MerchantvoultImg from "../../assets/images/NewImage/Invoice-Logo.png";
import { CURRENCY } from "components/Common/Currency";

export const Sendmoneyprint = React.forwardRef(function MyComponent(props, ref) {
    const transactionDate = new Date(props.transactionDetails.transaction_date);
    const formatDate = (date) => {
        const months = [
            "January", "February", "March",
            "April", "May", "June",
            "July", "August", "September",
            "October", "November", "December"
        ];

        return `Date: ${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
    };
    const formattedTransactionDate = formatDate(transactionDate);
    const jsonParse = props.transactionDetails.TaxDetails ? JSON.parse(props.transactionDetails.TaxDetails) : ""
    return (
        <div ref={ref}>

            <style>
                {`
			.invoice-box {
				max-width: 800px;
				margin: auto;
				padding: 30px;
				border: 1px solid #eee;
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
				font-size: 16px;
				line-height: 24px;
				font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
				color: #555;
			}

			.invoice-box table {
				width: 100%;
				line-height: inherit;
				text-align: left;
			}

			.invoice-box table td {
				padding: 5px;
				vertical-align: top;
			}
            .invoice-box table top {
				padding: 5px;
				vertical-align: top;
			}

			.invoice-box table tr td:nth-child(2) {
				text-align: right;
			}

			.invoice-box table tr.top table td {
				padding-bottom: 20px;
			}

			.invoice-box table tr.top table td.title {
				font-size: 45px;
				line-height: 45px;
				color: #333;
			}

			.invoice-box table tr.information table td {
				padding-bottom: 40px;
			}

			.invoice-box table tr.heading td {
				background: #eee;
				border-bottom: 1px solid #ddd;
				font-weight: bold;
			}

			.invoice-box table tr.details td {
				padding-bottom: 20px;
			}

			.invoice-box table tr.item td {
				border-bottom: 1px solid #eee;
			}

			.invoice-box table tr.item.last td {
				border-bottom: none;
			}

			.invoice-box table tr.total td:nth-child(2) {
				border-top: 2px solid #eee;
				font-weight: bold;
			}

			@media only screen and (max-width: 600px) {
				.invoice-box table tr.top table td {
					width: 100%;
					display: block;
					text-align: center;
				}

				.invoice-box table tr.information table td {
					width: 100%;
					display: flex;
                    justify-content: space-between;
					text-align: center;
				}
			}

			.invoice-box.rtl {
				direction: rtl;
				font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
			}

			.invoice-box.rtl table {
				text-align: right;
			}

			.invoice-box.rtl table tr td:nth-child(2) {
				text-align: left;
			}
            .item-right-side{
                display: flex;
                justify-content: end;
                align-items: end;
                align-content: end;
                border-bottom: 1px solid #eee;
            }

            .item-right-side-currency{
                width:20px;
            }

            .item-right-side-span{
                width:60px;
                text-align: right;
            }
    `}
            </style>

            <div className="invoice-box">
                <table cellPadding="0" cellSpacing="0" >
                    <tr className="top">
                        <td colSpan="2">
                            <table>
                                <tr>
                                    <td className="title">

                                    </td>
                                    {/* <img
                                        src="../../../public/MERCHANT.png"
                                        style={{width: "100%", maxWidth: "300px"}}
                                    /> */}
                                    <td>
                                        <img
                                            src={MerchantvoultImg}
                                            style={{ width: "100%", maxWidth: "120px" }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="title">
                                        <img
                                            src={`${API_BASE_URL}/${props.transactionDetails?.MerchantLogo}`}
                                            style={{ width: "100%", maxWidth: "120px" }}
                                        />
                                    </td>

                                    <td>
                                        UTR #: {props.transactionDetails.utr}<br />
                                        {props.transactionDetails.sender_vpa}<br />
                                        {formattedTransactionDate}<br />
                                        Status: <span style={{ textTransform: "uppercase" }}>{props.transactionDetails.Status}</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <tr className="information">
                        <td colSpan="2">
                            <table>
                                <tr>
                                    <td>
                                        {props.transactionDetails?.companyTradingName && (<>{props.transactionDetails?.companyTradingName}</>)}<br />
                                        {props.transactionDetails?.companyTradingName && (<>  {props.transactionDate?.personalAddressproof}</>)}<br />
                                        {props.transactionDetails?.companyTradingName && (<>  {props.transactionDetails?.address}</>)}<br />
                                        {props.transactionDetails?.companyTradingName && (<>  {props.transactionDetails?.country}</>)}<br />
                                    </td>
                                    <td>
                                        {props.transactionDetails?.companyTradingName && (<>   {props.transactionDetails?.firstName}</>)}<br />
                                        {props.transactionDetails?.companyTradingName && (<>    {props.transactionDetails?.receiver_vpa}</>)}<br />
                                        {props.transactionDetails?.companyTradingName && (<>    {props.transactionDetails?.mobileNumber}</>)}<br />
                                        {props.transactionDetails?.companyTradingName && (<>  {props.transactionDetails?.bankName}</>)}<br />
                                        {props.transactionDetails?.companyTradingName && (<>   {props.transactionDetails?.accountNumber}</>)}<br />
                                        {props.transactionDetails?.companyTradingName && (<>    {props.transactionDetails?.branch_code}</>)}<br />
                                        {props.transactionDetails?.companyTradingName && (<>  {props.transactionDetails?.email}</>)}<br />
                                        {props.transactionDetails?.companyTradingName && (<>  {props.transactionDetails?.vpa}</>)}

                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <tr className="heading">
                        <td>Payment Source</td>
                        <td>--</td>
                    </tr>

                    <tr className="details">
                        <td>{props.transactionDetails?.payment_type}</td>
                        <td>--</td>
                    </tr>

                    <tr className="heading">
                        <td>Transaction Details</td>

                        <td>Amount</td>
                    </tr>
                    <tr className="total">
                        <td> Amount</td>
                        <td>{CURRENCY} {props.transactionDetails?.amount ? props.transactionDetails?.amount : props.transactionDetails?.Amount}</td>
                    </tr>

                    <tr className="item">
                    </tr>
                    {jsonParse && jsonParse.length > 0 && (
                        jsonParse[1]?.Tax?.map((item, index) => (
                            <tr className="item" key={index}>
                                <td>{item?.Particular}</td>
                                <td className="item-right-side">
                                    <div className="item-right-side-currency">{CURRENCY}</div>
                                    <div className="item-right-side-span"> {item?.NetTotal}</div>
                                </td>
                            </tr>
                        ))
                    ) }

                    <tr className="total">
                        <td> Net Total</td>
                        <td>{CURRENCY} {props.transactionDetails.NetTotal}</td>
                    </tr>

                </table>
            </div>


        </div>
    );
});

Sendmoneyprint.displayName = "Sendmoneyprint";

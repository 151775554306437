
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  InputGroup,
} from "reactstrap";
import cancelImg from "../../assets/images/NewImage/x-button.png";
import "../Transactions/TransactionStyle.css";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import Breadcrumbs from "components/Common/Breadcrumb";
import dropdown from "../../assets/images/NewImage/down.png";
import { fetchUserProfile, getAllDeposithistory, } from "Context/ContextApi";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search, CSVExport
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { saveAs } from "file-saver";
// import { useDownloadExcel } from "react-export-table-to-excel";
import pdfimg from "../../assets/images/NewImage/pdf.png";
import csvimg from "../../assets/images/NewImage/csv.png";
import excelimg from "../../assets/images/NewImage/xls.png"
import { useDownloadExcel } from "react-export-table-to-excel";
import ReactToPrint from "react-to-print";
import { TransactionPrint } from "pages/Transactions/TransactionPrint";
import arrowicon from "../../assets/images/NewImage/skip-track.png"
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { CURRENCY } from "components/Common/Currency";

const API_BASE_URL = process.env.REACT_APP_API_URL
const SendHistory = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [transactionData, setTransactionData] = useState([])
  const [status, setStatus] = useState("")
  const [payment_type, setPayment_type] = useState("bankwithdraw")
  const [currency, setCurrency] = useState("USD")
  const [dateRange, setDateRange] = useState([]);
  const [popupData, setPopupData] = useState('')
  const [businesName, setBusinesName] = useState({})
  const generatedData = new Date().toLocaleString();
  const [actionData, setActionData] = useState(null)

  const sortedProducts = [...transactionData];
  sortedProducts.sort((a, b) => a.Status.localeCompare(b.Status));
  const togglePopup = (row) => {
    setPopupData(row)
    setPopupOpen((prevPopupOpen) => !prevPopupOpen);
  };
  const handleDateChange = (selectedDates) => {
    setDateRange(selectedDates);
  };
  useEffect(() => {
    fetchTheMerchantTransacrionHistory(status);
  }, [])
  const fetchTheMerchantTransacrionHistory = async (Status) => {
    try {
      const formData = {
        currency: CURRENCY,
        payment_type: payment_type,
        status: Status
      }
      const response = await getAllDeposithistory(formData)
      setTransactionData(response.result)
      setBusinesName(response.userInfo)
    } catch (error) {
      console.log(error);
    }
  }
  const handleReset = () => {
    setStatus("")
    fetchTheMerchantTransacrionHistory(status)
  }
  const columns = [
    {
      dataField: "Transaction_date",
      text: "Date",
      sort: true,
      formatter: (cell, row) => {
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false,
        };
        return new Date(cell).toLocaleString("en-US", options);
      },
    },
    {
      dataField: "UTR",
      text: "UTR",
      sort: true,
    },
    {
      dataField: "sender_name",
      text: "Payer",
      sort: true,
    },
    {
      dataField: "Sender_vpa",
      text: "Payer VPA",
      sort: true
    },
    {
      dataField: "NetPaid",
      text: " Net Paid",
      sort: true,
    },
    {
      dataField: "Payment_type",
      text: "Payment Type",
      sort: true,
    },
    {
      dataField: "Status",
      text: "Status",
      sort: true,
      formatter: (cell, row) => (
        <span
          style={{
            color: row.Status === "completed" ? "green" : "red",
            fontFamily: "Gilroy",
            fontWeight: "600",
            fontSize: "18px",
            textTransform: "uppercase"
          }}
        >
          {cell}
        </span>
      ),
    },
    {
      dataField: "",
      text: "Details",
      formatter: (cell, row) => (
        <div>
          <Link to={`/payment-details/${row?.id}`}>
            <p
              className="Details_popup"
            // onClick={() => togglePopup(row)}
            >
              <img src={arrowicon} alt="icon" />
            </p>
          </Link>
        </div>
      ),
    }
  ];
  const columns1 = [
    {
      dataField: "Transaction_date",
      text: "Date",
      formatter: (cell, row) => {
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false,
        };
        return new Date(cell).toLocaleString("en-US", options);
      },
    },
    {
      dataField: "sender_name",
      text: "Sender",
    },
    {
      dataField: "Sender_vpa",
      text: "Sender VPA",
    },
    {
      dataField: "receiver_name",
      text: "Receiver",
    },
    {
      dataField: "Receiver_vpa",
      text: "Receiver VPA",
    },
    {
      dataField: "UTR",
      text: "UTR",
    },
    {
      dataField: "Amount",
      text: "Amount",
    },
   
    {
      dataField: "Tax",
      text: "Tax",
    },
    {
      dataField: "NetTotal",
      text: "Net Total",
    },
    {
      dataField: "Payment_type",
      text: "Payment Type",
    },
  
    {
      dataField: "Status",
      text: "Status",
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: transactionData.length,
    custom: true,
  };

  const selectRow = {
    mode: "checkbox",
  };

  const { SearchBar } = Search;
  const formattedDate = new Date(popupData.Transaction_date).toLocaleString();

  // exporting data 
  const { ExportCSVButton, } = CSVExport;
  // excel and pdf 
  const tableRef = useRef();

  const handleDownloadPDF = () => {
    const pageWidth = 1000;
    const doc1 = new jsPDF({

    });
    const pageheight_1 = doc1.internal.pageSize.getHeight();
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'px',
      size: pageWidth,
      format: [pageWidth, pageheight_1]
    });

    const pagewidth1 = doc.internal.pageSize.getWidth();
    const pageheight1 = doc.internal.pageSize.getHeight();

    const imageUrl1 = `${API_BASE_URL}/vault_logo.png`;
    const merchantLogo = `${API_BASE_URL}/${businesName.MerchantLogo}`;
    
    var fontSize = 12;
    doc.setTextColor(0, 0, 51);
    doc.setFillColor(5, 5, 16);
    try {
      doc.addImage(merchantLogo, 'PNG', 30, 30, 100, 30)
    } catch (error) {
      console.error('Error adding Image:', error);
    }
    doc.addImage(imageUrl1, 'PNG', (pagewidth1 - 130), 30, 40, 40)

    doc.text(30, 100, `MERCHANT  : ${businesName.BusinessName} |  GENERATED AT  : ${generatedData}  |  REPORT FOR WITHDRAW TRANSACTIONS`);
    const titletextColor = '#000000';
    doc.autoTable({ startY: 110, html: "#my-table2", styles: { color: titletextColor, halign: 'center' }, headStyles: { fillColor: [5, 5, 16] }, alternateRowStyles: { fillColor: [201, 253, 240] }, tableLineColor: [0, 0, 51], tableLineWidth: 0.1, }); //use headStyles to bring styles to the table head, and alternateRowStyles to color the rows but one yes and one no
    doc.setFontSize(fontSize);

    const fileName = `REPORT_FOR_WITHDRAWALS_TRANSACTIONS_${new Date().toISOString()}.pdf`;
    doc.save(fileName);
  };


  const { onDownload } = useDownloadExcel({
    currentTableRef: document.getElementById("my-table"),
    filename: `merchant_Withdraw_transactions_${new Date()}`,
    sheet: 'Users'
  })

    
  let effact = "effect"
  useEffect(() => {
    fetchData();
  }, [effact]);
  const fetchData = async () => {
    try {
      const response = await fetchUserProfile();
      let jsonparse = response?.result?.subResult ? JSON.parse(response?.result?.subResult?.Permissions) : null;
      setActionData(jsonparse)
    } catch (error) {
      console.log(error);
    }
  };
  const componentRef = useRef();
  return (
    <React.Fragment>
      {isPopupOpen && popupData && (
        <div className="transaction_popup_main">
          <div className="transaction_popup_content">
            <div className="transaction_popup_left_side">
              <div className="Transaction_popup_left_side_content">
                <h3>{popupData.sender_name}</h3>
                <h3>{popupData.Payment_type}</h3>
              </div>
              <div className="Transaction_popup_left_side_content">
                <h4> {popupData.Currency} {popupData.Amount}</h4>
              </div>

              <div className="Transaction_popup_left_side_content">
                <h5>{formattedDate}</h5>
                <h5 className="transaction_popup_stratus">{popupData.Status}</h5>
                <div className="transaction_popup_button">
                  <ReactToPrint
                    trigger={() => (
                      <button type="button">
                        Print
                      </button>
                    )}
                    content={() => componentRef.current}
                  />
                  <button>Email</button>
                </div>

              </div>

            </div>
            <div className="transaction_popup_right_side mt-4">
              <div className="transaction_heading_popup">
                <h3>Transaction details</h3>
                <img
                  src={cancelImg}
                  alt="cancel"
                  onClick={togglePopup}
                />
              </div>
              <hr />
              <Row>
                <Col sm="12" xs="12" className="">
                  <div className="deposite_money_second_step_text ">
                    <div className="transaction_popup_right_side_detailsForTaxDetals">
                      <small>UTR</small>
                      <span>{popupData.UTR} </span>
                    </div>
                    <div className="transaction_popup_right_side_detailsForTaxDetals">
                      <small>Currency</small>
                      <span>{popupData.Currency}</span>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="deposit_money_secondStep_form">
                {JSON.parse(popupData.Tax_Details)?.map((item, index) => (
                  <div className="deposite_money_second_step_text " key={index}>
                    <div className="transaction_popup_right_side_detailsForTaxDetals">
                      <label>{item.Particular}</label>
                      {item.Total_Tax && (
                        <small>{item.Total_Tax} %</small>
                      )}
                    </div>
                    <span>$ {parseFloat(item.NetTotal).toFixed(2)}</span>
                  </div>
                ))}
                <div className="deposite_money_second_step_text">
                  <span>Total </span>
                  <span>$ {parseFloat(popupData.NetTotal)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="page-content"
        style={{ marginLeft: "5%", marginRight: "5%" }}
      >

        <Breadcrumbs title="GET" breadcrumbItem="Withdrawal history" />
        <Row>
          <Col
            md={{
              offset: 3,
              size: 6,
            }}
          >
            <h2
              className="text-center mt-5 mb-4 "
              style={{ fontFamily: "Gilroy", color: "#db9c45" }}
            >
              {" "}
              WITHDRAW MONEY{" "}
            </h2>
            <p className="text-center heading_description mb-5">
              History of all withdraw to your bank account
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
              className="mb-4"
            >
              <div style={{ width: "80%", display: "flex", gap: "10px" , flexWrap:"wrap"}}>
              <div className="filtter_Date_range">
                <InputGroup >
                  <Flatpickr
                    className="date_picker"
                    placeholder="Select date range"
                    options={{
                      mode: "range",
                      dateFormat: "Y-m-d",
                    }}
                    onChange={handleDateChange}
                  />
                </InputGroup>
                </div>
                <select className="filtter_select_box" name="status" value={status}
                  onChange={(e) => {
                    const selectedStatus = e.target.value;
                    setStatus(selectedStatus);
                    fetchTheMerchantTransacrionHistory(selectedStatus);
                  }}
                >
                  <option selected hidden>
                    Status
                  </option>
                  <option value=''>All</option>
                  <option value="completed">Successfull</option>
                  <option value="pending"> Pending</option>
                  <option value="failed">Failed</option>
                </select>

              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  gap: "20px",
                }}
              >
                <Button
                  type="submit"
                  className="procces_Button"
                  onClick={handleReset}
                >
                  Reset
                </Button>
                <Button
                  type="submit"
                  className="procces_Button"
                  style={{ fontSize: "16px", fontFamily: "Gilroy" }}
                  onClick={fetchTheMerchantTransacrionHistory}
                >
                  Apply filter
                </Button>
              </div>
            </div>
            <Card className="mt-5">
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={columns}
                  // data={products}
                  data={transactionData}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={transactionData}
                      search
                      exportCSV
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col md="7"></Col>
                            <Col md="1">
                            {actionData === null || (actionData?.Withdrawal?.action=== true) ? (
                              <div className="Image_for_export_excel_pdf">
                                <img src={pdfimg} alt="image" onClick={handleDownloadPDF} className="me-2" />
                                <img src={excelimg} alt="image" onClick={onDownload} />
                                <ExportCSVButton {...toolkitProps.csvProps} className="export_csv_button"><img src={csvimg} alt="image" /></ExportCSVButton>
                              </div>
                            ):null}
                            </Col>
                          </Row>

                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable

                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  //   selectRow={selectRow}
                                  classes={
                                    "table align-middle table-nowrap"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  style={{
                                    fontWeight: "Gilroy",
                                  }}
                                  headerClasses={"custom-header-class"}
                                  rowStyle={{
                                    fontSize: "15px",
                                    fontFamily: "Gilroy",
                                    fontWeight: "400",
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  {...paginationProps}
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <ReactToPrint content={() => componentRef.current} />
      <div style={{ display: "none" }}>
        <TransactionPrint ref={componentRef} transactionDetails={popupData} />
      </div>
      <div style={{ display: "none" }} >
        <table id="my-table" ref={tableRef} >
          <thead>
            <tr style={{ backgroundColor: "black", color: "#6ce9eb" }}>
              <th style={{ fontSize: "14px", color: "white" }}>MERCHANT  : {businesName.BusinessName ? businesName.BusinessName : ''} | GENERATED AT  : {generatedData} | REPORT FOR WITHDRAW TRANSACTIONS </th>
            </tr>
            <tr
              style={{ backgroundColor: "#6ce9eb", color: "black", fontSize: "12px" }}
            >
              <th >Date</th>
              <th  >Sender</th>
              <th>Sender VPA </th>
              <th>Receiver</th>
              <th>Receiver VPA</th>
              <th>UTR</th>
              <th>Amount</th>
              <th>Conversion cost</th>
              <th>Conversion Rate</th>
              <th>Platform Fees</th>
              <th>Source Tax</th>
              <th>Tax</th>
              <th>Net Total</th>
              <th>Payment Type</th>
              <th>source</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {transactionData.map((item, index) => (
              <tr key={index}>
                <td>{item.Transaction_date}</td>
                <td>{item.sender_name}</td>
                <td>{item.Sender_vpa}</td>
                <td>{item.receiver_name}</td>
                <td>{item.Receiver_vpa}</td>
                <td>{item.UTR}</td>
                <td>{item.Amount}</td>
                <td>{item.Conversion_cost}</td>
                <td>{item.Conversion_rate}</td>
                <td>{item.Platform_fees}</td>
                <td>{item.Source_tax}</td>
                <td>{item.Tax}</td>
                <td>{item.NetTotal}</td>
                <td>{item.Payment_type}</td>
                <td>{item.Source}</td>
                <td>{item.Status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* tableDemo  */}
      <div style={{ display: "none" }} >
        <ToolkitProvider keyField="id" columns={columns1} data={transactionData}>
          {toolkitProps => (
            <React.Fragment>
              <Row>
                <Col xl="12">
                  <div className="table-responsive">
                    <BootstrapTable
                      id="my-table2"
                      {...toolkitProps.baseProps}
                      responsive
                      bordered={false}
                      striped={false}
                      defaultSorted={defaultSorted}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"thead-light"}
                      style={{
                        fontWeight: "Gilroy",
                      }}
                      headerClasses={"custom-header-class"}
                      rowStyle={{
                        fontSize: "15px",
                        fontFamily: "Gilroy",
                        fontWeight: "400",
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </ToolkitProvider>
      </div>
    </React.Fragment>
  );
};
export default SendHistory;

































































































import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchMerchantProfile, fetchUserProfile, fetchmerchantPayment, gNoOfTransaction, gTotalDashBoard2, gTotalInsights, gTotalTranscationPerDay } from "./ContextApi";
import { io } from "socket.io-client";
const DataContext = createContext();

const API_BASE_URL = process.env.REACT_APP_API_URL;
export const useData = () => {
  return useContext(DataContext);
};
const token = JSON.parse(localStorage.getItem('authUser'));
export const DataProvider = React.memo(({ children }) => {
  const [data, setData] = useState(null)
  const [mData, setMData] = useState({})
  const [userDataa, setUserDatas] = useState([]);
  const [socket, setSocket] = useState(null);
  const [notifications, setNotification] = useState([]);
  const [profileData, setProfileData] = useState({})
  const [totalTransacrionPerDay, setTotalTransacrionPerDay] = useState([])
  const [totalInsights, setTotalInsights] = useState([])
  const [noOfTranscation, setNoOfTransaction] = useState([])
  const [balance, setBalance] = useState({})
  const [dashboardWidgetData, setDashboardWidgetData] = useState({})
  const [iPAddress, setIPAddress] = useState('')

  useEffect(() => { if (mData) { setUserDatas({ ...mData.result })} }, [mData])

  useEffect(() => { if (mData) { setData({ ...mData?.subResult }) } }, [mData?.subResult])

  const fetchData = async (fetchFunction, setter) => {
    try {
      const response = await fetchFunction();
      const result = response.result;
      setter(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token && token.length > 0) {
      if (mData) {
        fetchData(fetchUserProfile, setMData);
      }
      fetchData(fetchMerchantProfile, setProfileData);
      fetchData(gTotalTranscationPerDay, setTotalTransacrionPerDay)
      fetchData(gTotalInsights, setTotalInsights)
      fetchData(gNoOfTransaction, setNoOfTransaction)
    }
    handleFetch()
  }, [token]);

  useEffect(() => {
    if (userDataa) {
      const newSocket = io(`${API_BASE_URL}`, { query: { userId: userDataa?.id } });
      const socket = io(`${API_BASE_URL}`);
      const userId = userDataa.id;
      socket.emit('userConnected', userId);

      newSocket.on("running", data => {
        setNotification(data);
      });

      newSocket.on("MBalance", data => {
        setBalance(data);
      });

      newSocket.on("DashboardWidget", data => {
        setDashboardWidgetData(data)
      })

      return () => {
        socket.disconnect();
      };
    }
  }, [userDataa]);

  function handleFetch (){
    fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then(data => {
     setIPAddress(data.ip)
    })
    .catch(error => {
      console.error('Error fetching IP address:', error);
    });
    }

  return (
    <DataContext.Provider
      value={{
        userDataa,
        mData,
        socket,
        notifications,
        profileData,
        totalTransacrionPerDay,
        totalInsights,
        noOfTranscation,
        balance,
        dashboardWidgetData,
        fetchUserProfile,
        data,
        iPAddress
      }}
    >
      {children}
    </DataContext.Provider>
  );
});

import React from "react";
import "toastr/build/toastr.min.css";
import { useData } from "Context/ContextProvider";
import { CURRENCY } from "components/Common/Currency";

const HeaderBalance = () => {
    const { balance } = useData();
    return (
        <>
            {balance?.Balance && (
            <div className="header-balance">
                <span>{CURRENCY} {balance?.Balance}</span>
            </div>
            )}
        </>
    );
};


export default HeaderBalance;

import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./Register.css";
import { Button, Card, Col, Container, Form, Input, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import warning from "../../assets/images/NewImage/warning.png";
import { getMerchantDetailsBYapplicationId, registerApiForMerchant } from "Auth/ApiFunction";
function Register() {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("");
  const[applicationId,setapplicationId] = useState("")
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const history = useHistory();
  const [formErrors, setFormErrors] = useState({});
  const [formDatas, setFormData] = useState({
    title: "",
    firstname: "",
    middlename: "",
    lastname: "",
    email: "",
    number: "",
    nationalId: "",
    nationaUploadDoc: null,
    personalAddressproof: "",
    personaluploaddoc: null,
    personalSelfie: null,
    password: "",
    confirmPassword: "",
    // step 2

    businessaddressProof: "",
    documentFile2: null,
    documentFile3: null,
    documentFile4: null,
    documentFile5: null,
    companyTradingName: "",
    registeredLegalName: "",
    businessEntity: "",
    subCategory: "222",
    businessDescription: "",
    zIMRABPno: "",

    // step 3
    ownerName: "",
    ownerDoc: "",
    ownerIdNo: "",
    startYear: "",
    notificationEmail: "",
    primaryContactPerson: "",
    companytelephone: "",
    companywebsite: "",
    shippintradingaddress: "",
    shippingtradingcountry: "",
  });
  console.log(formDatas);
  const handleFileChange = (e, fieldName) => {
    const selectedFile = e.target.files[0];
    const fileValidationError = validateFile(selectedFile);
  
    setFormErrors({ ...formErrors, [fieldName]: fileValidationError });
  
    if (!fileValidationError) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({
          ...formDatas,
          [fieldName]: {
            file: selectedFile,
            filename: selectedFile.name,
            preview: reader.result,
          },
        });
      };
  
      reader.readAsDataURL(selectedFile);
      setFormErrors({ ...formErrors, [fieldName]: "" });
    }
  };
  
  // const handleFileChange = (e, fieldName) => {
  //   const selectedFile = e.target.files[0];
  //   const fileValidationError = validateFile(selectedFile);

  //   setFormErrors({ ...formErrors, [fieldName]: fileValidationError });

  //   if (!fileValidationError) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setFormData({
  //         ...formDatas,
  //         [fieldName]: {
  //           file: selectedFile,
  //           preview: reader.result,
  //         },
  //       });
  //     };

  //     reader.readAsDataURL(selectedFile);
  //     setFormErrors({ ...formErrors, [fieldName]: "" });
  //   }
  // };

  const handleChange = e => {
    const { name, value, type } = e.target;
    // if (type === "file") {
    //   const selectedFile = e.target.files[0];
    //   const fileValidationError = validateFile(selectedFile);
    //   setFormErrors({
    //     ...formErrors,
    //     [name]: fileValidationError,
    //   });
    //   if (!fileValidationError) {
    //     const reader = new FileReader();

    //     reader.onloadend = () => {
    //       setFormData({
    //         ...formDatas,
    //         [name]: {
    //           file: selectedFile,
    //           filename:selectedFile,
    //           preview: reader.result,
    //         },
    //       });
    //     };
    //     reader.readAsDataURL(selectedFile);
    //   }
    // } else {
      setFormData({ ...formDatas, [name]: value });
    // }
  };
  const validateFile = file => {
    if (!file) {
      return "Please upload a file";
    }
    return null;
  };

  const validateStep1 = () => {
    let errors = {};
    if (!formDatas.firstname) {
      errors.firstname = "Please enter your first name";
    }
    if (!formDatas.lastname) {
      errors.lastname = "Please enter your last name";
    }
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!validateEmail(formDatas.email, emailPattern)) {
      errors.email = "Please enter a valid email ID";
    }
    const phonePattern = /^\d{14}$/;
    if (!validatePhoneNumber(formDatas.number, phonePattern)) {
      errors.number = "Please enter a valid phone number (e.g., +1234567890)";
    }

    const nationalIdPattern = /^[A-Z0-9]+$/;
    if (!notionalEmail(formDatas.nationalId, nationalIdPattern)) {
      errors.nationalId = "Please enter a valid national ID containing only uppercase letters (A-Z) and/or digits 0-3.";
    }
    if (!formDatas.personalAddressproof) {
      errors.personalAddressproof = "Please enter your document number";
    }
    if (!formDatas.personaluploaddoc) {
      errors.personaluploaddoc = "Please upload a document file";
    } else {
      const fileValidationError = validateFile(
        formDatas.personaluploaddoc.file
      );

      if (fileValidationError) {
        errors.personaluploaddoc = fileValidationError;
      }
    }
    if (!formDatas.password) {
      errors.password = "Please enter a password";
    } else if (formDatas.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    } else if (
      !/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        formDatas.password
      )
    ) {
      errors.password =
        "Password should be a combination of alphabets, numbers, special characters, minimum 8 characters";
    }

    if (!formDatas.confirmPassword) {
      errors.confirmPassword = "Please confirm your password";
    } else if (formDatas.password !== formDatas.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };
  const validateStep2 = () => {
    let errors = {};

    if (!formDatas.companyTradingName) {
      errors.companyTradingName = "Please enter Company / Trading Name";
    }
    if (!formDatas.registeredLegalName) {
      errors.registeredLegalName = "Please enter Registered Legal Name";
    }

    // Validate ZIMRA BP Number

    const zIMRABPnoPattern = /^[A-Z0-9]+$/;
    if (!validAlphanumeric(formDatas.zIMRABPno, zIMRABPnoPattern)) {
      errors.zIMRABPno = "Please enter a valid ZIMRABP no";
    }

    // Validate Document Number
    if (!formDatas.businessaddressProof) {
      errors.businessaddressProof = "Please enter Document Number";
    }

    if (!formDatas.documentFile2) {
      errors.documentFile2 = "Please upload a document file";
    } else {
      const fileValidationError = validateFile(formDatas.documentFile2.file);

      if (fileValidationError) {
        errors.documentFile2 = fileValidationError;
      }
    }
    if (!formDatas.documentFile3) {
      errors.documentFile3 = "Please upload a document file";
    } else {
      const fileValidationError = validateFile(formDatas.documentFile3.file);

      if (fileValidationError) {
        errors.documentFile3 = fileValidationError;
      }
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };
  const validateForm3 = () => {
    let errors = {};

    // Other validations...

    // Validate Owner / CEO Name
    if (!formDatas.ownerName) {
      errors.ownerName = "Please enter the owner/CEO name";
    }

    // Validate Owner / CEO Doc
    if (!formDatas.ownerDoc) {
      errors.ownerDoc = "Please select the owner/CEO document type";
    }

    // Validate Owner / CEO ID No
    if (!formDatas.ownerIdNo) {
      errors.ownerIdNo = "Please enter the owner/CEO ID number";
    }

    // Validate Start year of business
    if (!formDatas.startYear) {
      errors.startYear = "Please enter the start year of the business";
    }

    // Validate Notification email
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!validateEmail1(formDatas.notificationEmail, emailPattern)) {
      errors.notificationEmail = "Please enter a valid email address";
    }

    // Validate Primary contact person
    if (!formDatas.primaryContactPerson) {
      errors.primaryContactPerson = "Please enter the primary contact person";
    }

    // Other validations...

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const validateEmail = (email, pattern) => {
    const emailPattern = pattern || /^\S+@\S+\.\S+$/;
    return emailPattern.test(email);
  };
  const validatePhoneNumber = (mobile, pattern) => {
    const emailPattern = pattern || /^\d{10}$/;
    return emailPattern.test(mobile);
  };
  const validateEmail1 = (notificationEmail, pattern) => {
    const emailPattern = pattern || /^\S+@\S+\.\S+$/;
    return emailPattern.test(notificationEmail);
  };
  const validAlphanumeric = (zIMRABPno, pattern) => {
    const emailPattern = pattern || /^[A-Z0-9]+$/;
    return emailPattern.test(zIMRABPno);
  }
  const notionalEmail = (nationalId, pattern) => {
    const emailPattern = pattern || /^[A-Z0-9]+$/;
    return emailPattern.test(nationalId);
  }


  const [captchaValue, setCaptchaValue] = useState(null);
  const handleCaptchaChange = value => {
    console.log("Captcha value:", value);
    setCaptchaValue(value);
  };
  const handleSubmit = () => {
    const finalpagevalidation = validateForm3();
    if (finalpagevalidation && captchaValue) {
      console.log("Form submitted!");
      alert("Form submitted!");
      setCurrentStep(1);
      handleRegisterMerchant()
      history.push("login");
    } else {
      console.log("Please complete the CAPTCHA.");
      alert("Please complete the CAPTCHA.");
    }
  };

  const handleRegisterMerchant = async () => {
    const formData = new FormData();
    // Step 1
    formData.append("title", formDatas.title);
    formData.append("firstname", formDatas.firstname);
    formData.append("middlename", formDatas.middlename);
    formData.append("lastname", formDatas.lastname);
    formData.append("email", formDatas.email);
    formData.append("number", formDatas.number);
    formData.append("nationalId", formDatas.nationalId);
    formData.append("personalAddressproof", formDatas.personalAddressproof);
    formData.append("password", formDatas.password);
    formData.append("status", "PENDING");
    // Step 2
    formData.append("companyTradingName", formDatas.companyTradingName);
    formData.append("registeredLegalName", formDatas.registeredLegalName);
    formData.append("category", formDatas.category);
    formData.append("businessEntity", formDatas.businessEntity);
    formData.append("subCategory", formDatas.subCategory)
    formData.append(
      "shortBusinessDescription",
      formDatas.businessDescription
    );
    formData.append("zIMRABPno", formDatas.zIMRABPno);
    formData.append("businessaddressProof", formDatas.businessaddressProof);
    // Step 3
    formData.append("ownerceoname", formDatas.ownerName);
    formData.append("ownerceodoc", formDatas.ownerDoc);
    formData.append("ownerceoidno", formDatas.ownerIdNo);
    formData.append("startYearOfBusiness", formDatas.startYear);
    formData.append("notificationEmail", formDatas.notificationEmail);
    formData.append("primaryContactPerson", formDatas.primaryContactPerson);
    formData.append("companyTelephone", formDatas.companytelephone);
    formData.append("companyWebsite", formDatas.companywebsite);
    formData.append("address", formDatas.shippintradingaddress);
    formData.append("country", formDatas.shippingtradingcountry);
    // if (Array.isArray(formDatas.nationaUploadDoc)) {
    //   formDatas.nationaUploadDoc.forEach((file) => {
    //     formData.append("nationaUploadDoc", file);
    //   });
    // } else {
    //   console.error("formDatas.nationaUploadDoc is not an array");
    // }
    
    formData.append("personaluploaddoc", formDatas.personaluploaddoc);
      
    // if (Array.isArray(formDatas.personaluploaddoc)) {
    //   // Iterate over the array
    //   formDatas.personaluploaddoc.forEach((file) => {
    //     formData.append("personaluploaddoc", file);
    //   });
    // } else {
    //   console.error("formDatas.nationaUploadDoc is not an array");
    // }
    
      console.log(formData);
    try {
      const response = await registerApiForMerchant(formData);
      console.log(response);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  const handleProceed = async nextStep => {
    let isStepValid;

    switch (currentStep) {
      case 1:
        isStepValid = validateStep1();
        break;
      case 2:
        isStepValid = validateStep2();
        break;
      default:
        isStepValid = true;
        break;
    }
    if (nextStep === currentStep + 1 && isStepValid) {
      try {
        if (currentStep === 1) {
          await handleRegisterMerchant();
        } 
        await handleRegisterMerchant()
        // setCurrentStep(nextStep);
        console.log("success");
      } catch (error) {
        console.log("Error during registration:", error);
        console.log("Registration was not successful");
      }
    } else if (nextStep === 0) {
      setCurrentStep(1);
    }
  };

  const categoryOptions = [
    { value: "artsandcrafts", label: "Arts and crafta" },
    { value: "Baby", label: "Baby" },
    { value: "beautyandfragrances", label: "Beauty and fragrances" },
    { value: "Booksandmagazines", label: "Books and magazines" },
    { value: "Businesstobusiness", label: "Business to business" },
    {
      value: "Clothingaccessoriesandshoes",
      label: "Clothing, accessories, and shoes",
    },
    {
      value: "Computersaccessoriesandservices",
      label: "Computers, accessories, and services",
    },
    { value: "Education", label: "Education" },
    { value: "Electronicsandtelecom", label: "Electronics and telecom" },
    { value: "Entertainmentandmedia", label: "Entertainment and media" },
    {
      value: "Financialservicesandproducts",
      label: "Financial services and products",
    },
    { value: "Foodretailandservice", label: "Food retail and service" },
    { value: "Giftsandflowers", label: "Gifts and flowers" },
    { value: "Government", label: "Government" },
    { value: "Healthandpersonalcare", label: "Health and personal care" },
    { value: "Homeandgarden", label: "Home and garden" },
    { value: "Nonprofit", label: "Nonprofit" },
    { value: "Petsandanimals", label: "Pets and animals" },
    {
      value: "Religionandspiritualityprofit",
      label: "Religion and spirituality (for profit)",
    },
    {
      value: "Retailnotelsewhereclassifies",
      label: "Retail (not elsewhere classifies)",
    },
    { value: "Servicesother", label: "Services - Other" },
    { value: "Sportsandoutdoors", label: "Sports and outdoors" },
    { value: "Toysandhobbies", label: "Toys And Hobbies" },
    { value: "Travel", label: "Travel" },
    { value: "Vehiclssales", label: "Vehicls sales" },
    {
      value: "Vehiclsserviceandassossories",
      label: "Vehicls service and assossories",
    },
  ];

  const subcategoryOptionsMap = {
    artsandcrafts: [
      { value: "sub1.1", label: "Antiques" },
      { value: "sub1.2", label: "Art and crafy supplies" },
      { value: "sub1.3", label: "Art dealers and galleries" },
      { value: "sub1.4", label: "Camera and photographic supplies" },
      { value: "sub1.5", label: "Digital art" },
      { value: "sub1.6", label: "Memorabilia" },
      { value: "sub1.7", label: "Music store (instruments and sheet music)" },
      { value: "sub1.8", label: "Sewing, needlework, and febrics" },
      { value: "sub1.9", label: "Stamp and coin" },
      { value: "sub1.10", label: "Stationary, printing and writing paper" },
      { value: "sub1.11", label: "Vintage and collectibles" },
    ],
    Baby: [
      { value: "sub2.1", label: "Clothing" },
      { value: "sub2.2", label: "Furniture" },
      { value: "sub2.3", label: "Baby products (other)" },
      { value: "sub2.4", label: "Safety and health" },
    ],
    beautyandfragrances: [
      { value: "sub3.1", label: "Bath and body" },
      { value: "sub3.2", label: "Fragrances and perfumes" },
      { value: "sub3.3", label: "Makeup and cosmetics" },
    ],
    Booksandmagazines: [
      { value: "sub4.1", label: "Audio books" },
      { value: "sub4.2", label: "Digital content" },
      { value: "sub4.3", label: "Educational and textbooks" },
      { value: "sub4.4", label: "Fiction and nonfiction" },
      { value: "sub4.5", label: "Magazines" },
      { value: "sub4.6", label: "Publishing and printing" },
      { value: "sub4.7", label: "Rare and used books" },
    ],
    Businesstobusiness: [
      { value: "sub5.1", label: "Accounting" },
      { value: "sub5.2", label: "Advertising" },
      { value: "sub5.3", label: "Agricultural" },
      {
        value: "sub5.4",
        label: "Architectural, enineering, and surveying services",
      },
      { value: "sub5.5", label: "Chemicals and allied products" },
      { value: "sub5.6", label: "Commercial photography, art, and graphics" },
      { value: "sub5.7", label: "Construction" },
      { value: "sub5.8", label: "Consulting services" },
      { value: "sub5.9", label: "Educational services" },
      { value: "sub5.10", label: "Equipment rentals and leasing services" },
      { value: "sub5.11", label: "Equipment repair services" },
      { value: "sub5.12", label: "Hiring services" },
      { value: "sub5.13", label: "Industrial and manufacturing supplies" },
      { value: "sub5.14", label: "Mailling lists" },
      { value: "sub5.15", label: "Multi-level marketing" },
      { value: "sub5.16", label: "Office and commercial furniture" },
      { value: "sub5.17", label: "Office supplies and equipment" },
      { value: "sub5.18", label: "Publishing and priting" },
      { value: "sub5.19", label: "Quick copy and reproduction services" },
    ],
    Clothingaccessoriesandshoes: [
      { value: "sub6.1", label: "Childern's clothing" },
      { value: "sub6.2", label: "Men's clothing" },
      { value: "sub6.3", label: "Women's clothing" },
      { value: "sub6.4", label: "Shoes" },
      { value: "sub6.5", label: "Military and civil service uniforms" },
      { value: "sub6.6", label: "Accessories" },
      { value: "sub6.7", label: "Retail (fine jewelry and watches)" },
      { value: "sub6.8", label: "Wholesale (precious stones and metals)" },
      { value: "sub6.9", label: "Fashion jevelry" },
    ],
    Computersaccessoriesandservices: [
      { value: "sub7.1", label: "Computer and data processing services" },
      { value: "sub7.2", label: "Desktops, laptops, and notebooks" },
      { value: "sub7.3", label: "Digital content" },
      { value: "sub7.4", label: "eCommerce services" },
      { value: "sub7.5", label: "Maintenance and repair services" },
      { value: "sub7.6", label: "Monitors and projectors" },
      { value: "sub7.7", label: "Networking" },
      { value: "sub7.8", label: "Online gaming" },
      { value: "sub7.9", label: "Parts and assessories" },
      { value: "sub7.10", label: "Peripherals" },
      { value: "sub7.11", label: "Software" },
      { value: "sub7.12", label: "Training services" },
      { value: "sub7.13", label: "Web hosting and design" },
    ],
    Education: [
      { value: "sub8.1", label: "Business and secretarial schools" },
      { value: "sub8.2", label: "child day care services" },
      { value: "sub8.3", label: "Colleges and univerities" },
      { value: "sub8.4", label: "Dance halls, studios, and schools" },
      { value: "sub8.5", label: "Primary and secondary schools" },
      { value: "sub8.6", label: "Vacational and trade schools" },
    ],
    Electronicsandtelecom: [
      { value: "sub9.1", label: "Cameras, camcorders, and equipment" },
      { value: "sub9.2", label: "Cell phones, PDAs, and pagers " },
      { value: "sub9.3", label: "General electronic accessories" },
      { value: "sub9.4", label: "Home audio" },
      { value: "sub9.5", label: "Home electronics" },
      { value: "sub9.6", label: "Security and surveillance" },
      { value: "sub9.7", label: "Telecommunication ewuipment and sales" },
      { value: "sub9.8", label: "Telecommunication services" },
      { value: "sub9.9", label: "Telephone cards" },
    ],
    Entertainmentandmedia: [
      { value: "2086", label: "Memorabilia" },
      { value: "2087", label: "Movie tickets" },
      { value: "2088", label: "Movies (DVDs, videotapes)" },
      { value: "2089", label: "Music (CDs, cassettes and albums)" },
      { value: "2090", label: "TV and radio" },
      { value: "2091", label: "Adult digital content" },
      { value: "2092", label: "Concert tickets" },
      { value: "2093", label: "Theater tickets" },
      { value: "2094", label: "Toys and games" },
      { value: "2095", label: "Slot machines" },
      { value: "2096", label: "Digital content" },
      { value: "2097", label: "Entertainers" },
      { value: "2098", label: "Gambling" },
      { value: "2099", label: "Online games" },
      { value: "2100", label: "Video games and systems" },
    ],
    Financialservicesandproducts: [
      { value: "2101", label: "Accounting" },
      { value: "2102", label: "Collection agency" },
      { value: "2103", label: "Commodities and futures exchange" },
      { value: "2104", label: "Consumer credit reporting agencies" },
      { value: "2105", label: "Debt counseling service" },
      { value: "2106", label: "Credit union" },
      { value: "2107", label: "Currency dealer and currency exchange" },
      { value: "2108", label: "Escrow" },
      { value: "2109", label: "Finance company" },
      { value: "2110", label: "Financial and investment advice" },
      { value: "2111", label: "Insurance (auto and home)" },
      { value: "2112", label: "Insurance (life and annuity)" },
      { value: "2113", label: "Investments (general)" },
      { value: "2114", label: "Money service business" },
      { value: "2115", label: "Mortgage brokers or dealers" },
      { value: "2116", label: "Online gaming currency" },
      { value: "2117", label: "Paycheck lender or cash advance" },
      { value: "2118", label: "Prepaid and stored value cards" },
      { value: "2119", label: "Real estate agent" },
      { value: "2120", label: "Remittance" },
      { value: "2121", label: "Rental property management" },
      { value: "2122", label: "Security brokers and dealers" },
      { value: "2123", label: "Wire transfer and money order" },
    ],
    Foodretailandservice: [
      { value: "2124", label: "Alcoholic beverages" },
      { value: "2125", label: "Catering services" },
      { value: "2126", label: "Coffee and tea" },
      { value: "2127", label: "Gourmet foods" },
      { value: "2128", label: "Specialty and miscellaneous food stores" },
      { value: "2129", label: "Restaurant" },
      { value: "2130", label: "Tobacco" },
      { value: "2131", label: "Vitamins and supplements" },
    ],
    Giftsandflowers: [
      { value: "2132", label: "Florist" },
      { value: "2133", label: "Gift, card, novelty, and souvenir shops" },
      { value: "2134", label: "Gourmet foods" },
      { value: "2135", label: "Nursery plants and flowers" },
      { value: "2136", label: "Party supplies" },
    ],
    Government: [
      {
        value: "2137",
        label: "Government services (not elsewhere classified)",
      },
    ],
    Healthandpersonalcare: [
      { value: "2138", label: "Drugstore (excluding prescription drugs)" },
      { value: "2139", label: "Drugstore (including prescription drugs)" },
      { value: "2140", label: "Dental care" },
      { value: "2141", label: "Medical care" },
      { value: "2142", label: "Medical equipment and supplies" },
      { value: "2143", label: "Vision care" },
      { value: "2144", label: "Vitamins and supplements" },
    ],
    Homeandgarden: [
      { value: "2145", label: "Antiques" },
      { value: "2146", label: "Appliances" },
      { value: "2147", label: "Art dealers and galleries" },
      { value: "2148", label: "Bed and bath" },
      { value: "2149", label: "Construction material" },
      { value: "2150", label: "Drapery, window covering, and upholstery" },
      { value: "2151", label: "Exterminating and disinfecting services" },
      { value: "2152", label: "Fireplace, and fireplace screens" },
      { value: "2153", label: "Furniture" },
      { value: "2154", label: "Garden supplies" },
      { value: "2155", label: "Glass, paint, and wallpaper" },
      { value: "2156", label: "Hardware and tools" },
      { value: "2157", label: "Home decor" },
      { value: "2158", label: "Housewares" },
      { value: "2159", label: "Kitchenware" },
      { value: "2160", label: "Landscaping" },
      { value: "2161", label: "Rugs and carpets" },
      { value: "2162", label: "Security and surveillance equipment" },
      { value: "2163", label: "Swimming pools and spas" },
    ],
    Nonprofit: [
      { value: "2164", label: "Charity" },
      { value: "2165", label: "Political" },
      { value: "2166", label: "Religious" },
      { value: "2167", label: "Other" },
      { value: "2168", label: "Personal" },
      { value: "2169", label: "Educational" },
    ],
    Petsandanimals: [
      { value: "2171", label: "Medication and supplements" },
      { value: "2172", label: "Pet shops, pet food, and supplies" },
      { value: "2173", label: "Specialty or rare pets" },
      { value: "2174", label: "Veterinary services" },
    ],
    Religionandspiritualityprofit: [
      { value: "2175", label: "Membership services" },
      { value: "2176", label: "Merchandise" },
      { value: "2177", label: "Services (not elsewhere classified)" },
    ],
    Retailnotelsewhereclassifies: [
      { value: "2178", label: "Chemicals and allied products" },
      { value: "2179", label: "Department store" },
      { value: "2180", label: "Discount store" },
      { value: "2181", label: "Durable goods" },
      { value: "2182", label: "Non-durable goods" },
      { value: "2183", label: "Used and secondhand store" },
      { value: "2184", label: "Variety store" },
    ],
    Servicesother: [
      { value: "2185", label: "Advertising" },
      { value: "2186", label: "Shopping services and buying clubs" },
      { value: "2187", label: "Career services" },
      { value: "2188", label: "Carpentry" },
      { value: "2189", label: "Child care services" },
      { value: "2190", label: "Cleaning and maintenance" },
      { value: "2191", label: "Commercial photography" },
      { value: "2192", label: "Computer and data processing services" },
      { value: "2193", label: "Computer network services" },
      { value: "2194", label: "Consulting services" },
      { value: "2195", label: "Counseling services" },
      { value: "2196", label: "Courier services" },
      { value: "2197", label: "Dental care" },
      { value: "2198", label: "eCommerce services" },
      { value: "2199", label: "Electrical and small appliance repair" },
      { value: "2200", label: "Entertainment" },
      { value: "2201", label: "Equipment rental and leasing services" },
      { value: "2202", label: "Event and wedding planning" },
      { value: "2203", label: "Gambling" },
      { value: "2204", label: "General contractors" },
      { value: "2205", label: "Graphic and commercial design" },
      { value: "2206", label: "Health and beauty spas" },
      { value: "2207", label: "IDs, licenses, and passports" },
      { value: "2208", label: "Importing and exporting" },
      { value: "2209", label: "Information retrieval services" },
      { value: "2210", label: "Insurance - auto and home" },
      { value: "2211", label: "Insurance - life and annuity" },
      { value: "2212", label: "Landscaping and horticultural" },
      { value: "2213", label: "Legal services and attorneys" },
      { value: "2214", label: "Local delivery service" },
      { value: "2215", label: "Lottery and contests" },
      { value: "2216", label: "Medical care" },
      { value: "2217", label: "Membership clubs and organizations" },
      { value: "2218", label: "Misc. publishing and printing" },
      { value: "2219", label: "Moving and storage" },
      { value: "2220", label: "Online dating" },
      { value: "2221", label: "Photofinishing" },
      { value: "2222", label: "Photographic studios - portraits" },
      { value: "2223", label: "Protective and security services" },
      { value: "2224", label: "Quick copy and reproduction services" },
      { value: "2225", label: "Radio, television, and stereo repair" },
      { value: "2226", label: "Real estate agent" },
      { value: "2227", label: "Rental property management" },
      { value: "2228", label: "Reupholstery and furniture repair" },
      { value: "2229", label: "Services (not elsewhere classified)" },
      { value: "2230", label: "Shipping and packing" },
      { value: "2231", label: "Swimming pool services" },
      { value: "2232", label: "Tailors and alterations" },
      { value: "2233", label: "Telecommunication service" },
      { value: "2234", label: "Utilities" },
      { value: "2235", label: "Vision care" },
      { value: "2236", label: "Watch, clock, and jewelry repair" },
    ],
    Servicesother: [
      { value: "2237", label: "Athletic shoes" },
      { value: "2238", label: "Bicycle shop, service, and repair" },
      { value: "2239", label: "Boating, sailing and accessories" },
      { value: "2240", label: "Camping and outdoors" },
      { value: "2241", label: "Dance halls, studios, and schools" },
      { value: "2242", label: "Exercise and fitness" },
      { value: "2243", label: "Fan gear and memorabilia" },
      { value: "2244", label: "Firearm accessories" },
      { value: "2245", label: "Firearms" },
      { value: "2246", label: "Hunting" },
      { value: "2247", label: "Knives" },
      { value: "2248", label: "Martial arts weapons" },
      { value: "2249", label: "Sport games and toys" },
      { value: "2250", label: "Sporting equipment" },
      { value: "2251", label: "Swimming pools and spas" },
    ],
    Sportsandoutdoors: [
      { value: "2237", label: "Athletic shoes" },
      { value: "2238", label: "Bicycle shop, service, and repair" },
      { value: "2239", label: "Boating, sailing and accessories" },
      { value: "2240", label: "Camping and outdoors" },
      { value: "2241", label: "Dance halls, studios, and schools" },
      { value: "2242", label: "Exercise and fitness" },
      { value: "2243", label: "Fan gear and memorabilia" },
      { value: "2244", label: "Firearm accessories" },
      { value: "2245", label: "Firearms" },
      { value: "2246", label: "Hunting" },
      { value: "2247", label: "Knives" },
      { value: "2248", label: "Martial arts weapons" },
      { value: "2249", label: "Sport games and toys" },
      { value: "2250", label: "Sporting equipment" },
      { value: "2251", label: "Swimming pools and spas" },
    ],
    Toysandhobbies: [
      { value: "2252", label: "Arts and crafts" },
      { value: "2253", label: "Camera and photographic supplies" },
      { value: "2254", label: "Hobby, toy, and game shops" },
      { value: "2255", label: "Memorabilia" },
      { value: "2256", label: "Music store - instruments and sheet music" },
      { value: "2257", label: "Stamp and coin" },
      { value: "2258", label: "Stationary, printing, and writing paper" },
      { value: "2259", label: "Vintage and collectibles" },
      { value: "2260", label: "Video games and systems" },
    ],
    Travel: [
      { value: "2261", label: "Airline" },
      { value: "2262", label: "Auto rental" },
      { value: "2263", label: "Bus line" },
      { value: "2264", label: "Cruises" },
      { value: "2265", label: "Lodging and accommodations" },
      { value: "2266", label: "Luggage and leather goods" },
      { value: "2267", label: "Recreational services" },
      { value: "2268", label: "Sporting and recreation camps" },
      { value: "2269", label: "Taxicabs and limousines" },
      { value: "2270", label: "Timeshares" },
      { value: "2271", label: "Tours" },
      { value: "2272", label: "Trailer parks or campgrounds" },
      { value: "2273", label: "Transportation services - other" },
      { value: "2274", label: "Travel agency" },
    ],
    Vehiclssales: [
      { value: "2275", label: "Auto dealer - new and used" },
      { value: "2276", label: "Auto dealer - used only" },
      { value: "2277", label: "Aviation" },
      { value: "2278", label: "Boat dealer" },
      { value: "2279", label: "Mobile home dealer" },
      { value: "2280", label: "Motorcycle dealer" },
      { value: "2281", label: "Recreational and utility trailer dealer" },
      { value: "2282", label: "Recreational vehicle dealer" },
      { value: "2283", label: "Vintage and collectibles" },
    ],
    Vehiclsserviceandassossories: [
      { value: "2284", label: "New parts and supplies - motor vehicle" },
      { value: "2285", label: "Used parts - motor vehicle" },
      { value: "2286", label: "Audio and video" },
      { value: "2287", label: "Auto body repair and paint" },
      { value: "2288", label: "Auto rental" },
      { value: "2289", label: "Auto service" },
      { value: "2290", label: "Automotive tire supply and service" },
      { value: "2291", label: "Boat rental and leases" },
      { value: "2292", label: "Car wash" },
      { value: "2293", label: "Motor home and recreational vehicle rental" },
      { value: "2294", label: "Tools and equipment" },
      { value: "2295", label: "Towing service" },
      { value: "2296", label: "Truck and utility trailer rental" },
      { value: "2297", label: "Accessories" },
    ],
  };
  const handleCategoryChange = event => {
    const selectedCategory = event.target.value;

    setFormErrors({ ...formErrors, category: "" });
    if (!selectedCategory) {
      setFormErrors({ ...formErrors, category: "Please select a category" });
    }
    setSelectedCategory(selectedCategory);
    setFormData({ ...formDatas, category: selectedCategory });

    setSubcategoryOptions(subcategoryOptionsMap[selectedCategory] || []);
  };

  const handleFetchTheDetails = async() =>{
    const formData ={
      applicationId:applicationId
    }
    try {
      const response = await getMerchantDetailsBYapplicationId(formData)
      console.log(response);
      setFormData({...formDatas,...response.result });
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <div style={{ marginBottom: "5%", marginTop: "5%" }}>
        <div className="container-fluid">
          <Container>
            <Card style={{ paddingTop: "50px", paddingBottom: "50px" }}>
              <Row>
                <Col
                  md={{
                    offset: 3,
                    size: 6,
                  }}
                >
                  <h1 className="text-center mb-4 heading_for_progress_bar_register ">
                    MERCHANT REGISTRATION
                  </h1>
                  <p className="text-center steps_text_progress_bar">
                    Step {currentStep} of 3
                  </p>
                  <div className="register_form_fetch_using_applicatio_id">
                    <input type="text" placeholder="ENTER YOUR APPLICATION NUMBER TO CONTINUE REGISTRATION" name="applicationId" value={applicationId} onChange={(e)=>setapplicationId(e.target.value)}/>
                    <button onClick={handleFetchTheDetails}>FETCH</button>
                  </div>
                  <div className="progress_bar_main mt-4 mb-4">
                    <div
                      className={`progress_bar_one ${currentStep >= 1 ? "Progress_bar_active_register" : ""
                        }`}
                    ></div>
                    <div
                      className={`progress_bar_one ${currentStep >= 2 ? "Progress_bar_active_register" : ""
                        }`}
                    ></div>
                    <div
                      className={`progress_bar_one ${currentStep === 3 ? "Progress_bar_active_register" : ""
                        }`}
                    ></div>
                  </div>
                  {currentStep === 1 && (
                    <h4 className="text-center after_heading_description_In_progress_register">
                      Personal details
                    </h4>
                  )}
                  {currentStep === 2 && (
                    <h4 className="text-center after_heading_description_In_progress_register">
                      Business details
                    </h4>
                  )}
                  {currentStep >= 3 && (
                    <h4 className="text-center after_heading_description_In_progress_register">
                      Business ownership details
                    </h4>
                  )}
                </Col>
              </Row>
              {currentStep === 1 && (
                <Row className="mt-5">
                  <Col className="ps-5 pe-5">
                    <Row>
                      <Col md="6" className="mb-4">
                        <div className="form_group_title_and_name">
                          <div
                            className={`form_group_for_merchant_registration ${formErrors.title ? "error_message" : ""
                              }`}
                          >
                            <label>Title</label>
                            <select
                              value={formDatas.title}
                              onChange={e => {
                                const selectedValue = e.target.value;
                                setFormData({
                                  ...formDatas,
                                  title: selectedValue,
                                });

                                if (selectedValue) {
                                  setFormErrors({ ...formErrors, title: "" });
                                }
                              }}
                            >
                              <option selected hidden>
                                Select
                              </option>
                              <option value="Mr">Mr</option>
                              <option value="Ms">Ms</option>
                              <option value="Mrs">Mrs</option>
                            </select>
                          </div>
                          <div
                            className={`form_group_for_merchant_registration ${formErrors.firstname ? "error_message" : ""
                              }`}
                          >
                            <label>
                              First name
                              <span className="mandatory_star">*</span>
                              <span className="warning_message">
                                {formErrors.firstname && <img src={warning} />}
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter first name"
                              name="firstname"
                              value={formDatas.firstname}
                              onChange={e => {
                                handleChange(e);
                                if (e.target.value.trim() !== "") {
                                  setFormErrors({
                                    ...formErrors,
                                    firstname: "",
                                  });
                                }
                              }}
                            />
                            <span className="warning_message">
                              {formErrors.firstname}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col md="3" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.middlename ? "error_message" : ""
                            }`}
                        >
                          <label>Middle name</label>
                          <input
                            type="text"
                            placeholder="Enter middle name"
                            name="middlename"
                            value={formDatas.middlename}
                            onChange={e => {
                              handleChange(e);
                              if (e.target.value.trim() !== "") {
                                setFormErrors({
                                  ...formErrors,
                                  middlename: "",
                                });
                              }
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.lastname ? "error_message" : ""
                            }`}
                        >
                          <label>
                            Last name<span className="mandatory_star">*</span>
                            <span className="warning_message">
                              {formErrors.lastname && <img src={warning} />}
                            </span>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter last name"
                            name="lastname"
                            value={formDatas.lastname}
                            onChange={e => {
                              handleChange(e);
                              if (e.target.value.trim() !== "") {
                                setFormErrors({ ...formErrors, lastname: "" });
                              }
                            }}
                          />
                          <span className="warning_message">
                            {" "}
                            {formErrors.lastname}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.email ? "error_message" : ""
                            }`}
                        >
                          <label>
                            Email address
                            <span className="mandatory_star">*</span>
                            <span className="warning_message">
                              {formErrors.email && <img src={warning} />}
                            </span>
                          </label>
                          <input
                            type="email"
                            placeholder="Enter email"
                            name="email"
                            value={formDatas.email}
                            onChange={e => {
                              handleChange(e);
                              if (e.target.value.trim() !== "") {
                                setFormErrors({ ...formErrors, email: "" });
                              }
                            }}
                          />
                          {/* <span className="document_below_text">
                            An email will be sent to this address to
                            authenticate your username
                          </span> */}
                          <span className="warning_message">
                            {" "}
                            {formErrors.email}
                          </span>
                        </div>
                      </Col>{" "}
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.mobile ? "error_message" : ""
                            }`}
                        >
                          <label>
                            Mobile<span className="mandatory_star">*</span>
                            <span className="warning_message">
                              {/* {formErrors.mobile} */}
                              {formErrors.number && <img src={warning} />}
                            </span>
                          </label>
                          <div className="form_group_input_text_inline">
                            <input
                              type="number"
                              placeholder="Enter mobile"
                              name="number"
                              value={formDatas.number}
                              onChange={e => {
                                handleChange(e);
                                if (e.target.value.trim() !== "") {
                                  setFormErrors({ ...formErrors, number: "" });
                                }
                              }}
                            />
                            <span>eg : +23562723637</span>
                          </div>
                          <span className="warning_message">
                            {" "}
                            {formErrors.number}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration me-3 ${formErrors.nationalId ? "error_message" : ""
                            }`}
                        >
                          <label>
                            National ID
                            <span className="mandatory_star">*</span>
                            <span className="warning_message">
                              {formErrors.nationalId && <img src={warning} />}
                            </span>
                          </label>

                          <input
                            type="text"
                            placeholder="Enter national ID"
                            name="nationalId"
                            className="uppercasefeild"
                            value={formDatas.nationalId}
                            onChange={e => {
                              handleChange(e);
                              if (e.target.value.trim() !== "") {
                                setFormErrors({
                                  ...formErrors,
                                  nationalId: "",
                                });
                              }
                            }}
                          />
                          <span className="warning_message">
                            {" "}
                            {formErrors.nationalId}
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.nationaUploadDoc ? "error_message" : ""
                            }`}
                        >
                          <div className="form_group_document_pdfs_text">
                            <label>
                              Upload document
                              <span className="mandatory_star">*</span>

                              <span className="document_below_text">
                                (Upload pitctures of your national ID)
                              </span>
                              <span className="warning_message">
                                {formErrors.nationaUploadDoc && (
                                  <img src={warning} />
                                )}
                              </span>
                            </label>
                          </div>
                          <Row>
                            <Col md="6" sm="12" xs="12">
                              <Input
                                type="file"
                                size="sm"
                              onChange={e => {
                                handleFileChange(e, "nationaUploadDoc");
                                if (e.target.value.trim() !== "") {
                                  setFormErrors({
                                    ...formErrors,
                                    nationaUploadDoc: "",
                                  });
                                }
                              }}
                              />
                            </Col>
                            <Col md="6" sm="12" xs="12">
                              <Input
                                type="file"
                                size="sm"
                              onChange={e => {
                                handleFileChange(e, "nationaUploadDoc");
                                if (e.target.value.trim() !== "") {
                                  setFormErrors({
                                    ...formErrors,
                                    nationaUploadDoc: "",
                                  });
                                }
                              }}
                              />
                            </Col>
                          </Row>

                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6" className="mb-4 ">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.personalAddressproof
                              ? "error_message"
                              : ""
                            }`}
                        >
                          <label>
                            Address proof ID
                            <span className="mandatory_star">*</span>
                            <span className="warning_message">
                              {/* {formErrors.documentNumber} */}
                              {formErrors.personalAddressproof && (
                                <img src={warning} />
                              )}
                            </span>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter address proof number"
                            name="personalAddressproof"
                            className="uppercasefeild"
                            value={formDatas.personalAddressproof}
                            onChange={e => {
                              handleChange(e);
                              if (e.target.value.trim() !== "") {
                                setFormErrors({
                                  ...formErrors,
                                  personalAddressproof: "",
                                });
                              }
                            }}
                          />
                        </div>
                      </Col>{" "}
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.personaluploaddoc ? "error_message" : ""
                            }`}
                        >
                          <div className="form_group_document_pdfs_text">
                            <label>
                              Upload document
                              <span className="mandatory_star">*</span>
                              <span className="document_below_text">
                                (Upload pitctures of your address proof)
                              </span>
                              <span className="warning_message">
                                {/* {formErrors.documentNumber} */}
                                {formErrors.personaluploaddoc && (
                                  <img src={warning} />
                                )}
                              </span>
                            </label>
                          </div>
                          <Row>
                            <Col md="6" sm="12" xs="12">
                              <Input
                                type="file"
                                size="sm"
                                onChange={e => {
                                  handleFileChange(e, "personaluploaddoc");
                                  if (e.target.value.trim() !== "") {
                                    setFormErrors({
                                      ...formErrors,
                                      personaluploaddoc: "",
                                    });
                                  }
                                }}
                              />
                            </Col>
                            <Col md="6" sm="12" xs="12">
                              <Input
                                type="file"
                                size="sm"
                                onChange={e => {
                                  handleFileChange(e, "personaluploaddoc");
                                  if (e.target.value.trim() !== "") {
                                    setFormErrors({
                                      ...formErrors,
                                      personaluploaddoc: "",
                                    });
                                  }
                                }}
                              />
                            </Col>
                          </Row>

                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.password ? "error_message" : ""
                            }`}
                        >
                          <label>
                            Password<span className="mandatory_star">*</span>
                            <span className="warning_message">
                              {formErrors.password && <img src={warning} />}
                            </span>
                          </label>
                          <input
                            className=""
                            type="password"
                            placeholder="Enter password"
                            name="password"
                            value={formDatas.password}
                            onChange={e => {
                              handleChange(e);
                              if (e.target.value.trim() !== "") {
                                setFormErrors({ ...formErrors, password: "" });
                              }
                            }}
                          />
                          <span className="warning_message">
                            {" "}
                            {formErrors.password}
                          </span>
                        </div>
                      </Col>
                      <Col md="6">
                        <div
                          className={`form_group_for_merchant_registration me-3 ${formErrors.documentFile1 ? "error_message" : ""
                            }`}
                        >
                          <label>
                            Upload document
                            <span className="mandatory_star">*</span>

                          </label>
                          <span className="document_below_text ">
                            (Include a clear selfie of yours holding the
                            document)
                          </span>
                          <span className="warning_message">
                            {/* {formErrors.kycDocument1} */}
                            {formErrors.documentFile2 && <img src={warning} />}
                          </span>
                          <Row>
                            <Col md="6" sm="12" xs="12">
                              <Input
                                type="file"
                                size="sm"
                                onChange={e => {
                                  handleFileChange(e, "documentFile1");
                                  if (e.target.value.trim() !== "") {
                                    setFormErrors({
                                      ...formErrors,
                                      documentFile1: "",
                                    });
                                  }
                                }}
                              />
                            </Col>
                            <Col md="6" sm="12" xs="12">
                              <Input
                                type="file"
                                size="sm"
                                onChange={e => {
                                  handleFileChange(e, "documentFile1");
                                  if (e.target.value.trim() !== "") {
                                    setFormErrors({
                                      ...formErrors,
                                      documentFile1: "",
                                    });
                                  }
                                }}
                              />
                            </Col>
                          </Row>

                        </div>
                      </Col>
                    </Row>
                    <Col md="6" className="mb-4">
                      <div
                        className={`form_group_for_merchant_registration me-3 ${formErrors.confirmPassword ? "error_message" : ""
                          }`}
                      >
                        <label>
                          Confirm password
                          <span className="mandatory_star">*</span>
                          <span className="warning_message">
                            {formErrors.confirmPassword && (
                              <img src={warning} />
                            )}
                          </span>
                        </label>
                        <input
                          type="password"
                          placeholder="Enter confirm password"
                          name="confirmPassword"
                          value={formDatas.confirmPassword}
                          onChange={e => {
                            handleChange(e);
                            if (e.target.value.trim() !== "") {
                              setFormErrors({
                                ...formErrors,
                                confirmPassword: "",
                              });
                            }
                          }}
                        />
                        <span className="warning_message">
                          {formErrors.confirmPassword}
                        </span>
                      </div>
                    </Col>
                    <Col md="2">
                      <Button
                  onClick={() => {
                    handleProceed(currentStep + 1);
                    handleRegisterMerchant();
                  }}
                  
                        className="get_vault_button"
                      >
                        Proceed
                      </Button>
                    </Col>
                  </Col>
                </Row>
              )}
              {currentStep === 2 && (
                <Row className="mt-5">
                  <Col className="ps-5 pe-5">
                    <Row>
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.companyTradingName ? "error_message" : ""
                            }`}
                        >
                          <label>
                            Company / Trading Name
                            <span className="mandatory_star">*</span>
                            <span className="warning_message">
                              {/* {formErrors.companyTradingName} */}
                              {formErrors.companyTradingName && (
                                <img src={warning} />
                              )}
                            </span>
                          </label>
                          <div className="form_group_input_text_inline">
                            <input
                              type="text"
                              placeholder="Enter name"
                              name="companyTradingName"
                              value={formDatas.companyTradingName}
                              onChange={e => {
                                handleChange(e);
                                if (e.target.value.trim() !== "") {
                                  setFormErrors({
                                    ...formErrors,
                                    companyTradingName: "",
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>
                      </Col>{" "}
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.registeredLegalName
                              ? "error_message"
                              : ""
                            }`}
                        >
                          <label>
                            Registered legal name
                            <span className="mandatory_star">*</span>
                            <span className="warning_message">
                              {/* {formErrors.companyTradingName} */}
                              {formErrors.registeredLegalName && (
                                <img src={warning} />
                              )}
                            </span>
                          </label>
                          <div className="form_group_input_text_inline">
                            <input
                              type="text"
                              placeholder="Enter name"
                              name="registeredLegalName"
                              value={formDatas.registeredLegalName}
                              onChange={e => {
                                handleChange(e);
                                if (e.target.value.trim() !== "") {
                                  setFormErrors({
                                    ...formErrors,
                                    registeredLegalName: "",
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.category ? "error_message" : ""
                            }`}
                        >
                          <label>Category</label>
                          <select
                            id="category"
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                          // value={formDatas.category}
                          // onChange={e => {
                          //   const selectedValue = e.target.value;
                          //   setFormData({
                          //     ...formDatas,
                          //     category: selectedValue,
                          //   });

                          //   if (selectedValue) {
                          //     setFormErrors({ ...formErrors, category: "" });
                          //   }
                          // }}
                          >
                            <option selected hidden>
                              Select
                            </option>
                            {categoryOptions.map(category => (
                              <option
                                key={category.value}
                                value={category.value}
                              >
                                {category.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.businessEntity ? "error_message" : ""
                            }`}
                        >
                          <label>Business entity</label>
                          <select
                            value={formDatas.businessEntity}
                            onChange={e => {
                              const selectedValue = e.target.value;
                              setFormData({
                                ...formDatas,
                                businessEntity: selectedValue,
                              });

                              if (selectedValue) {
                                setFormErrors({
                                  ...formErrors,
                                  businessEntity: "",
                                });
                              }
                            }}
                          >
                            <option selected hidden>
                              Select
                            </option>
                            <option value="privatelimitedcompany">
                              Private limited company
                            </option>
                            <option value="listedcompany(PLC)">
                              Listed Company (PLC)
                            </option>
                            <option value="registeredsoletrader">
                              Registered Sole Trader
                            </option>
                            <option value="individual">Individual</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration me-3 ${formErrors.subCategory ? "error_message" : ""
                            }`}
                        >
                          <label>Sub category</label>
                          <select
                            id="subCategory"
                            disabled={subcategoryOptions.length === 0}
                            value={formDatas.subCategory}
                            onChange={e => {
                              const selectedValue = e.target.value;
                              setFormData({
                                ...formDatas,
                                subCategory: selectedValue,
                              });

                              if (selectedValue) {
                                setFormErrors({
                                  ...formErrors,
                                  subCategory: "",
                                });
                              }
                            }}
                          >
                            <option selected hidden>
                              Select
                            </option>
                            {subcategoryOptions.map(subCategory => (
                              <option
                                key={subCategory.value}
                                value={subCategory.value}
                              >
                                {subCategory.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.shortBusinessDescription
                              ? "error_message"
                              : ""
                            }`}
                        >
                          <label>
                            {" "}
                            Short business description{" "}
                            <span className="warning_message">
                              {/* {formErrors.companyTradingName} */}
                              {formErrors.shortBusinessDescription && (
                                <img src={warning} />
                              )}
                            </span>
                          </label>
                          <textarea
                            type="text"
                            placeholder="Description"
                            name="shortBusinessDescription"
                            value={formDatas.shortBusinessDescription}
                            onChange={e => {
                              handleChange(e);
                              if (e.target.value.trim() !== "") {
                                setFormErrors({
                                  ...formErrors,
                                  shortBusinessDescription: "",
                                });
                              }
                            }}
                          />
                          <span className="document_below_text">
                            Please indicate the products and services you offer
                            and whether you offer these to the Zimbabwean or
                            International market.
                          </span>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.zIMRABPno ? "error_message" : ""
                            }`}
                        >
                          <label>
                            ZIMRA BP number
                            <span className="mandatory_star">*</span>
                            <span className="warning_message">
                              {/* {formErrors.zIMRABPno} */}
                              {formErrors.zIMRABPno && <img src={warning} />}
                            </span>
                          </label>
                          <div className="">
                            <input
                              type="text"
                              placeholder="Enter number"
                              name="zIMRABPno"
                              className="uppercasefeild"
                              value={formDatas.zIMRABPno}
                              onChange={e => {
                                handleChange(e);
                                if (e.target.value.trim() !== "") {
                                  setFormErrors({
                                    ...formErrors,
                                    zIMRABPno: "",
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="6" sm="12" xs="12">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.documentFile2 ? "documentFile2" : ""
                            }`}
                        >
                          <div className="form_group_document_pdfs_text">
                            <label>
                              Upload document
                              <span className="mandatory_star">*</span>
                            </label>

                            <span className="warning_message">
                              {/* {formErrors.kycDocument1} */}
                              {formErrors.documentFile2 && (
                                <img src={warning} />
                              )}
                            </span>
                          </div>

                          <Row>
                            <Col md="6" sm="12" xs="12">
                              <Input
                                type="file"
                                size="sm"
                                onChange={e => {
                                  handleFileChange(e, "documentFile2");
                                  if (e.target.value.trim() !== "") {
                                    setFormErrors({
                                      ...formErrors,
                                      documentFile2: "",
                                    });
                                  }
                                }}
                              />
                            </Col>
                            <Col md="6" sm="12" xs="12">
                              <Input
                                type="file"
                                size="sm"
                                onChange={e => {
                                  handleFileChange(e, "documentFile2");
                                  if (e.target.value.trim() !== "") {
                                    setFormErrors({
                                      ...formErrors,
                                      documentFile2: "",
                                    });
                                  }
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.businessaddressProof
                              ? "error_message"
                              : ""
                            }`}
                        >
                          <label>
                            Tax Identification number
                            <span className="mandatory_star">*</span>
                            <span className="warning_message">
                              {/* {formErrors.kycDocument1} */}
                              {formErrors.businessaddressProof && (
                                <img src={warning} />
                              )}
                            </span>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter number"
                            name="businessaddressProof"
                            className="uppercasefeild"
                            value={formDatas.businessaddressProof}
                            onChange={e => {
                              handleChange(e);
                              if (e.target.value.trim() !== "") {
                                setFormErrors({
                                  ...formErrors,
                                  businessaddressProof: "",
                                });
                              }
                            }}
                          />
                        </div>
                      </Col>{" "}
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.firstname ? "documentFile2" : ""
                            }`}
                        >
                          <div className="form_group_document_pdfs_text">
                            <label>Upload document</label>

                            <Row>
                              <Col md="6" sm="12" xs="12">
                                <Input
                                  type="file"
                                  size="sm"
                                  onChange={e => {
                                    handleFileChange(e, "documentFile2");
                                    if (e.target.value.trim() !== "") {
                                      setFormErrors({
                                        ...formErrors,
                                        documentFile2: "",
                                      });
                                    }
                                  }}
                                />
                              </Col>
                              <Col md="6" sm="12" xs="12">
                                <Input
                                  type="file"
                                  size="sm"
                                  className=""
                                  onChange={e => {
                                    handleFileChange(e, "documentFile3");
                                    if (e.target.value.trim() !== "") {
                                      setFormErrors({
                                        ...formErrors,
                                        documentFile3: "",
                                      });
                                    }
                                  }}
                                />
                              </Col>
                            </Row>

                            <Row className="mt-4">
                              <Col md="6" sm="12" xs="12">
                                <Input
                                  type="file"
                                  size="sm"
                                  className=""
                                  onChange={e => {
                                    handleFileChange(e, "documentFile4");
                                    if (e.target.value.trim() !== "") {
                                      setFormErrors({
                                        ...formErrors,
                                        documentFile4: "",
                                      });
                                    }
                                  }}
                                />
                              </Col>
                              <Col md="6" sm="12" xs="12">
                                <Input
                                  type="file"
                                  size="sm"
                                  className=""
                                  onChange={e => {
                                    handleFileChange(e, "documentFile5");
                                    if (e.target.value.trim() !== "") {
                                      setFormErrors({
                                        ...formErrors,
                                        documentFile5: "",
                                      });
                                    }
                                  }}
                                />
                              </Col>
                            </Row>
                            <span className="document_below_text">
                              (Include a clear selfie of yours holding the
                              document)
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <div className="Button_for_next_and_back">
                    <Col md="2">
                      <Button
                        onClick={() => handleProceed(currentStep - 1)}
                        className="get_vault_button"
                      >
                        Back
                      </Button>
                    </Col>
                    <Col md="2">
                      <Button
                        onClick={() => handleProceed(currentStep + 1)}
                        className="get_vault_button"
                      >
                        Confirm
                      </Button>
                    </Col>
                  </div>
                </Row>
              )}
              {currentStep === 3 && (
                <Row className="mt-5">
                  <Col className="ps-5 pe-5">
                    <Row>
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.ownerName ? "error_message" : ""
                            }`}
                        >
                          <label>
                            Owner / CEO Name
                            <span className="mandatory_star">*</span>
                            <span className="warning_message">
                              {/* {formErrors.zIMRABPno} */}
                              {formErrors.ownerName && <img src={warning} />}
                            </span>
                          </label>
                          <div className="form_group_input_text_inline">
                            <input
                              type="text"
                              placeholder="Enter name"
                              name="ownerName"
                              value={formDatas.ownerName}
                              onChange={e => {
                                handleChange(e);
                                if (e.target.value.trim() !== "") {
                                  setFormErrors({
                                    ...formErrors,
                                    ownerName: "",
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>
                      </Col>{" "}
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.ownerDoc ? "error_message" : ""
                            }`}
                        >
                          <label>
                            Owner / CEO Doc
                            <span className="mandatory_star">*</span>
                            <span className="warning_message">
                              {/* {formErrors.zIMRABPno} */}
                              {formErrors.ownerName && <img src={warning} />}
                            </span>
                          </label>
                          <select
                            value={formDatas.ownerDoc}
                            onChange={e => {
                              const selectedValue = e.target.value;
                              setFormData({
                                ...formDatas,
                                ownerDoc: selectedValue,
                              });

                              if (selectedValue) {
                                setFormErrors({ ...formErrors, ownerDoc: "" });
                              }
                            }}
                          >
                            <option selected hidden>
                              Select
                            </option>
                            <option value="zimbebwenationalid">
                              Zimbabwe national ID
                            </option>
                            <option value="zimbabwedriverslicense">
                              Zimbabwe Drivers License
                            </option>
                            <option value="zimbabwepassport">
                              Zimbabwe Passport
                            </option>
                            <option value="othernationalid">
                              Other National ID
                              <span className="mandatory_star">*</span>
                            </option>
                            <option value="otherpassport">
                              Other Passport
                            </option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.ownerIdNo ? "error_message" : ""
                            }`}
                        >
                          <label>
                            Owner / CEO ID No
                            <span className="mandatory_star">*</span>
                            <span className="warning_message">
                              {/* {formErrors.zIMRABPno} */}
                              {formErrors.ownerIdNo && <img src={warning} />}
                            </span>
                          </label>
                          <div className="form_group_input_text_inline">
                            <input
                              type="text"
                              placeholder="Enter number"
                              name="ownerIdNo"
                              value={formDatas.ownerIdNo}
                              onChange={e => {
                                handleChange(e);
                                if (e.target.value.trim() !== "") {
                                  setFormErrors({
                                    ...formErrors,
                                    ownerIdNo: "",
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>
                      </Col>{" "}
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.startYear ? "error_message" : ""
                            }`}
                        >
                          <label>
                            Start year of business
                            <span className="mandatory_star">*</span>
                            <span className="warning_message">
                              {/* {formErrors.zIMRABPno} */}
                              {formErrors.startYear && <img src={warning} />}
                            </span>
                          </label>
                          <div className="form_group_input_text_inline">
                            <input
                              type="number"
                              placeholder="Enter year"
                              name="startYear"
                              value={formDatas.startYear}
                              onChange={e => {
                                handleChange(e);
                                if (e.target.value.trim() !== "") {
                                  setFormErrors({
                                    ...formErrors,
                                    startYear: "",
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.notificationEmail ? "error_message" : ""
                            }`}
                        >
                          <label>
                            Notification email
                            <span className="mandatory_star">*</span>
                            <span className="warning_message">
                              {/* {formErrors.zIMRABPno} */}
                              {formErrors.notificationEmail && (
                                <img src={warning} />
                              )}
                            </span>
                          </label>
                          <input
                            type="email"
                            placeholder="Enter email"
                            name="notificationEmail"
                            value={formDatas.notificationEmail}
                            onChange={e => {
                              handleChange(e);
                              if (e.target.value.trim() !== "") {
                                setFormErrors({
                                  ...formErrors,
                                  notificationEmail: "",
                                });
                              }
                            }}
                          />
                          {/* <span>
                            If the main user email above is different to this
                            email it will make a second user with transaction
                            only access.{" "}
                          </span> */}
                        </div>
                      </Col>
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.primaryContactPerson
                              ? "error_message"
                              : ""
                            }`}
                        >
                          <label>
                            Primary contact person
                            <span className="mandatory_star">*</span>
                            <span className="warning_message">
                              {/* {formErrors.zIMRABPno} */}
                              {formErrors.primaryContactPerson && (
                                <img src={warning} />
                              )}
                            </span>
                          </label>
                          <input
                            type="year"
                            placeholder="Enter number"
                            name="primaryContactPerson"
                            value={formDatas.primaryContactPerson}
                            onChange={e => {
                              handleChange(e);
                              if (e.target.value.trim() !== "") {
                                setFormErrors({
                                  ...formErrors,
                                  primaryContactPerson: "",
                                });
                              }
                            }}
                          />
                          {/* <span>
                            Primary contact is typically assigned to a manager
                            with authority to handle financial matters
                          </span> */}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.firstName ? "error_message" : ""
                            }`}
                        >
                          <label>Company telephone</label>
                          <div className="form_group_input_text_inline">
                            <input
                              type="text"
                              placeholder="Enter number"
                              name="companytelephone"
                              value={formDatas.companytelephone}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </Col>{" "}
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.firstName ? "error_message" : ""
                            }`}
                        >
                          <label>Company website</label>
                          <div className="form_group_input_text_inline">
                            <input
                              type="URL"
                              placeholder="Enter URL"
                              name="companywebsite"
                              value={formDatas.companywebsite}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.firstName ? "error_message" : ""
                            }`}
                        >
                          <label> Shipping / Trading address</label>
                          <textarea
                            type="text"
                            placeholder="Desctription"
                            name="shippintradingaddress"
                            value={formDatas.shippintradingaddress}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col md="6" className="mb-4">
                        <div
                          className={`form_group_for_merchant_registration ${formErrors.firstName ? "error_message" : ""
                            }`}
                        >
                          <label>Shipping / Trading country</label>
                          <div className="form_group_input_text_inline">
                            <input
                              type="text"
                              placeholder="Enter country"
                              name="shippingtradingcountry"
                              value={formDatas.shippingtradingcountry}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <div className="cpathc_in_merchabnt_form">
                    <div className="form_for_captch_in_merchant">
                      <ReCAPTCHA
                        sitekey="6LdxGxcpAAAAALjw0AgB5c73HpxN7at4y9jhZcIK"
                        onChange={handleCaptchaChange}
                      />
                    </div>
                  </div>
                  <div className="Button_for_next_and_back">
                    <Col md="1">
                      <Button
                        onClick={() => handleProceed(currentStep - 1)}
                        className="get_vault_button"
                      >
                        Back
                      </Button>
                    </Col>
                    <Col md="1">
                      <Button
                        className="get_vault_button"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </Col>
                  </div>
                </Row>
              )}
            </Card>
          </Container>
        </div>
      </div>
    </>
  );
}
export default Register;

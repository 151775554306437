import React from "react";
import PaymentsInsightsPaiChart from "./PaymentsInsightsPaiChart";
import { Card, Col, Row } from "reactstrap";
import "./paymentInsights.css";

function PaymentInsights({ data }) {
  let datas = [
    {
      value: 0, name: "Merchant Transfer",
      NumberOfTransactions: 0,
      PercentageOfTotal: "0"
    },
    {
      value: 0, name: "Topup", NumberOfTransactions: 0,
      PercentageOfTotal: "0"
    },
    {
      value: 0, name: "Payment Request", NumberOfTransactions: 0,
      PercentageOfTotal: "0"
    },
    {
      value: 0, name: "Withdrawal", NumberOfTransactions: 0,
      PercentageOfTotal: "0"
    },
  ]
  let defualtData = data ? data : datas;
 
  const namesArray = data.map(item => item.name);
  function getClassName(name) {
    switch (name) {
      case 'Merchant Transfer':
        return 'color_upi_payment_red';
      case 'Topup':
        return 'color_upi_payment_yellow';
      case 'Payment Request':
        return 'color_debit_card_blue';
      case 'Withdrawal':
        return 'color_net_banking_green';
      default:
        return '';
    }
  }
  return (
    <>
      <React.Fragment>
        <Card>
          <Row>
            <Col lg="6" md="6" sm="12" xs="12" className="p-5">
              <h2 style={{ fontFamily: "Gilroy", color: "#db9c45" }}>
                Payment insights
              </h2>
              <PaymentsInsightsPaiChart data={defualtData} name={namesArray} />
            </Col>
            <Col lg="6" md="6" sm="12" xs="12">
              <Row
                className="pt-5 pb-3 me-4"
                style={{ borderBottom: "1px solid black" }}
              >
                <Col sm="5" xs="5">
                  <div className="round_circle_indights_title">
                    <h6 style={{ color: "#db9c45" }}>PAYMENT MODE</h6>
                  </div>
                </Col>
                <Col sm="4" xs="4">
                  <div className="payment_insights_right_text">
                    <h6 style={{ color: "#db9c45" }}>NO OF TRANSACTIONS</h6>
                  </div>
                </Col>
                <Col sm="3" xs="3">
                  <div className="payment_insights_right_text">
                    <h6 style={{ color: "#db9c45" }}>OVERALL %</h6>
                  </div>
                </Col>
              </Row>
              {defualtData?.map((item, index) => (
                <Row
                  className="pt-4 pb-3 me-4"
                  style={{ borderBottom: "1px solid black" }}
                  key={index}
                >
                  <Col sm="5" xs="5">
                    <div className="round_circle_indights_title">
                      {/* <h6 className="round_circle_insights color_upi_payment_red"></h6> */}
                      <h6 className={`round_circle_insights ${getClassName(item?.name)}`}></h6>
                      <h6>{item?.name}</h6>
                    </div>
                  </Col>
                  <Col sm="4" xs="4">
                    <div className="payment_insights_right_text">
                      <h6 >{item?.NumberOfTransactions}</h6>
                    </div>
                  </Col>
                  <Col sm="3" xs="3">
                    <div className="payment_insights_right_text">
                      <h6>{parseFloat(item?.PercentageOfTotal).toFixed(2)}%</h6>
                    </div>
                  </Col>
                </Row>
              ))}

              {/* <Row
                className="pt-4 pb-3 me-4"
                style={{ borderBottom: "1px solid black" }}
              >
                <Col sm="5" xs="5">
                  <div className="round_circle_indights_title">
                    <h6 className="round_circle_insights color_upi_payment_yellow"></h6>
                    <h6>Paytm Wallet</h6>
                  </div>
                </Col>
                <Col sm="4" xs="4">
                  <div className="payment_insights_right_text">
                    <h6>543</h6>
                  </div>
                </Col>
                <Col sm="3" xs="3">
                  <div className="payment_insights_right_text">
                    <h6>33%</h6>
                  </div>
                </Col>
              </Row> */}
              {/* <Row
                className="pt-4 pb-3 me-4"
                style={{ borderBottom: "1px solid black" }}
              >
                <Col sm="5" xs="5">
                  <div className="round_circle_indights_title">
                    <h6 className="round_circle_insights color_debit_card_blue"></h6>
                    <h6>Debit Card</h6>
                  </div>
                </Col>
                <Col sm="4" xs="4">
                  <div className="payment_insights_right_text">
                    <h6>633</h6>
                  </div>
                </Col>
                <Col sm="3" xs="3">
                  <div className="payment_insights_right_text">
                    <h6>42%</h6>
                  </div>
                </Col>
              </Row> */}
              {/* <Row className="pt-4 pb-3 me-4">
                <Col sm="5" xs="5">
                  <div className="round_circle_indights_title">
                    <h6 className="round_circle_insights color_net_banking_green"></h6>
                    <h6>Net Banking</h6>
                  </div>
                </Col>
                <Col sm="4" xs="4">
                  <div className="payment_insights_right_text">
                    <h6>423</h6>
                  </div>
                </Col>
                <Col sm="3" xs="3">
                  <div className="payment_insights_right_text">
                    <h6>25%</h6>
                  </div>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </Card>
      </React.Fragment>

    </>
  );
}
export default PaymentInsights;

import React from "react";
import MerchantvoultImg from "../../assets/images/NewImage/Invoice-Logo.png"
import { CURRENCY } from "components/Common/Currency";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const TransactionPrint = React.forwardRef(function MyComponent(props, ref) {
    const transactionDate = new Date(props.transactionDetails?.Transaction_date);
    const formatDate = (date) => {
        const months = [
            "January", "February", "March",
            "April", "May", "June",
            "July", "August", "September",
            "October", "November", "December"
        ];

        return `Date: ${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
    };
    const formattedTransactionDate = transactionDate && formatDate(transactionDate);
    const jsonParse = props.transactionDetails?.Tax_Details && JSON.parse(props.transactionDetails?.Tax_Details);
    function extractTaxDetails(array) {
        if (!Array.isArray(array)) {
            return [];
        }
        return array.filter(obj => obj.Tax !== undefined);
    }
    const taxDetailsArray = jsonParse?.find(item => item?.Title === "Tax Details")?.Tax;
    const refundtaxDetailsArray = jsonParse?.find(item => item?.Title === "Refund Tax Details")?.Tax;
    const refundTaxtaxDetailsArray = jsonParse?.find(item => item?.Title === "Refund Transaction Tax Details")?.Tax;
    // Extracted tax details
    const taxDetails = extractTaxDetails(jsonParse);

    return (
        <div ref={ref}>
            <style>
                {`
			.invoice-box {
				max-width: 800px;
				margin: auto;
				padding: 30px;
				border: 1px solid #eee;
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
				font-size: 16px;
				line-height: 24px;
				font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
				color: #555;
			}

			.invoice-box table {
				width: 100%;
				line-height: inherit;
				text-align: left;
			}

			.invoice-box table td {
				padding: 5px;
				vertical-align: top;
			}
            .invoice-box table top {
				padding: 5px;
				vertical-align: top;
			}

			.invoice-box table tr td:nth-child(2) {
				text-align: right;
			}

			.invoice-box table tr.top table td {
				padding-bottom: 20px;
			}

			.invoice-box table tr.top table td.title {
				font-size: 45px;
				line-height: 45px;
				color: #333;
			}

			.invoice-box table tr.information table td {
				padding-bottom: 40px;
			}

			.invoice-box table tr.heading td {
				background: #eee;
				border-bottom: 1px solid #ddd;
				font-weight: bold;
			}

			.invoice-box table tr.details td {
				padding-bottom: 20px;
			}

			.invoice-box table tr.item td {
				border-bottom: 1px solid #eee;
			}

			.invoice-box table tr.item.last td {
				border-bottom: none;
			}

			.invoice-box table tr.total td:nth-child(2) {
				border-top: 2px solid #eee;
				font-weight: bold;
			}

			@media only screen and (max-width: 600px) {
				.invoice-box table tr.top table td {
					width: 100%;
					display: block;
					text-align: center;
				}

				.invoice-box table tr.information table td {
					width: 100%;
					display: flex;
                    justify-content: space-between;
					text-align: center;
				}
			}

			.invoice-box.rtl {
				direction: rtl;
				font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
			}

			.invoice-box.rtl table {
				text-align: right;
			}

			.invoice-box.rtl table tr td:nth-child(2) {
				text-align: left;
			}
            .item-right-side{
                display: flex;
                justify-content: end;
                align-items: end;
                align-content: end;
                border-bottom: 1px solid #eee;
            }

            .item-right-side-currency{
                width:20px;
            }

            .item-right-side-span{
                width:60px;
                text-align: right;
            }
    `}
            </style>
            <div className="invoice-box">
                <table cellPadding="0" cellSpacing="0" >
                    <tr className="top">
                        <td colSpan="2">
                            <table>
                                <tr>
                                    <td className="title">
                                    </td>
                                    <td>
                                        <img
                                            src={MerchantvoultImg}
                                            style={{ width: "100%", maxWidth: "120px" }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="title">
                                        <img
                                            src={`${API_BASE_URL}/${props.merchantLogo?.MerchantLogo}`}
                                            style={{ width: "100%", maxWidth: "120px" }}
                                        />
                                    </td>
                                    <td>
                                        UTR #: {props.transactionDetails?.UTR}<br />
                                        {props.transactionDetails?.receiver_name}<br />
                                        {props.transactionDetails?.Receiver_vpa}<br />
                                        {formattedTransactionDate}<br />
                                        Status: <span style={{ textTransform: "uppercase" }}>{props.transactionDetails?.Status}</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr className="information">
                        <td colSpan="2">
                            <table>
                                <tr>
                                    <td>
                                        {props.transactionDetails?.companyTradingName}<br />
                                        {props.transactionDetails?.address}<br />
                                        {props.transactionDetails?.country}<br />
                                    </td>
                                    <td>
                                        {props.transactionDetails?.sender_name}<br />
                                        {props.transactionDetails?.Sender_vpa}<br />
                                        {props.transactionDetails?.senderemail}<br />

                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr className="heading">
                        <td>Payment Source</td>
                        <td></td>
                    </tr>

                    <tr className="details">
                        <td>{props.transactionDetails?.Payment_type}</td>
                    </tr>

                    <tr className="heading">
                        <td>Transaction Details</td>
                        <td>Amount</td>
                    </tr>
                    {props.transactionDetails?.Amount && (
                        <tr className="total">
                            <td> Amount </td>
                            <td>{props.transactionDetails?.Amount}</td>
                        </tr>
                    )}
                    {props.transactionDetails?.DiscountAmount && (
                        <tr className="item">
                            <td> Discount Amount </td>
                            <td>{props.transactionDetails?.DiscountAmount}</td>
                        </tr>
                    )}
                    {props.transactionDetails?.DiscountAmount && (
                        <tr className="total">
                            <td> Offer Applied </td>
                            <td>{parseFloat(props.transactionDetails?.Amount - props.transactionDetails?.DiscountAmount)}</td>
                        </tr>
                    )}
                    {taxDetailsArray && taxDetailsArray?.length > 0 && (
                        taxDetailsArray?.map((item, index) => (
                            <tr className="item" key={index}>
                                <td>{item?.Particular}</td>
                                <td className="item-right-side">
                                    <div className="item-right-side-currency">{CURRENCY}</div>
                                    <div className="item-right-side-span"> {item?.NetTotal}</div>
                                </td>
                            </tr>
                        ))
                    )}

                    {props.transactionDetails?.Total_Tax && (
                        <tr className="total">
                            <td> Net Tax </td>
                            <td>{props.transactionDetails?.Total_Tax}</td>
                        </tr>
                    )}
                    <tr className="total">
                        <td> Net Total </td>
                        <td>{props.transactionDetails?.NetTotal}</td>
                    </tr>
                    {props.transactionDetails?.Refund_Status === "true" && (
                        <tr className="heading">
                            <td>Refund Details</td>
                            <td></td>
                        </tr>
                    )}
                    <tr className="item"> </tr>
                    {refundtaxDetailsArray && refundtaxDetailsArray?.length > 0 && (
                        refundtaxDetailsArray?.map((item, index) => (
                            <tr className="item" key={index}>
                                <td>{item?.Particular}</td>
                                <td className="item-right-side">
                                    <div className="item-right-side-currency">{CURRENCY}</div>
                                    <div className="item-right-side-span"> {item?.NetTotal}</div>
                                </td>
                            </tr>
                        ))
                    )}
                    {props.transactionDetails?.Refund_On_Tax_Total && (
                        <tr className="total">
                            <td> Net Tax </td>
                            <td>{props.transactionDetails?.Refund_On_Tax_Total}</td>
                        </tr>
                    )}
                    {props.transactionDetails?.Refund_On_NetTotal && (
                        <tr className="total">
                            <td> Net Total </td>
                            <td>{props.transactionDetails?.Refund_On_NetTotal}</td>
                        </tr>
                    )}

                    {props.transactionDetails?.Refund_Status === "true" && (
                        <tr className="heading">
                            <td>Refund Tax Details</td>
                            <td></td>
                        </tr>
                    )}
                    <tr className="item"> </tr>
                    {refundTaxtaxDetailsArray && refundTaxtaxDetailsArray?.length > 0 && (
                        refundTaxtaxDetailsArray?.map((item, index) => (
                            <tr className="item" key={index}>
                                <td>{item?.Particular}</td>
                                <td className="item-right-side">
                                    <div className="item-right-side-currency">{CURRENCY}</div>
                                    <div className="item-right-side-span"> {item?.NetTotal}</div>
                                </td>
                            </tr>
                        ))
                    )}
                    {props.transactionDetails?.R_Net_Tax && (
                        <tr className="total">
                            <td> Net Refund </td>
                            <td>{parseFloat(props.transactionDetails?.Refund_On_NetTotal - props.transactionDetails?.R_Net_Tax).toFixed(2)}</td>
                        </tr>
                    )}
                    {props.transactionDetails?.Final_Amount && (
                        <tr className="heading">
                            <td> Final Adjustment </td>
                            <td>{props.transactionDetails?.Final_Amount}</td>
                        </tr>
                    )}
                </table>
            </div>
        </div>
    );
});

TransactionPrint.displayName = "TransactionPrint";

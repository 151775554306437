
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  InputGroup,
} from "reactstrap";
import "./TransactionStyle.css";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import Breadcrumbs from "components/Common/Breadcrumb";
import { checkAuthentication, fetchUserProfile, getAllTransactionHistory } from "Context/ContextApi";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search, CSVExport
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import ReactToPrint from "react-to-print";
import { TransactionPrint } from "./TransactionPrint";

import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { saveAs } from "file-saver";
// import { useDownloadExcel } from "react-export-table-to-excel";
import pdfimg from "../../assets/images/NewImage/pdf.png";
import csvimg from "../../assets/images/NewImage/csv.png";
import excelimg from "../../assets/images/NewImage/xls.png"
import { useDownloadExcel } from "react-export-table-to-excel";

import { io } from "socket.io-client";
import { useData } from "Context/ContextProvider";
import arrowicon from "../../assets/images/NewImage/skip-track.png"
import { Link } from "react-router-dom/cjs/react-router-dom.min";
// import merchantlogo from "../../../public/vault_logo.png"

const CURRENCY = process.env.CURRENCY;
const API_BASE_URL = process.env.REACT_APP_API_URL
const Transaction = () => {
  const ref = useRef()
  const { userDataa } = useData();
  const [transactionData, setTransactionData] = useState([])
  const [status, setStatus] = useState("")
  const [payment_type, setPayment_type] = useState("")
  const [currency, setCurrency] = useState("ZiG")
  const [dateRange, setDateRange] = useState([]);
  const [popupData, setPopupData] = useState('')
  const [businesName, setBusinesName] = useState({})
  const [actionData, setActionData] = useState({})
  const generatedData = new Date().toLocaleString();

  const sortedProducts = [...transactionData];
  sortedProducts.sort((a, b) => a.Status.localeCompare(b.Status));


  const handleDateChange = (selectedDates) => {
    setDateRange(selectedDates);
  };
  const effact = "effact"

  useEffect(() => {
    fetchTheMerchantTransacrionHistory(status, payment_type)
  }, [effact])

  useEffect(() => {
    if(userDataa.id && userDataa.id > 0){
    const socket = io(`${API_BASE_URL}`);
    const userId = userDataa.id;
    socket.emit('userConnected', userId);
    socket.on('vaultTransaction', (data) => {
      console.log(data, "----------------data");
      setTransactionData(prev => [...data, ...prev]);
    });
    return () => {
      socket.disconnect();
    };
  }
  }, [userDataa.id]);


  const fetchTheMerchantTransacrionHistory = async (Status, PaymentType) => {
    try {
      const formData = {
        currency: CURRENCY,
        payment_type: PaymentType,
        status: Status
      }
      const response = await getAllTransactionHistory(formData)
      setTransactionData(response.result)
      setBusinesName(response.businessName)
    } catch (error) {
      console.log(error);
    }
  }

  const handleReset = () => {
    setCurrency("")
    setPayment_type("")
    setStatus("")
    fetchTheMerchantTransacrionHistory(status, payment_type)
  }
  const columns = [
    {
      dataField: "Transaction_date",
      text: "Date",
      sort: true,
      formatter: (cell, row) => {
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false,
        };
        return new Date(cell).toLocaleString("en-US", options);
      },
    },
    {
      dataField: "UTR",
      text: "UTR",
      sort: true,
    },
    {
      dataField: "sender_name",
      text: "Payer",
      sort: true,
    },
    {
      dataField: "Sender_vpa",
      text: "Payer VPA",
      sort: true
    },
    {
      dataField: "NetPaid",
      text: " Net Paid",
      sort: true,
    },
    {
      dataField: "Payment_type",
      text: "Payment Type",
      sort: true,
    },
    {
      dataField: "Status",
      text: "Status",
      sort: true,
      formatter: (cell, row) => (
        <span
          style={{
            color: row.Status === "completed" ? "green" : "red",
            fontFamily: "Gilroy",
            fontWeight: "600",
            fontSize: "18px",
            textTransform: "uppercase"
          }}
        >
          {cell}
        </span>
      ),
    },
    {
      dataField: "",
      text: "Details",
      formatter: (cell, row) => (
        <div>
          <Link to={`/payment-details/${row?.id}`}>
          <p
            className="Details_popup"
            // onClick={() => togglePopup(row)}
          >
            <img src={arrowicon} alt="icon"/>
          </p>
          </Link>
        </div>
      ),
    }
  ];
  const columns1 = [
    {
      dataField: "Transaction_date",
      text: "Date",
      formatter: (cell, row) => {
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false,
        };
        return new Date(cell).toLocaleString("en-US", options);
      },
    },
    {
      dataField: "sender_name",
      text: "Sender",
    },
    {
      dataField: "Sender_vpa",
      text: "Sender VPA",
    },
    {
      dataField: "receiver_name",
      text: "Receiver",
    },
    {
      dataField: "Receiver_vpa",
      text: "Receiver VPA",
    },
    {
      dataField: "UTR",
      text: "UTR",
    },
    {
      dataField: "Amount",
      text: "Amount",
    },
   
    {
      dataField: "Tax",
      text: "Tax",
    },
    {
      dataField: "NetTotal",
      text: "Net Total",
    },
    {
      dataField: "Payment_type",
      text: "Payment Type",
    },
      {
      dataField: "Status",
      text: "Status",
    },
  ];


  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: transactionData.length,
    custom: true,
  };

  const selectRow = {
    mode: "checkbox",
  };

  const { SearchBar } = Search;
  const componentRef = useRef();
  // exporting data 
  const { ExportCSVButton, } = CSVExport;
  // excel and pdf 
  const tableRef = useRef();
  const handleDownloadPDF = () => {
    const pageWidth = 1000;
    const doc1 = new jsPDF({

    });
    const pageheight_1 = doc1.internal.pageSize.getHeight();
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'px',
      size: pageWidth,
      format: [pageWidth, pageheight_1]
    });

    const pagewidth1 = doc.internal.pageSize.getWidth();
    const pageheight1 = doc.internal.pageSize.getHeight();

    const imageUrl1 = `${API_BASE_URL}/vault_logo.png`;
    const merchantLogo = `${API_BASE_URL}/${businesName.MerchantLogo}`;

    var fontSize = 12;
    doc.setTextColor(0, 0, 51);
    doc.setFillColor(5, 5, 16);
    try {
      doc.addImage(merchantLogo, 'PNG', 30, 30, 77.76, 24.63)
    } catch (error) {
      doc.addImage(merchantLogo, 'PNG', 30, 30, 77.76, 24.63)
      console.error('Error adding Image:', error);

    }
    doc.addImage(imageUrl1, 'PNG', (pagewidth1 - 130), 30, 40, 40)
    doc.text(30, 80, `MERCHANT  : ${businesName.BusinessName} |  GENERATED AT  : ${generatedData}  |  REPORT FOR DEPOSIT TRANSACTIONS`, { textAlign: 'justify' });
    const titletextColor = '#000000';
    doc.autoTable({ startY: 90, html: "#my-table2", styles: { color: titletextColor, halign: 'center' }, headStyles: { fillColor: [5, 5, 16] }, alternateRowStyles: { fillColor: [201, 253, 240] }, tableLineColor: [0, 0, 51], tableLineWidth: 0.1, }); //use headStyles to bring styles to the table head, and alternateRowStyles to color the rows but one yes and one no
    doc.setFontSize(fontSize);

    const fileName = `REPORT_FOR_TRANSACTIONS_${new Date().toISOString()}.pdf`;
    doc.save(fileName);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: document.getElementById("my-table"),
    filename: `merchant_transactions_${new Date()}`,
    sheet: 'Users'
  })

  // let effact = "effect"
  useEffect(() => {
    fetchData();
  }, [effact]);
  const fetchData = async () => {
    try {
      const response = await fetchUserProfile();
      let jsonparse = response?.result?.subResult ? JSON.parse(response?.result?.subResult?.Permissions) : null;
      setActionData(jsonparse)
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content"
        style={{ marginLeft: "5%", marginRight: "5%" }}>
        <Breadcrumbs title="GET" breadcrumbItem="Transaction" />
        <Row>
          <Col
            md={{
              offset: 3,
              size: 6,
            }}
          >
            <h2
              className="text-center mt-5 mb-4 "
              style={{ fontFamily: "Gilroy", color: "#db9c45" }}
            >
              TRANSACTIONS
            </h2>
            <p className="text-center heading_description mb-5">
              History of all transactions in your account
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
              className="mb-4"
            >
              <div style={{ width: "80%", display: "flex", gap: "10px", flexWrap:"wrap" }}>
                <div className="filtter_Date_range">
                <InputGroup >
                  <Flatpickr
                    className="date_picker"
                    placeholder="Select date range"
                    options={{
                      mode: "range",
                      dateFormat: "Y-m-d",
                    }}
                    onChange={handleDateChange}
                  />
                </InputGroup>
                </div>
                <select className="filtter_select_box" name="status" value={status} 
                onChange={(e) => {
                  const selectedStatus = e.target.value;
                  setStatus(selectedStatus);
                  fetchTheMerchantTransacrionHistory(selectedStatus, payment_type);
                }}
                >
                  <option defaultValue hidden>
                    Status
                  </option>
                  <option value="">All</option>
                  <option value="completed">Successfull</option>
                  <option value="pending"> Pending</option>
                  <option value="Rejected"> Rejected</option>
                  <option value="failed">Failed</option>
                </select>
                <select className="filtter_select_box" name="payment_type" value={payment_type} onChange={(e) => {
                  const selectedPaymentType = e.target.value;
                  setPayment_type(selectedPaymentType);
                  fetchTheMerchantTransacrionHistory(status, selectedPaymentType);
                }}>
                  <option defaultValue hidden>
                    Type
                  </option>
                  <option value="">All</option>
                  <option value="personal transfer">Personal </option>
                  <option value="merchant transfer"> Merchant </option>
                  <option value="topup">Topup</option>
                  <option value="GetMoney">Request</option>
                  <option value="bankwithdraw">Withdraw</option>
                </select>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  gap: "20px",
                }}
              >
                <span style={{ fontSize: "16px", fontFamily: "Gilroy" }} onClick={handleReset}>
                  Reset
                </span>
                <Button
                  type="submit"
                  style={{ fontSize: "16px", fontFamily: "Gilroy" }}
                  onClick={fetchTheMerchantTransacrionHistory}
                  className="procces_Button"
                >
                  Apply filter
                </Button>


              </div>
            </div>
            <Card className="mt-5">
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={columns}
                  // data={products}
                  data={transactionData}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={transactionData}
                      search
                      exportCSV
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col md="7"></Col>
                            <Col md="1">
                            {actionData === null || (actionData?.Transaction?.action=== true) ? (
                              <div className="Image_for_export_excel_pdf">
                                <img src={pdfimg} alt="image" onClick={handleDownloadPDF} className="me-2" />
                                <img src={excelimg} alt="image" onClick={onDownload} />
                                <ExportCSVButton {...toolkitProps.csvProps} className="export_csv_button"><img src={csvimg} alt="image" /></ExportCSVButton>
                              </div>
                            ):null}
                            </Col>
                          </Row>

                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable

                                  // id="my-table"
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  //   selectRow={selectRow}
                                  classes={
                                    "table align-middle table-nowrap"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  style={{
                                    fontWeight: "Gilroy",
                                  }}
                                  headerClasses={"custom-header-class"}
                                  rowStyle={{
                                    fontSize: "15px",
                                    fontFamily: "Gilroy",
                                    fontWeight: "400",
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  {...paginationProps}
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>

                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <ReactToPrint content={() => componentRef.current} />
      <div style={{ display: "none" }}>
        <TransactionPrint ref={componentRef} transactionDetails={popupData} />
      </div>
      <div style={{ display: "none" }} >
        <table id="my-table" ref={tableRef} >
          <thead>
            <tr style={{ backgroundColor: "black", color: "#6ce9eb" }}>
              <th style={{ fontSize: "14px", color: "white" }}>MERCHANT  :{businesName.BusinessName}  GENERATED AT  : {generatedData} | REPORT FOR TRANSACTIONS </th>
            </tr>
            <tr
              style={{ backgroundColor: "#6ce9eb", color: "black", fontSize: "12px" }}
            >
              <th >Date</th>
              <th  >Sender</th>
              <th>Sender VPA </th>
              <th>Receiver</th>
              <th>Receiver VPA</th>
              <th>UTR</th>
              <th>Amount</th>
              <th>Tax</th>
              <th>Net Total</th>
              <th>Payment Type</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {transactionData.map((item, index) => (
              <tr key={index}>
                <td>{item.Transaction_date}</td>
                <td>{item.sender_name}</td>
                <td>{item.Sender_vpa}</td>
                <td>{item.receiver_name}</td>
                <td>{item.Receiver_vpa}</td>
                <td>{item.UTR}</td>
                <td>{item.Amount}</td>
                <td>{item.Tax}</td>
                <td>{item.NetTotal}</td>
                <td>{item.Payment_type}</td>
                <td>{item.Status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* tableDemo  */}
      <div style={{ display: "none" }} >
        <ToolkitProvider keyField="id" columns={columns1} data={transactionData}>
          {toolkitProps => (
            <React.Fragment>
              <Row>
                <Col xl="12">
                  <div className="table-responsive">
                    <BootstrapTable
                      id="my-table2"
                      {...toolkitProps.baseProps}
                      responsive
                      bordered={false}
                      striped={false}
                      defaultSorted={defaultSorted}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"thead-light"}
                      style={{
                        fontWeight: "Gilroy",
                      }}
                      headerClasses={"custom-header-class"}
                      rowStyle={{
                        fontSize: "15px",
                        fontFamily: "Gilroy",
                        fontWeight: "400",
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </ToolkitProvider>
      </div>

    </React.Fragment>
  );
};




export default Transaction;

import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  Modal,
} from "reactstrap";
import cancelImg from "../../assets/images/NewImage/x-button.png";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search, CSVExport
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { fetchUserProfile, fetchmerchantPayment } from "Context/ContextApi";
import ReactToPrint from "react-to-print";
import { TransactionPrint } from "pages/Transactions/TransactionPrint";

import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { saveAs } from "file-saver";
// import { useDownloadExcel } from "react-export-table-to-excel";
import pdfimg from "../../assets/images/NewImage/pdf.png";
import csvimg from "../../assets/images/NewImage/csv.png";
import excelimg from "../../assets/images/NewImage/xls.png"
import { useDownloadExcel } from "react-export-table-to-excel";
import { useData } from "Context/ContextProvider";
import { io } from "socket.io-client";
import RefundModal from "./RefundModal";
import IconDripicons from "pages/Icons/IconDripicons";
import arrowicon from "../../assets/images/NewImage/skip-track.png"
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const API_BASE_URL = process.env.REACT_APP_API_URL
const Payments = () => {
  const { userDataa } = useData()
  const generatedData = new Date().toLocaleString();
  const [products, setProducts] = useState([])
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupData, setPopupData] = useState('')
  const [selectedStatus, setselectedStatus] = useState('')
  const [status, setStatus] = useState('')
  const [businesName, setBusinesName] = useState({})
  const [actionData, setActionData] = useState({})
  const statusOptions = [
    { value: '', label: 'All' },
    { value: 'completed', label: 'Successfull' },
    { value: 'pending', label: 'Pending' },
    { value: 'Rejected', label: 'Rejected' },
    { value: 'failed', label: 'Failed' },
  ];
  const sortedProducts = [...products];
  sortedProducts.sort((a, b) => a.Status.localeCompare(b.Status));

  const togglePopup = (row) => {
    setPopupData(row)
    setPopupOpen((prevPopupOpen) => !prevPopupOpen);
  };

  useEffect(() => {
    handleFetchPayment(status);
  }, [])
  useEffect(() => {
    const socket = io(`${API_BASE_URL}`);
    const userId = userDataa.id;
    socket.emit('userConnected', userId);
    socket.on('vaultTransaction', (data) => {
      setProducts(prev => [...data, ...prev]);
    });
    return () => {
      socket.disconnect();
    };
  }, [userDataa.id]);
  const handleFetchPayment = async (Status) => {
    try {
      const formData = {
        status: Status,
      }
      const response = await fetchmerchantPayment(formData)
        console.log(response, "response");
      setProducts(response.result)
      setBusinesName(response.userInfo)
    } catch (error) {
      console.log(error);
    }
  }
  const columns = [
    {
      dataField: "Transaction_date",
      text: "Date",
      sort: true,
      formatter: (cell, row) => {
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false,
        };
        return new Date(cell).toLocaleString("en-US", options);
      },
    },
    {
      dataField: "UTR",
      text: "UTR",
      sort: true,
    },
    {
      dataField: "sender_name",
      text: "Payer",
      sort: true,
    },
    {
      dataField: "Sender_vpa",
      text: "Payer VPA",
      sort: true
    },
    {
      dataField: "NetPaid",
      text: "Net Paid",
      sort: true,
    },
    {
      dataField: "Status",
      text: "Status",
      sort: true,
      formatter: (cell, row) => (
        <span
          style={{
            color: row.Status === "completed" ? "green" : "red",
            fontFamily: "Gilroy",
            fontWeight: "600",
            fontSize: "18px",
            textTransform: "uppercase"
          }}
        >
          {cell}
        </span>
      ),
    },
    {
      dataField: "details",
      text: "Details",
      sort: false,
      formatter: (cell, row) => (
        <div>
          <Link to={`/payment-details/${row?.id}`}>
          <p
            className="Details_popup"
            // onClick={() => togglePopup(row)}
          >
            <img src={arrowicon} alt="icon"/>
          </p>
          </Link>
        </div>
      ),
    },
  ];
  const columns1 = [
    {
      dataField: "Transaction_date",
      text: "Date",
      formatter: (cell, row) => {
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false,
        };
        return new Date(cell).toLocaleString("en-US", options);
      },
    },
    {
      dataField: "sender_name",
      text: "Sender",
    },
    {
      dataField: "Sender_vpa",
      text: "Sender VPA",
    },
    {
      dataField: "receiver_name",
      text: "Receiver",
    },
    {
      dataField: "Receiver_vpa",
      text: "Receiver VPA",
    },
    {
      dataField: "UTR",
      text: "UTR",
    },
    {
      dataField: "Amount",
      text: "Amount",
    },
   
    {
      dataField: "Tax",
      text: "Tax",
    },
    {
      dataField: "NetTotal",
      text: "Net Total",
    },
    {
      dataField: "Payment_type",
      text: "Payment Type",
    },
  
    {
      dataField: "Status",
      text: "Status",
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: products.length,
    custom: true,
  };

  const { SearchBar } = Search;

  const componentRef = useRef();

  // exporting data 
  const { ExportCSVButton, } = CSVExport;
  // excel and pdf 
  const tableRef = useRef();

  const handleDownloadPDF = () => {
    const pageWidth = 1000;
    const doc1 = new jsPDF({

    });
    const pageheight_1 = doc1.internal.pageSize.getHeight();
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'px',
      size: pageWidth,
      format: [pageWidth, pageheight_1]
    });

    const pagewidth1 = doc.internal.pageSize.getWidth();
    const pageheight1 = doc.internal.pageSize.getHeight();

    const imageUrl1 = `${API_BASE_URL}/vault_logo.png`;
    const merchantLogo = `${API_BASE_URL}/${businesName.MerchantLogo}`;
    var offsetY = 4.797777777777778;
    var lineHeight = 6.49111111111111;
    var fontSize = 12;
    doc.setTextColor(0, 0, 51);
    doc.setFillColor(5, 5, 16);
    try {
      doc.addImage(merchantLogo, 'PNG', 30, 30, 100, 30)
    } catch (error) {
      console.error('Error adding Image:', error);
    }
    doc.addImage(imageUrl1, 'PNG', (pagewidth1 - 112), 30, 40, 40)

    doc.text(30, 100, `MERCHANT  : ${businesName.BusinessName} |  GENERATED AT  : ${generatedData}  |  REPORT FOR TRANSACTIONS`);
    const titletextColor = '#000000';
    doc.autoTable({ startY: 110, html: "#my-table2", styles: { color: titletextColor, halign: 'center' }, headStyles: { fillColor: [5, 5, 16] }, alternateRowStyles: { fillColor: [201, 253, 240] }, tableLineColor: [0, 0, 51], tableLineWidth: 0.1, }); //use headStyles to bring styles to the table head, and alternateRowStyles to color the rows but one yes and one no
    doc.setFontSize(fontSize);

    const fileName = `REPORT_FOR_TRANSACTIONS_${new Date().toISOString()}.pdf`;
    doc.save(fileName);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: document.getElementById("my-table"),
    filename: `Merchant_Payments_${new Date()}`,
    sheet: 'Users'
  })
  const handleReset = () => {
    setStatus("")
    handleFetchPayment(status)
  }
let effact =  "effact";
  useEffect(() => {
    fetchData();
  }, [effact]);
  const fetchData = async () => {
    try {
      const response = await fetchUserProfile();
      let jsonparse = response?.result?.subResult ? JSON.parse(response?.result?.subResult?.Permissions) : null;
      setActionData(jsonparse)
    } catch (error) {
      console.log(error);
    }
  };
  // Meta title
  document.title = "GET Platform  | Payments";

  return (
    <React.Fragment>
      <RefundModal isPopupOpen={isPopupOpen} popupData={popupData} onClose={togglePopup} />
      <div
        className="page-content"
        style={{ marginLeft: "5%", marginRight: "5%" }}
      >
        <div className="container-fluid">
          <Breadcrumbs title="GET" breadcrumbItem="Payments" />
          <Row>
            <Col
              md={{
                offset: 3,
                size: 6,
              }}
            >
              <h1 className="text-center all_newPaeHeading">PAYMENTS</h1>
              <h5 className="text-center all_sub_new_heading">
                List of all payments you received from customers
              </h5>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div style={{ width: "80%", display: "flex", gap: "10px", flexWrap:"wrap"  }}>
                <div className="filtter_Date_range">
                  <InputGroup >
                    <Flatpickr
                      className="date_picker"
                      placeholder="Select date range"
                      options={{
                        mode: "range",
                        dateFormat: "Y-m-d",
                      }}
                      style={{
                        borderRadius: "7px",
                        border: "1px solid black",
                        width: "100%",
                        fontFamily: "Gilroy",
                        color: "black",
                      }}
                    />
                  </InputGroup>
                  </div>
                  <select className="filtter_select_box" name="status" value={status}
                   onChange={(e) => {
                    const selectedStatus = e.target.value;
                    setStatus(selectedStatus);
                    handleFetchPayment(selectedStatus);
                  }}
                  >
                    <option defaultValue hidden>Status</option>
                    {statusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "20px",
                  }}
                >
                  <Button
                    type="submit"
                    className="procces_Button"
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                  <Button
                    type="submit"
                    className="procces_Button"
                    onClick={handleFetchPayment}
                  >
                    Apply filter
                  </Button>
                </div>
              </div>
              <Card className="mt-5">
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    // data={products}
                    data={sortedProducts}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={products}
                        search
                        exportCSV
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col md="7"></Col>
                              <Col md="1">
                              {actionData === null || (actionData?.Payments?.action=== true) ? (
                                <div className="Image_for_export_excel_pdf">
                                  <img src={pdfimg} alt="image" onClick={handleDownloadPDF} className="me-2" />
                                  <img src={excelimg} alt="image" onClick={onDownload} />
                                  <ExportCSVButton {...toolkitProps.csvProps} className="export_csv_button"><img src={csvimg} alt="image" /></ExportCSVButton>
                                </div>
                              ):null}
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    // ref={tableRef}
                                    // id="my-table"
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    //   selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    style={{
                                      fontWeight: "Gilroy",
                                    }}
                                    headerClasses={"custom-header-class"}
                                    rowStyle={{
                                      fontSize: "15px",
                                      fontFamily: "Gilroy",
                                      fontWeight: "400",
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ReactToPrint content={() => componentRef.current} />

      <div style={{ display: "none" }}>
        {/* <TransactionPrint ref={componentRef} transactionDetails={popupData} /> */}
      </div>
      <div style={{ display: "none" }} >
        <table id="my-table" ref={tableRef} >
          <thead>
            <tr style={{ backgroundColor: "black", display: "flex", flexWrap: "nowrap", textWrap: "nowrap" }}>
              <th style={{ fontSize: "14px", color: "white", textWrap: "nowrap" }}>MERCHANT  :{businesName.BusinessName}  GENERATED AT  : {generatedData} | REPORT FOR TRANSACTIONS </th>
            </tr>
            <tr
              style={{ backgroundColor: "#6ce9eb", color: "black", fontSize: "12px" }}
            >
              <th >Date</th>
              <th  >Sender</th>
              <th>Sender VPA </th>
              <th>Receiver</th>
              <th>Receiver VPA</th>
              <th>UTR</th>
              <th>Amount</th>
              <th>Tax</th>
              <th>Net Total</th>
              <th>Payment Type</th>
              <th>source</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {products.map((item, index) => (
              <tr key={index}>
                <td>{item.Transaction_date}</td>
                <td>{item.sender_name}</td>
                <td>{item.Sender_vpa}</td>
                <td>{item.receiver_name}</td>
                <td>{item.Receiver_vpa}</td>
                <td>{item.UTR}</td>
                <td>{item.Amount}</td>
                <td>{item.Tax}</td>
                <td>{item.NetTotal}</td>
                <td>{item.Payment_type}</td>
                <td>{item.Source}</td>
                <td>{item.Status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* tableDemo  */}
      <div style={{ display: "none" }} >
        <ToolkitProvider keyField="id" columns={columns1} data={products}>
          {toolkitProps => (
            <React.Fragment>
              <Row>
                <Col xl="12">
                  <div className="table-responsive">
                    <BootstrapTable
                      id="my-table2"
                      {...toolkitProps.baseProps}
                      responsive
                      bordered={false}
                      striped={false}
                      defaultSorted={defaultSorted}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"thead-light"}
                      style={{
                        fontWeight: "Gilroy",
                      }}
                      headerClasses={"custom-header-class"}
                      rowStyle={{
                        fontSize: "15px",
                        fontFamily: "Gilroy",
                        fontWeight: "400",
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </ToolkitProvider>
      </div>
    </React.Fragment>
  );
};

export default Payments;

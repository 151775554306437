import React, { useEffect, useState } from "react";
import { Button, Col, Container, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import { fetchCheckmerchantSecreatePIn, gMerchantPolicy, gNoOfTransaction, gTotalInsights, gTotalTranscationPerDay, generateotpForTheMerchantSecreatPin, getDashboardWidget, setAndUpdateThesecreatePin } from "Context/ContextApi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

function PinModal() {

  const [isButtonVisible, setButtonVisibility] = useState(true)
  const [secondsRemaining, setSecondsRemaining] = useState(60);
  const [isAccepted, setAccepted] = useState(false);
  const [isOtpModalOpen, setOtpModalOpen] = useState(false);
  const [checkSecreatePinData, setCheckSecreatePinData] = useState("")
  const [responseForpin, setResponseForpin] = useState("")
  const [data, setData] = useState({})
  const [formDatas, setFormDatas] = useState({
    otp: "669258",
    pin: "",
    confirmPin: ""
  })
  const [validationErrors, setValidationErrors] = useState({
    otp: "669258",
    pin: "",
    confirmPin: ""
  });

  const effact = "effact";
  useEffect(() => { fData() }, [effact])

  const fData = async () => {
      try {
          const formData = {
              id: "a79f5844-cbd3-418c-815b-36ea472425e2"
          }
          const response = await gMerchantPolicy(formData)
          setData(response?.result)
      } catch (error) {
          console.log(error);
      }
  }

  const handleChanges = (e) => {
    setFormDatas({
      ...formDatas,
      [e.target.name]: e.target.value
    });
  };


  useEffect(() => {
    fetchCheckScreatePinOfMerchant();
  }, [])

  const fetchCheckScreatePinOfMerchant = async () => {
    try {
      const response = await fetchCheckmerchantSecreatePIn()
      console.log(response.result.hasSecretPin);
      setResponseForpin(response.result)
      setCheckSecreatePinData(response.result);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    if (responseForpin.hasSecretPin == "true") {
      setAccepted(false);
    } else if (responseForpin.hasSecretPin == 'false') {
      setAccepted(true);
    }
  }, [responseForpin]);


  const handleGenerateOTP = async () => {
    try {
      const response = await generateotpForTheMerchantSecreatPin()
      toastr.warning(response.message)
      // setButtonVisibility(false);
      setSecondsRemaining(60);
      startCountdown();
    } catch (error) {
      console.log(error);
    }
  };

  const startCountdown = () => {
    const countdownInterval = setInterval(() => {
      setSecondsRemaining((prevSeconds) => prevSeconds - 1);
    }, 1000);

    setTimeout(() => {
      setButtonVisibility(true);
      clearInterval(countdownInterval);
    }, 60000);
  };

  useEffect(() => {
    return () => {
      clearInterval(startCountdown);
    };
  }, []);

  const handleAccept = () => {
    setOtpModalOpen(true);
    setAccepted(false)
  };

  const handleGeneratePin = async () => {
    // if (formDatas.otp.length !== 6 || !/^\d+$/.test(formDatas.otp)) {
    //   setValidationErrors({
    //     ...validationErrors,
    //     otp: 'OTP must be a 6-digit number.'
    //   });
    //   return;
    // }

    if (formDatas.pin.length < 4) {
      setValidationErrors({
        ...validationErrors,
        pin: 'PIN must be at least 4 characters.'
      });
      return;
    }

    if (formDatas.confirmPin !== formDatas.pin) {
      setValidationErrors({
        ...validationErrors,
        confirmPin: 'PIN and Confirm PIN must match.'
      });
      return;
    }
    try {
      const formData = {
        // otp: "669258",
        pin: formDatas.pin
      };
      const response = await setAndUpdateThesecreatePin(formData);
      toastr.success(response.message)
      setValidationErrors({
        otp: "669258",
        pin: "",
        confirmPin: ""
      });
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };
  const closeModal = () => {
    setOtpModalOpen(false);
  };

 
  return (
    <>
      <React.Fragment>
        <Modal
          size="xl"
          isOpen={isAccepted}
          role="dialog"
          autoFocus={true}
          data-toggle="modal"
          centered
        >
          <div className="modal-conter">
            <div className="modal-body">
              <div className="text-center mb-4">
                <div className="row justify-content-center">
                  <div className="col-xl-12">
                    <h4 className="text-primary">Terms & Conditions</h4>
                    <div dangerouslySetInnerHTML={{ __html: data?.Description }} />
                    <Button className="procces_Button" type="button" id="button-addon2" onClick={handleAccept}>
                      Accpect
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={isOtpModalOpen}
          role="dialog"
          autoFocus={true}
          centered
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-center mb-4">
                <div className="row justify-content-center">
                  <div className="col-xl-8">
                    <h4 className="text-primary">Generate PIN</h4>
                    <FormGroup>
                      <Label for="pin" className="generate_pin_label">PIN:</Label>
                      <Input type="number" name="pin" id="pin" placeholder="New Pin" className="css_for_generatePin_input" value={formDatas.pin} onChange={handleChanges} />

                      {validationErrors.pin && (
                        <small className="text-danger">{validationErrors.pin}</small>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="confirmPin" className="generate_pin_label">Confirm PIN:</Label>
                      <Input type="number" name="confirmPin" id="confirmPin" placeholder="Confirm Pin" className="css_for_generatePin_input" value={formDatas.confirmPin} onChange={handleChanges}
                      />
                      {validationErrors.confirmPin && (
                        <small className="text-danger">{validationErrors.confirmPin}</small>
                      )}
                    </FormGroup>
                    {/* <FormGroup>
                      <Label for="OTP" className="generate_pin_label">
                        OTP
                      </Label>
                      <div className="Otp_genearate_button">
                        <Input
                          type="password"
                          name="otp"
                          id="otp"
                          placeholder="Enter OTP"
                          className="css_for_generatePin_input"
                          value={formDatas.otp}
                          onChange={handleChanges}
                        />
                        {isButtonVisible ? (
                          <Button onClick={handleGenerateOTP}>OTP generate</Button>
                        ) : null}

                      </div>  {validationErrors.otp && (
                        <small className="text-danger">{validationErrors.otp}</small>
                      )}
                      <small className="generate_pin_timer">{secondsRemaining > 0 ? `${secondsRemaining} sec` : ''}</small>
                    </FormGroup> */}
                    <Button className="procces_Button" type="button" onClick={handleGeneratePin}>
                      Confirm
                    </Button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    </>
  );
}
export default PinModal;

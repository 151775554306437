import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import deletes from "../../assets/images/NewImage/supprimer.png";
import cancels from "../../assets/images/NewImage/cancel.png";
import ticket from "../../assets/images/NewImage/bank.png";
import "./withdrawalsStyle.css";
import { addbankAccount, deleteMerchantbankDetails, getMerchantBankDetailsapi, getMerchantBank_names } from "Context/ContextApi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useData } from "Context/ContextProvider";


const WithdrawalsSetting = () => {
  const {iPAddress} = useData();
  const [ispopupOpenForSetting, setIsPopupOpenForSetting] = useState(false);
  const [bankDetails, setBankDetails] = useState()
  const [selectedItem, setSelectedItem] = useState(null);
  const [merchantBankNames, setMerchantBankNames] = useState([])
  const [bankNameID, setBankNameID] = useState(null)

  const toggleOpenPopup = () => {
    setIsPopupOpenForSetting((prev) => !prev);
  };
  const [sendForm, setSendForm] = useState({
    accountHolderName: "",
    bankName: "",
    accountNumber: "",
    branchName: "",
    swift: "",
    branch_code: "",
    branch_address: "",
    city: "",
    country: "",
  })
  const handleChange = (e) => {
    setSendForm({ ...sendForm, [e.target.name]: e.target.value })
  }
  // get merchant bank details
  useEffect(() => {
    fetchaBankDetails()
  }, [])
  const fetchaBankDetails = async () => {
    try {
      const response = await getMerchantBankDetailsapi()
      setBankDetails(response.result)
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmitTheBank = async () => {
    try {
      const selectedItemBankFromAllBank = merchantBankNames.find((item) => item.Bank === sendForm.bankName);
      if (selectedItem) {
        const formData = {
          bankId: selectedItem.id,
          accountHolderName: sendForm.accountHolderName,
          bankName: sendForm.bankName,
          accountNumber: sendForm.accountNumber,
          branchName: sendForm.branchName,
          swift: sendForm.swift,
          branch_code: sendForm.branch_code,
          branch_address: sendForm.branch_address,
          city: sendForm.city,
          country: sendForm.country,
          bankNameId: selectedItemBankFromAllBank.id,
          IPAddress:iPAddress,
        };
        const response = await addbankAccount(formData)
        toastr.success(response.message)
      } else {
        const formData = {
          accountHolderName: sendForm.accountHolderName,
          bankName: sendForm.bankName,
          accountNumber: sendForm.accountNumber,
          branchName: sendForm.bankName,
          swift: sendForm.swift,
          branch_code: sendForm.branch_code,
          branch_address: sendForm.branch_address,
          city: sendForm.city,
          country: sendForm.country,
          bankNameId: selectedItemBankFromAllBank.id,
          IPAddress:iPAddress,
        }
        const response = await addbankAccount(formData)
        toastr.success(response.message)
      }
      setSendForm({
        accountHolderName: "",
        bankName: "",
        accountNumber: "",
        branchName: "",
        swift: "",
        branch_code: "",
        branch_address: "",
        city: "",
        country: "",
      });
      setSelectedItem(null);
      toggleOpenPopup();
      fetchaBankDetails();
    } catch (error) {
      console.log(error);
    }
  }


  //handl edit
  const handleEdit = (id) => {
    const selectedItem = bankDetails.find((item) => item.id === id);
  
    if (selectedItem) {
      setSendForm({
        accountHolderName: selectedItem.Beneficiary,
        bankName: selectedItem.BankName,
        accountNumber: selectedItem.AccNumber,
        branchName: selectedItem.BranchName,
        swift: selectedItem.Swift,
        branch_code: selectedItem.BranchCode,
        branch_address: selectedItem.BranchAddress,
        city: selectedItem.City,
        country: selectedItem.Country,
        bankId: selectedItem.id
      });
      setSelectedItem(selectedItem);
      toggleOpenPopup();
    }
  }


  const handleDelete = async (id) => {
    const formData = {
      bankId: id
    }
    try {
      const response = await deleteMerchantbankDetails(formData)
      toastr.warning(response.message)
      fetchaBankDetails();
    } catch (error) {
      console.log(error.message);
      toastr.error(error.message)
    }
  }
  useEffect(() => {
    handlefetchBankNames()
  }, [])
  const handlefetchBankNames = async () => {
    try {
      const response = await getMerchantBank_names()
      setMerchantBankNames(response.result)
    } catch (error) {
      console.log(error);
    }
  }
  const handlebankNameId = (selectedBankId) => {
    setBankNameID(selectedBankId.id)
  }

  return (
    <>

      {ispopupOpenForSetting && (
        <div className="withdrawals_popup">
          <div className="withdrawals_popup_content">
            <div className="widthdrawals_heading">
              <h3>Update Withdrawal Setting</h3>
              <img
                src={cancels}
                alt="cancel"
                onClick={toggleOpenPopup}
              />
            </div>
            <hr />
            <div className="from_group_main">
              {/* <div className="form_group_for_withdrwalas_setting_popup">
                  <label>Withdrawal Type</label>
                  <select>
                    <option selected hidden>
                      Type
                    </option>
                    <option>Bank</option>
                  </select>
                </div> */}
              <div className="form_group_for_withdrwalas_setting_popup">
                <label>Account Holders Name</label>
                <input type="text" placeholder="Account holders name" name="accountHolderName" value={sendForm.accountHolderName} onChange={handleChange} />
              </div>
              <Row>
                <Col md="6" sm="6" xs="12">
                  <div className="form_group_for_withdrwalas_setting_popup">
                    <label>Account Number/IBAN</label>
                    <input type="text" placeholder="Account number/IBAN" name="accountNumber" value={sendForm.accountNumber} onChange={handleChange} />
                  </div>
                </Col>
                <Col md="6" sm="6" xs="12">
                  <div className="form_group_for_withdrwalas_setting_popup">
                    <label>Bank Name</label>
                    <select name="bankName" value={sendForm.bankName} onChange={handleChange} >
                      <option selected hidden>Bank Name</option>
                      {merchantBankNames.map((item, index) => (
                        <option key={index} value={item.Bank} onChange={(item) => handlebankNameId(item)}>{item.Bank}</option>
                      ))}
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6" sm="6" xs="12">
                  <div className="form_group_for_withdrwalas_setting_popup">
                    <label>Branch Name</label>
                    <input type="text" placeholder="Branch name" name="branchName" value={sendForm.branchName} onChange={handleChange} />

                  </div>
                </Col>
                <Col md="6" sm="6" xs="12">
                  <div className="form_group_for_withdrwalas_setting_popup">
                    <label>Branch City</label>
                    <input type="text" placeholder="Branch City" name="city" value={sendForm.city} onChange={handleChange} />
                  </div>
                </Col>
              </Row>
              <div className="form_group_for_withdrwalas_setting_popup">
                <label>Branch Address</label>
                <input type="text" placeholder="Branch address" name="branch_address" value={sendForm.branch_address} onChange={handleChange} />
              </div>
              <div className="form_group_for_withdrwalas_setting_popup">
                <label>Branch Code</label>
                <input type="text" placeholder="Branch code" name="branch_code" value={sendForm.branch_code} onChange={handleChange} />
              </div>
              <Row>
                <Col md="6" sm="6" xs="12">
                  <div className="form_group_for_withdrwalas_setting_popup">
                    <label>SWIFT Code</label>
                    <input type="text" placeholder="Swift code" name="swift" value={sendForm.swift} onChange={handleChange} />
                  </div>
                </Col>
                <Col md="6" sm="6" xs="12">
                  <div className="form_group_for_withdrwalas_setting_popup">
                    <label>Country</label>
                    <select name="country" value={sendForm.country} onChange={handleChange}>
                      <option selected hidden>
                        Select Country
                      </option>
                      <option value="Zimbabwe">Zimbabwe</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Button    className="procces_Button" onClick={handleSubmitTheBank}>Add Settings</Button>
            </div>
          </div>
        </div>
      )}
      <React.Fragment>
        <div className="page-content">
          <Container>
            <Breadcrumb title="GET" breadcrumbItem="Manage" />
            <Row>
              <Col
                md={{
                  offset: 3,
                  size: 6,
                }}
              >
                <h1 className="text-center all_newPaeHeading"> MANAGE</h1>
                <h5 className="text-center all_sub_new_heading">
                  View the complete list of all your saved payment methods
                </h5>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col lg="12" md="12" sm="12" xs="12">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <select
                    className="filtter_select_box"
                  >
                    <option>All</option>
                    <option>Option 1</option>
                    <option> Option 2</option>
                  </select>
                  <Button
                    type="submit"
                    className="procces_Button"
                    onClick={toggleOpenPopup}
                    style={{
                      fontFamily: "Gilroy",
                      fontWeight: "400",
                      fontSize: "18px"
                    }}
                  >
                    + Add setting
                  </Button>
                </div>
              </Col>
            </Row>
            {bankDetails?.map((item, index) => (
              <Card
                className="m-0"
                style={{ borderBottom: "1px solid #777777" }}
                key={index}
              >
                <CardBody>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={ticket}
                        alt="ticket"
                        className=""
                        style={{ width: "40px", height: "40px" }}
                      />
                      <div style={{ display: "flex", flexDirection: "column" }} className="withdrawal_card_left_heading">
                        Bank
                      </div>
                    </div>
                    <div className="withdraw_list_card_middle_part">
                      <label>{item.BankName}</label>
                      {/* <p>{`${item.AccNumber.slice(0, 3)}*********${item.AccNumber.slice(-4)}`}</p> */}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <i className="bx bx-edit" style={{ fontSize: "21px" }} onClick={() => handleEdit(item.id)} />
                      <img
                        src={deletes}
                        style={{ width: "20px", height: "20px", cursor: "pointer" }}
                        onClick={() => handleDelete(item.id)}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            ))}

            {/* <Card
              className="m-0"
              style={{ borderBottom: "1px solid #777777" }}
            >
              <CardBody>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={ticket}
                      alt="ticket"
                      className=""
                      style={{ width: "40px", height: "40px" }}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }} className="withdrawal_card_left_heading">
                      Paypal
                    </div>
                  </div>
                  <div className="withdraw_list_card_middle_part">
                    <label>Globus Bank Limited</label>
                    <p>Sandha proud (******47-C)</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={deletes}
                      style={{ width: "20px", height: "20px" }}
                    />
                  </div>
                </div>
              </CardBody>
            </Card> */}
            {/* <Card
              className="m-0"
              style={{ borderBottom: "1px solid #777777" }}
            >
              <CardBody>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={ticket}
                      alt="ticket"
                      className=""
                      style={{ width: "40px", height: "40px" }}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }} className="withdrawal_card_left_heading">
                      Bank
                    </div>
                  </div>
                  <div className="withdraw_list_card_middle_part">
                    <label>Globus Bank Limited</label>
                    <p>Sandha proud (******47-C)</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={deletes}
                      style={{ width: "20px", height: "20px" }}
                    />
                  </div>
                </div>
              </CardBody>
            </Card> */}
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};

export default WithdrawalsSetting;
import React, { useState } from 'react';
import Cookies from 'js-cookie';

const CookiesContent= () => {
  const [accepted, setAccepted] = useState(Cookies.get('cookiesAccepted'));

  const handleAccept = () => {
    Cookies.set('cookiesAccepted', 'true', { expires: 365 });
    setAccepted(true);
  };

  const handleReject = () => {
    Cookies.set('cookiesAccepted', 'false', { expires: 365 });
    setAccepted(true);
  };

  if (accepted) {
    return null; 
  }
const handleClick = () =>{
    Cookies.set('cookiesAccepted', 'false', { expires: 365 });
    setAccepted(true);
}
  return (
    <div className="cookie-consent">
      <p>rocket: Welcome to GETransfer - Elevating Your Financial Experience! :rocket:
        Unlock the power of seamless financial transactions with GETransfer's cutting-edge applications and services. Your journey to financial empowerment begins here!
        :sparkles: Why Choose GETransfer? :sparkles:
        Secure Transactions: Our top-notch security measures ensure your financial data is protected.
        Innovative Solutions: Experience the future of fintech with our state-of-the-art applications designed for your convenience.
        Global Accessibility: Transfer funds, manage transactions, and more, anytime, anywhere.
        Personalized Services: Tailored solutions to meet your unique financial needs.
        Explore the endless possibilities of digital finance. Join GETransfer today!
        :globe_with_meridians: Discover More at [www.GETransfer.com] :globe_with_meridians:
        This website uses cookies to enhance your experience.{' '}
        <p> <b> <a href="/cookies" onClick={handleClick} style={{ color: '#4e503b', textDecoration: 'underline' }}>Cookies Policy</a></b></p>
        </p>

      <button onClick={handleAccept}>Accept</button>
      <button onClick={handleReject}>Reject</button>
    </div>
  );
};

export default CookiesContent;

import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import cancelImg from "../../assets/images/NewImage/x-button.png";
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import { refundAmount } from "Context/ContextApi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { TransactionPrint } from "pages/Transactions/TransactionPrint";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const RefundModal = ({ isPopupOpen, popupData, onClose }) => {
  const componentRef = useRef();
  const [refundOpen, setRefundOpen] = useState(false)
  const [popupOpenPin, setPoupOpenPin] = useState(false)
  const [pin, setPin] = useState('')
  const [responseData, setResponseData] = useState({})
  const [refundOpenClose, setRefundOpenClose] = useState(false)
  const [refundTrueFalse, setRefundTrueFalse] = useState(true)
  const [error, setError] = useState('')
  const [isformData, setIsFormData] = useState({
    amount: "",
    description: ""
  })

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    const amountValue = name === "amount" ? parseFloat(value) : null;
    if (name === "amount") {
      if (amountValue < 0) {
        setError({ amount: "Amount cannot be less than 0" });
      } else if (amountValue > parseFloat(popupData.NetTotal)) {
        setError({ amount: "Amount cannot be greater than the net total" });
      } else {
        setError({ amount: "" });
      }
    }
    setIsFormData({ ...isformData, [name]: value });
  }

  useEffect(() => {
    setResponseData(popupData)
    setRefundTrueFalse(popupData?.Refund_Status !== 'false')
  }, [popupData])

  const SubmitRefund = async () => {
    try {
     
      const formData = {
        Amount: isformData.amount,
        Description: isformData.description,
        id: popupData?.id,
        Pin: pin
      }
      const response = await refundAmount(formData)
      console.log(response, "response");
      if (response.status === "success") {
        setIsFormData({
        amount:"",
        description:""
      })
        setPin('')
        toastr.success(response.message)
        setPoupOpenPin(!popupOpenPin)
        setRefundOpen(!refundOpen)
        setResponseData(...popupData, ...response.transactionDetails)
        setRefundTrueFalse(false)
        setRefundOpenClose(true)
      } else {
        toastr.error(response.message)
        setIsFormData({
          amount:"",
          description:""
        })
        setPin('')
      }
    } catch (error) {
      console.log(error.response);
      toastr.error(error.message)
      setPoupOpenPin(false)
    setPin('')
    }
  }
  const handleClickRefundOpen = () => {
    setRefundOpenClose(!refundOpenClose)
  }

  const handleOpenPin = ()=>{
    if(isformData.amount === ""){
      setError({amount:"Please Enter Amount"})
      return
    }
    if(isformData.description === ""){
      setError({description:"Please Enter description"})
      return
    }
    setPoupOpenPin(!popupOpenPin)
  }
  return (
    <React.Fragment>
      {isPopupOpen && responseData && (
        <div className="transaction_popup_main">
          <div className="transaction_popup_content">
            <div className="transaction_popup_left_side">
              <div className="Transaction_popup_left_side_content">
                <h3>{responseData.receiver_name}</h3>
                <h3>{responseData.Payment_type}</h3>
              </div>
              <div className="Transaction_popup_left_side_content">
                <h4> {responseData.Currency} {responseData.Amount}</h4>
              </div>

              <div className="Transaction_popup_left_side_content">
                <h5>{new Date(responseData?.Transaction_date).toLocaleString()}</h5>
                <h5 className="transaction_popup_stratus">{responseData.Status}</h5>
                <div className="transaction_popup_button">
                  <ReactToPrint
                    trigger={() => (
                      <button type="button">
                        Print
                      </button>
                    )}
                    content={() => componentRef.current}
                  />
                  <button>Email</button>
                </div>
                {responseData.Refund_Status === 'false' && (
                  <div className="refund-button">
                    <button
                    //  onClick={handleOpentheRefund}
                     >
                      <Link className="refund-link" to={`/payment-details/${responseData?.id}`}>Refund</Link>
                      </button>
                  </div>
                )}
              </div>

            </div>
            <div className="transaction_popup_right_side mt-4">
              <div className="transaction_heading_popup">
                <h3>Transaction details</h3>
                <img
                  src={cancelImg}
                  alt="cancel"
                  onClick={() => onClose()}
                />
              </div>
              <hr />
              <Row>
                <Col sm="12" xs="12" className="">
                  <div className="deposite_money_second_step_text ">
                    <div className="transaction_popup_right_side_detailsForTaxDetals">
                      <small>UTR</small>
                      <span>{responseData.UTR} </span>
                    </div>
                    <div className="transaction_popup_right_side_detailsForTaxDetals">
                      <small>Currency</small>
                      <span>{responseData.Currency}</span>
                    </div>
                  </div>
                </Col>
              </Row>
              {!refundOpenClose && (
                <div className="deposit_money_secondStep_form">
                  {responseData?.Tax_Details && JSON?.parse(responseData?.Tax_Details)?.map((item, index) => (
                    <div className="deposite_money_second_step_text " key={index}>
                      <div className="transaction_popup_right_side_detailsForTaxDetals">
                        <label>{item.Particular}</label>
                        {item.Total_Tax && (
                          <small>{item.Total_Tax} %</small>
                        )}
                      </div>
                      <span>$ {parseFloat(item.NetTotal).toFixed(2)}</span>
                    </div>
                  ))}
                  <div className="deposite_money_second_step_text">
                    <span>Total </span>
                    <span>$ {parseFloat(responseData.NetTotal)}</span>
                  </div>
                </div>)}
              {refundTrueFalse && (
                <div className="refund-details-on-popup" onClick={handleClickRefundOpen}>
                  <span>Refund</span>
                  <span className={`arrow-For-refund ${refundOpenClose ? "up" : ""}`}> </span>
                </div>
              )}
              {refundOpenClose && (<div className="deposit_money_secondStep_form">
                {responseData?.R_Tax && JSON?.parse(responseData?.R_Tax)?.map((item, index) => (
                  <div className="deposite_money_second_step_text " key={index}>
                    <div className="transaction_popup_right_side_detailsForTaxDetals">
                      <label>{item.Particular}</label>
                      {item.Total_Tax && (
                        <small>{item.Total_Tax} %</small>
                      )}
                    </div>
                    <span>$ {parseFloat(item.NetTotal).toFixed(2)}</span>
                  </div>
                ))}
                <div className="deposite_money_second_step_text">
                  <span>Total </span>
                  <span>$ {parseFloat(responseData.R_NetTotal)}</span>
                </div>
              </div>
              )}
              {refundOpen && (
                <div className="deposit_money_secondStep_form">
                  <div>
                    <Label>Amount</Label>
                    <Input type="number" placeholder="Enter refund amount" required name="amount"
                     min="0"  value={isformData.amount} onChange={handleChange} />
                    {error?.amount &&(<p>{error.amount}</p>)}
                   </div>
                  <div>
                    <Label>Refund details</Label>
                    <Input type="textarea" placeholder="Enter description" required name="description" value={isformData.description} onChange={handleChange} />
                    {error?.description &&(<p>{error.description}</p>)}
                  </div>
                  <div className="refund_submit_button">
                    <Button onClick={handleOpenPin} >Submit</Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <Modal
        isOpen={popupOpenPin}
        role="dialog"
        autoFocus={true}
        centered
      >
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <button
              type="button"
              className="btn-close"
              onClick={() => setPoupOpenPin(false)}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="text-center mb-4">
              <div className="row justify-content-center">
                <div className="col-xl-8">
                  <h4 className="model_heading_text">Verify Your pin</h4>
                  <FormGroup>
                    <Label for="pin" className="generate_pin_label">PIN:</Label>
                    <Input type="password" name="pin" id="pin" placeholder="Enter your pin"
                     min="0"  className="css_for_generatePin_input" value={pin} onChange={(e) => setPin(e.target.value)} />
                    {/* {pinError && <small className="text-danger">{pinError}</small>} */}
                  </FormGroup>
                  <Button className="model_confirm_button" type="button" onClick={SubmitRefund}>
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ReactToPrint content={() => componentRef.current} />
      <div style={{ display: "none" }}>
        <TransactionPrint ref={componentRef} transactionDetails={responseData} />
      </div>
    </React.Fragment>

  )
}
export default RefundModal;
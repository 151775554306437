import React from "react"
import { Container, Row, Col } from "reactstrap"
import getLogo from "../../assets/images/NewImage/getr-logo-black.png"

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6} className="footer-left-side-main"><img src={getLogo} className="footer-logo-image"/>GET  © {currentYear} - {nextYear} </Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block " style={{fontFamily:"Gilroy"}}>
                Design & Develop by GET
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer

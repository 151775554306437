import { gMerchantPolicy } from 'Context/ContextApi';
import GetFooter from 'components/Common/getFooter';
import Footer from 'components/VerticalLayout/Footer';
import React, { useEffect, useState } from 'react';

const PrivacyPlicy = () => {
    const [data, setData] = useState({})
    const effact = "effact";
    useEffect(() => { fData() }, [effact])

    const fData = async () => {
        try {
            const formData = {
                id: "bec691aa-4849-479a-b57b-e356dc8e8694"
            }
            const response = await gMerchantPolicy(formData)
            setData(response?.result)
        } catch (error) {
            console.log(error);
        }
    }
    document.title = "GET Platform | Privacy Policy"
    return (
        <React.Fragment>
            <div className='container policy-main-container '>
                <h1>Privacy Policy</h1>
                <div dangerouslySetInnerHTML={{ __html: data?.Description }} />
            </div>
            <GetFooter />

        </React.Fragment>
    );
};

export default PrivacyPlicy;

import React, { Component } from "react"
import { Row, Col } from "reactstrap"
import getLogo from "../../assets/images/NewImage/getr-logo-black.png"

class Footer extends Component {
  render() {
    const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;
    return (
      <React.Fragment>
        <footer className="footer">
          <div className="container-fluid">
            <Row>
              <Col sm={6} className="footer-left-side-main"><img src={getLogo} className="footer-logo-image"/>GET © {currentYear}-{nextYear}  </Col>
              <Col sm={6}>
                <div className="text-sm-end d-none d-sm-block" style={{fontFamily:"Gilroy"}}>
                  Developed & Designed by Hexims IT
              </div>
              </Col>
            </Row>
          </div>
        </footer>
      </React.Fragment>
    )
  }
}

export default Footer;
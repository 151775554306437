import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_URL;



// ==================== All Api Function ===================
const makeApiRequest = async (url, method, formData) => {
  try {
    const response = await axios({
      method,
      url: `${API_BASE_URL}${url}`,
      data: formData,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const registerApiForMerchant = async (formData) => {
    console.log(formData);
    return makeApiRequest("/api/v2/get/webmerchant/merchantregistration", "post", formData);
  };

  // get user detail by application Id
  export const getMerchantDetailsBYapplicationId = async (formData) =>{
    return makeApiRequest("/api/v2/get/webmerchant/getmerchantdatausingapplicationid", "post", formData)
  }
  
  // new Merchant onBorading form
  export const insertMerchantDataIntoMerchant_ = async (formData) =>{
    return makeApiRequest("/api/v2/get/getvault/addAndCreateMerchantOnboardingForm", "post", formData)
  }
  // new Merchant Onboarding Data
  export const getMerchant_DetailsByApplicationId = async (formData) =>{
    return makeApiRequest("/api/v2/get/getvault/fetchMerchantInfoByApplicationId", "post", formData)
  }

  // national id verify
  export const verifyTheNationalId = async (formData)=>{
    return makeApiRequest("/api/v2/get/getvault/validAndInvalidNationalId", "post", formData)
  }

    // registration form
    export const inserRegistrationDataIntoMerchant = async (formData) =>{
      return makeApiRequest("/api/v2/get/getvault/merchantRegistration", "post", formData)
    }

    // national id verify
  export const nationalidCheck = async (formData)=>{
    return makeApiRequest("/api/v2/get/usersmodal/documentOCRData", "post", formData)
  }

  // send otp
  export const sendOtp = async (formData)=>{
    return makeApiRequest("/api/v2/get/getvault/afrosoftSmsOtp", "post", formData)
  }

    // otp validate check
    export const validationOtpcheck = async (formData)=>{
      return makeApiRequest("/api/v2/get/getvault/validateOtp", "post", formData)
    }

   
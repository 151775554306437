import React from 'react';

const CookiePolicyContnent = () => {
  return (
    <>
      <article className="container cookie-policy-article">
        <h1>Cookie Policy for GETransfer Pvt Ltd Website</h1>
        <p>
          <strong>Effective Date:</strong> {new Date().toString()}
        </p>
        <h2>1. Introduction</h2>
        <p>
          Welcome to the GETransfer website operated by GETransfer Technologies Inc. ("GETransfer," "we," "us," or "our").
          This Cookie Policy is designed to inform you about our use of cookies on our website, applications, and related
          services (collectively, the "Services"). By using our Services, you agree to the use of cookies as described
          in this policy.
        </p>
        <h2>2. What Are Cookies?</h2>
        <p>
          Cookies are small text files stored on your device when you visit a website. They are widely used to enhance
          your browsing experience, personalize content, and provide analytics and insights into user behavior.
        </p>
        <h2>3. How We Use Cookies</h2>
        <p>We use cookies for various purposes, including:</p>
        <ul>
          <li>
            <strong>Authentication:</strong> To identify and authenticate users when they access our Services.
          </li>
          <li>
            <strong>Preferences:</strong> To remember your preferences and settings.
          </li>
          <li>
            <strong>Analytics:</strong> To gather information about how our Services are used, helping us improve user
            experience and optimize our offerings.
          </li>
          <li>
            <strong>Marketing:</strong> To deliver personalized advertisements and promotional content.
          </li>
        </ul>
        <h2>4. Types of Cookies We Use</h2>
        <ul>
          <li>
            <strong>Essential Cookies:</strong> These cookies are necessary for the proper functioning of our website.
            They enable you to navigate our site and use its features.
          </li>
          <li>
            <strong>Performance Cookies:</strong> These cookies collect information about how visitors use our website,
            such as which pages they visit most frequently. This helps us improve our site's performance.
          </li>
          <li>
            <strong>Functionality Cookies:</strong> These cookies allow our website to remember your choices, such as
            language preferences or region, providing enhanced, personalized features.
          </li>
          <li>
            <strong>Targeting/Advertising Cookies:</strong> These cookies are used to deliver content more relevant to
            you and your interests. They may be used to deliver targeted advertising or limit the number of times you
            see an advertisement.
          </li>
        </ul>
        <h2>5. Third-Party Cookies</h2>
        <p>
          Some cookies on our website come from third-party services that we use for analytics, advertising, and other
          functions. These third parties may use cookies to collect information about your browsing habits and deliver
          targeted content.
        </p>
        <h2>6. Managing Cookies</h2>
        <p>
          Most browsers allow you to control cookies through their settings. However, disabling certain cookies may impact
          the functionality and performance of our Services.
        </p>
        <h2>7. Changes to Our Cookie Policy</h2>
        <p>
          We may update our Cookie Policy from time to time to reflect changes in technology, applicable laws, or our
          business operations. Please check this page periodically for any updates.
        </p>
        <h2>8. Contact Us</h2>
        <p>
          If you have any questions or concerns about our Cookie Policy, please contact us at [insert contact information].
        </p>
        <p>
          Thank you for choosing GETransfer. We value your privacy and strive to provide a secure and seamless experience
          on our platform.
        </p>
      </article>


    </>
  );
};

export default CookiePolicyContnent;

import React, { useEffect, useState } from "react";
import { Button, Col, Container, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import MiniWidget from "./GetMiniWidget";
import SalesRevenue from "./SalesRevenue";
import GetNoOfTransaction from "./getNoOfTransaction";
import PaymentInsights from "./PaymentInsights";
import { fetchCheckmerchantSecreatePIn, gNoOfTransaction, gTotalInsights, gTotalTranscationPerDay, generateotpForTheMerchantSecreatPin, getDashboardWidget, setAndUpdateThesecreatePin } from "Context/ContextApi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useData } from "Context/ContextProvider";
import getLogo from "../../assets/images/NewImage/Vault_Wheel.png";
import Loader from "components/Common/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PinModal from "./PinModal";

const permision = localStorage.getItem("paths")
function getdashboard() {
  document.title = "GET Platform | Dashboard ";
  const { dashboardWidgetData } = useData();
  const [isloading, setIsLoading] = useState(true)

  const [data, setData] = useState([])
  const [data1, setData1] = useState([])
  const [data2, setData2] = useState([])

  let reloadCount = 0;
  const maxReloadAttempts = 3;

  let effact = "effact"
  useEffect(() => {
    if (permision) {
      setTimeout(() => {
        setIsLoading(false);
      }, 0.1);
    } else {
      // reloadCount++;
      // if (reloadCount >= maxReloadAttempts) {
      //   history.push("/logout")
      //   console.log("Maximum reload attempts reached. Logging out...");
      // } else {
      //   setTimeout(() => {
      //     // setIsLoading(true);
      //     window.location.reload();
      //   }, 2000);
      // }

      setTimeout(() => {
        setIsLoading(true);
        window.location.reload();
      }, 2000);
    }
  }, [effact])

  useEffect(() => {
    if (dashboardWidgetData?.result1) {
      setData(dashboardWidgetData.result1);
    }
  }, [dashboardWidgetData?.result1]);

  useEffect(() => {
    if (dashboardWidgetData?.result2) {
      setData1(dashboardWidgetData.result2);
    }
  }, [dashboardWidgetData?.result2]);

  useEffect(() => {
    if (dashboardWidgetData?.result3) {
      setData2(dashboardWidgetData.result3);
    }
  }, [dashboardWidgetData?.result3]);

  const formattedResult = data.map(item => [
    new Date(item.TransactionDay).getTime(),
    parseFloat(item.TotalAmount),
  ]);

  const formattedOfTransaction = data1?.map(item => [
    new Date(item.TransactionDay).getTime(),
    item.NumberOfTransactions,
  ]);
  useEffect(() => {
    FDashboardDta();
    FDashboardDta1();
    FDashboardDta2();
  }, [])

  const FDashboardDta = async () => {
    try {
      const response = await gTotalTranscationPerDay();
      setData(response?.result)
    } catch (error) {
      console.log(error);
    }
  }
  const FDashboardDta1 = async () => {
    try {
      const response = await gNoOfTransaction();
      setData1(response.result)
    } catch (error) {
      console.log(error);
    }
  }

  const FDashboardDta2 = async () => {
    try {
      const response = await gTotalInsights();
      setData2(response.result)
    } catch (error) {
      console.log(error);
    }
  }

  const [isButtonVisible, setButtonVisibility] = useState(true)
  const [secondsRemaining, setSecondsRemaining] = useState(60);
  const [isAccepted, setAccepted] = useState(false);
  const [checkSecreatePinData, setCheckSecreatePinData] = useState("")
  const [responseForpin, setResponseForpin] = useState("")

  useEffect(() => {
    fetchCheckScreatePinOfMerchant();
  }, [])

  const fetchCheckScreatePinOfMerchant = async () => {
    try {
      const response = await fetchCheckmerchantSecreatePIn()
      setResponseForpin(response.result)
      setCheckSecreatePinData(response.result);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    if (responseForpin.hasSecretPin == "true") {
      setAccepted(false);
    } else if (responseForpin.hasSecretPin == 'false') {
      setAccepted(true);
    }
  }, [responseForpin]);


  const startCountdown = () => {
    const countdownInterval = setInterval(() => {
      setSecondsRemaining((prevSeconds) => prevSeconds - 1);
    }, 1000);

    setTimeout(() => {
      setButtonVisibility(true);
      clearInterval(countdownInterval);
    }, 60000);
  };

  useEffect(() => {
    return () => {
      clearInterval(startCountdown);
    };
  }, []);
 

  let valume = [{
    name: "Revenue",
    data: [...formattedResult]
  }]

  let valume1 = [{
    name: "Valum",
    data: [...formattedOfTransaction]
  }]

  return (
    <>
      <React.Fragment>
        {isloading && (
          <Loader />
        )}
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Row>
                  <MiniWidget />
                </Row>
                <Row>
                  <SalesRevenue chartSeries={valume} />
                  <GetNoOfTransaction chartSeries={valume1} />
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <PaymentInsights data={data2} />
              </Col>
            </Row>
          </Container>
        </div>
        <PinModal/>
      </React.Fragment>
    </>
  );
}
export default getdashboard;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

// Import fackbackend Configuration file
import fakeBackend from "./helpers/AuthType/fakeBackend";
import Cookies from "js-cookie";
import CookiesContent from "pages/Cookies/Cookies";
import { useData } from "Context/ContextProvider";
import Loader from "components/Common/Loader";


// Activating fake backend
fakeBackend();
const permision = localStorage.getItem("paths")
const App = ({ layout }) => {
  const { mData } = useData()
  const [loader, setLoader] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (permision) {
      setInterval(() => {
        setLoader(false)
      }, 1000);
    }
  }, [permision])


  let effact = "effact"
  useEffect(() => {
    if (mData) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [mData])

  /**
   * Returns the layout
   */
  const getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };
  const Layout = getLayout();
  return (
    <React.Fragment>
      {isLoading && (
        <Loader />
      )}
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              isLocked={true}
              exact
            />
          ))}
        </Switch>
      </Router>
      <CookiesContent />
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);

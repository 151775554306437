import { takeEvery, put, call, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { loginSuccess, logoutUserSuccess, apiError } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
} from "../../../helpers/fakebackend_helper";

// import { loginUserSuccess, loginUserFailure, apiError } from './actions';
// import { postLoginApi } from '../api'; 
const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
    const data = {
      Email: user.email,
      Password_: user.password,
      IPAddress: user.IPAddress
    }
    const response = yield call(postJwtLogin, data);
    if (response && response.token) {
      localStorage.setItem("authUser", JSON.stringify(response.token));
      yield put(loginSuccess(response.token));
      yield put(apiError(response.message));
      // history.push("/get-dashboard")
      if(response.result?.Register_Status === 'COMPLETED'){
         history.push("/get-dashboard")
      }else{
        history.push("/registration-form") 
      }
    } else {
      if (response && response.status === "failed" && response.message === "Password Doesn't Match.Login With Correct Credentials.") {
        yield put(apiError("Incorrect email or password. Please try again."));
      } else {

        if (response && response.status === 401) {
          const errorResponse = JSON.parse(response.response);
          if (errorResponse && errorResponse.status === "failed" && errorResponse.message === "Password Doesn't Match.Login With Correct Credentials.") {
            yield put(apiError("Incorrect email or password. Please try again."));
          } else {
            yield put(apiError(errorResponse.message));
          }
        } else {
          yield put(apiError(response.message));
        }
      }
    }
  } catch (error) {
    yield put(apiError(error.response.data.message));
  }
  // try {
  //   if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //     const response = yield call(
  //       fireBaseBackend.loginUser,
  //       user.email,
  //       user.password
  //     );
  //     yield put(loginSuccess(response));
  //     console.log("2");
  //   } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
  //     const response = yield call(postJwtLogin, {
  //       email: user.email,
  //       password: user.password,
  //     });
  //     localStorage.setItem("authUser", JSON.stringify(response));
  //     yield put(loginSuccess(response));
  //     console.log("3");
  //   } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
  //     const response = yield call(postFakeLogin, {
  //       email: user.email,
  //       password: user.password,
  //     });
  //     console.log(response);
  //     localStorage.setItem("authUser", JSON.stringify(response));
  //     yield put(loginSuccess(response));
  //     console.log("4", response);
  //   }
  //   // history.push("/get-dashboard")
  // } catch (error) {
  //   yield put(apiError(error));
  //   console.log("5");
  //   // history.push("/get-dashboard")
  // }
}
authSaga.js



// function* loginUserSaga(action) {
//   try {
//     const { user, history } = action.payload;
//     const response = yield call(postLoginApi, user); // Assuming postLoginApi is a function that makes the API request

//     if (response.data && response.data.token) {
//       yield put(loginUserSuccess(response.data.token));
//       localStorage.setItem('token', response.data.token);
//       history.push('/dashboard'); // Redirect to the dashboard or any desired route
//     } else {
//       yield put(loginUserFailure('Invalid credentials'));
//     }
//   } catch (error) {
//     yield put(apiError('Error occurred while logging in'));
//   }
// }

// export function* watchLoginUser() {
//   yield takeLatest(LOGIN_USER, loginUserSaga);
// }


function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history.push("/login");
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);

  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;

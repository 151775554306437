import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  InputGroup,
} from "reactstrap";
import avatar from "../../assets/images/users/avatar-2.jpg";
import "./Disputes.css";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class Disputes extends Component {
  render() {
    document.title = "GET Platform  | Disputes";
    return (
      <React.Fragment>
        <div
          className="page-content"
          style={{ marginLeft: "5%", marginRight: "5%" , marginBottom:"5%"}}
        >
          <div className="container">
            <Breadcrumbs title="GET" breadcrumbItem="Disputes" />
            <Row>
              <Col
                md={{
                  offset: 3,
                  size: 6,
                }}
              >
                <h1 className="text-center all_newPaeHeading">DISPUTES</h1>
                <h5 className="text-center all_sub_new_heading">
                  Your conversations with support team regarding transaction disputes
                </h5>
              </Col>
            </Row>
            <Row className="mt-3 mb-4">
              <Col>
                <h5 className="mb-3 sub_heading_left_side_the_page">
                  All list of disputes
                </h5>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "0px",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "80%", display: "flex", gap: "10px" }}>
                  <InputGroup style={{ width:"15%"}}>
                    <Flatpickr
                      className="date_picker"
                      placeholder="Select date range"
                      options={{
                        mode: "range",
                        dateFormat: "Y-m-d",
                      }}
                      style={{borderRadius:"7px", border:"1px solid black", width:"100%"}}
                    />
                  </InputGroup>
                    <select
                     
                
                      className="filtter_select_box"
                    >
                      <option selected hidden>
                        All
                      </option>
                      <option>Open</option>
                      <option>Close</option>
                    </select>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      gap: "20px",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Gilroy",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Reset
                    </span>
                    <Button
                      type="submit"
                    className="procces_Button"
                    >
                      Apply filter
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="mt-2">
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "20px",
                        }}
                      >
                        <div>
                          <img
                            src={avatar}
                            alt="icon"
                            className="avatar-md rounded-circle img-thumbnail"
                          />
                        </div>
                        <div className="disputes_main_container_card_text_left_side">
                          <h4 className="withdrawal_card_left_heading">Description does not match with product</h4>
                          <div>
                            <span className="color_primary">DIS-665MEZ . </span>
                            <span className="withdraw_list_card_time">Claimant: kyla sarah</span>
                          </div>
                          <div>
                            <span className="withdraw_list_card_time">Transaction ID:</span>
                            <span className="disputes_transaction_text">
                              {" "}
                              9HNQHJSDUSD2Q
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "20px",
                        }}
                      >
                        <div className="disputes_main_container_card_text_left_side">
                          <Button >See details</Button>
                          <div>
                            <span className="color_primary">Solved . </span>
                            <span className="withdraw_list_card_time">21-06-2023 4:29 PM</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="mt-2">
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "20px",
                        }}
                      >
                        <div>
                          <img
                            src={avatar}
                            alt="icon"
                            className="avatar-md rounded-circle img-thumbnail"
                          />
                        </div>
                        <div className="disputes_main_container_card_text_left_side">
                          <h4 className="withdrawal_card_left_heading">Description does not match with product</h4>
                          <div>
                            <span className="color_primary">DIS-665MEZ . </span>
                            <span className="withdraw_list_card_time">Claimant: kyla sarah</span>
                          </div>
                          <div>
                            <span className="withdraw_list_card_time">Transaction ID:</span>
                            <span className="disputes_transaction_text">
                              {" "}
                              9HNQHJSDUSD2Q
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "20px",
                        }}
                      >
                        <div className="disputes_main_container_card_text_left_side">
                          <Button >See details</Button>
                          <div>
                            <span className="color_primary">Solve . </span>
                            <span className="withdraw_list_card_time">21-06-2023 4:29 PM</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="m-0">
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "20px",
                        }}
                      >
                        <div>
                          <img
                            src={avatar}
                            alt="icon"
                            className="avatar-md rounded-circle img-thumbnail"
                          />
                        </div>
                        <div className="disputes_main_container_card_text_left_side">
                          <h4 className="withdrawal_card_left_heading">Description does not match with product</h4>
                          <div>
                            <span className="color_primary">DIS-665MEZ . </span>
                            <span className="withdraw_list_card_time">Claimant: kyla sarah</span>
                          </div>
                          <div>
                            <span className="withdraw_list_card_time">Transaction ID:</span>
                            <span className="disputes_transaction_text">
                              {" "}
                              9HNQHJSDUSD2Q
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "20px",
                        }}
                      >
                        <div className="disputes_main_container_card_text_left_side">
                          <Button >See details</Button>
                          <div>
                            <span className="color_primary">Solve . </span>
                            <span className="withdraw_list_card_time">21-06-2023 4:29 PM</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Disputes;

import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useRef } from "react";
import { Button, Card, CardBody, Col, Container, Form, Row, ButtonToggle, Modal } from "reactstrap";
import Qrmodel from "../../assets/images/NewImage/qr-code-file-bangla-mobile-code-0.png";
import lock from "../../assets/images/NewImage/verrouiller-alt.png";
import edit from "../../assets/images/NewImage/editer.png";
import avatar from "../../assets/images/users/avatar-1.jpg";
import "./UserProfile.css";
import { useState } from "react";
import userEditProfile from "../../assets/images/NewImage/user-avatar.png";
import cancelImg from "../../assets/images/NewImage/x-button.png";
import { ResetMerchantPin, currencyConvert, fetchUserProfile, generateotpForTheMerchantSecreatPin, updateAndChangeSubAdminPassword, updateAndChangeThePassword, updateSubAdminProfileData, updateTheUserProfile, updateprofileData } from "Context/ContextApi";
import { useEffect } from "react";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import 'react-toggle/style.css';
import ReactToPrint from "react-to-print";
import { ComponentToPrint } from "./PrintCode";
import wheelLogo from "../../assets/images/NewImage/Vault_Wheel.png"
import { CURRENCY } from "components/Common/Currency";

const API_BASE_URL = process.env.REACT_APP_API_URL;
function SubAdminUserprofile() {
    const [userData, setUserData] = useState({});
    const [adminData, setAdminData] = useState({})
    const [isPassword, setIsPassword] = useState(false);
    const [personalSelfieDoc, setPersonalSelfieDoc] = useState([])
    const [isChangePin, setIsChangePin] = useState(false)
    const [actionData, setActionData] = useState({})
    const [changePasswordForm, setChangePasswordForm] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        applicationPin: "",
    })

    const [formErrors, setFormErrors] = useState({});
    const fileInputRef = useRef(null);
    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setPersonalSelfieDoc(selectedFile)
        if (selectedFile) {
            handleUpdateTheData('Profile',selectedFile);
        }
    };

    const [formDatass, setFormData] = useState({
        id:"",
        UserName: "",
        Email: "",
        Mobile: "",
        Role: "",
        Description: "",
        Designation: "",
        Department: "",
        Pin: "",
        ConfirmPin: "",
        Image: null,
    });

    const onChange = e => {
        setFormData({ ...formDatass, [e.target.name]: e.target.value });
    };
    const effact = "effact"
    useEffect(() => {
        fetchData();
    }, [effact]);
    const fetchData = async () => {
        try {
            const response = await fetchUserProfile();
            console.log(response);
            const result = response.result.result;
            setAdminData(response?.result?.subResult)
            setUserData(result);
            setFormData({ ...formDatass, ...response.result?.subResult });
            let jsonparse = response?.result?.subResult ? JSON.parse(response?.result?.subResult?.Permissions) : null;
            setActionData(jsonparse)
        } catch (error) {
            console.log(error);
        }
    };

    const handleUpdateTheData = async (type, profileSelfie) => {
        try {
            const data = {
                type: type,
                Profile:profileSelfie,
                ...formDatass
            }
            const formData = new FormData()
            for (const [key, value] of Object.entries(data)) {
                if (value instanceof File) {
                    formData.append(key, value);
                } else if (Array.isArray(value) && value.length > 0 && value[0] instanceof File) {
                    value.forEach((file, index) => {
                        formData.append(`${key}`, file);
                    });
                } else {
                    formData.append(key, value);
                }
            }
            const response = await updateSubAdminProfileData(formData);
            toastr.success(response.message);
            fetchData();
        } catch (error) {
            console.log(error);
        }
    };

    // validation for change password model 
    const handleChangeForPassword = e => {
        const { name, value, } = e.target;
        setChangePasswordForm({ ...changePasswordForm, [name]: value });
    }
    
    const validateForChangePassword = () => {
        let errors = {};
        if (!changePasswordForm.oldPassword) {
            errors.oldPassword = "Please enter old password";
        }
        if (!changePasswordForm.newPassword) {
            errors.newPassword = "Please enter a password";
        } else if (changePasswordForm.newPassword.length < 8) {
            errors.newPassword = "Password must be at least 8 characters long";
        } else if (
            !/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
                changePasswordForm.newPassword
            )
        ) {
            errors.newPassword =
                "Password should be a combination of alphabets, numbers, special characters, minimum 8 characters";
        }
        if (!changePasswordForm.confirmPassword) {
            errors.confirmPassword = "Please confirm your password";
        } else if (changePasswordForm.newPassword !== changePasswordForm.confirmPassword) {
            errors.confirmPassword = "Passwords do not match";
        }
        if (!changePasswordForm.applicationPin) {
            errors.applicationPin = "Enter application pin"
        }
        setFormErrors(errors);

        return Object.keys(errors).length === 0;
    };
    const handleChangePassword = async () => {
        try {
            if (!validateForChangePassword()) {
                return;
            }
            const formData = {
                id: adminData?.id,
                oldPassword: changePasswordForm.oldPassword,
                newPassword: changePasswordForm.newPassword,
                applicationPin: changePasswordForm.applicationPin
            }
            const response = await updateAndChangeSubAdminPassword(formData)
            toastr.success(response.message)
            setIsPassword(false)
            setChangePasswordForm({
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
                applicationPin: ""
            })
        } catch (error) {
            console.log(error.response);
            toastr.warning(error?.response.data?.message)
        }
    }
    const componentRef = React.useRef();
    document.title = "GET Platform  | Profile";
    return (
        <>
            <Modal
                isOpen={isPassword}
                role="dialog"
                autoFocus={true}
                centered
            >
                <div className="modal-content">
                    <div className="isDEfault_popup_content ">
                        <div className="is_default_heading">
                            <h3>Change passsword</h3>
                            <img
                                src={cancelImg}
                                alt="cancel"
                                onClick={() => setIsPassword(false)}
                            />
                        </div>
                        <div className="idefault_popup_form_group mt-3">
                            <label>Old password</label>
                            <input type="password" placeholder="Old password"
                                name="oldPassword"
                                value={changePasswordForm.oldPassword}
                                onChange={e => {
                                    handleChangeForPassword(e);
                                    if (e.target.value.trim() !== "") {
                                        setFormErrors({ ...formErrors, oldPassword: "" });
                                    }
                                }} />
                            <span className="warning_message">
                                {formErrors.oldPassword}
                            </span>
                        </div>
                        <div className="idefault_popup_form_group mt-2">
                            <label>New passsword</label>
                            <input type="password" placeholder="New password"
                                name="newPassword"
                                value={changePasswordForm.newPassword}
                                onChange={e => {
                                    handleChangeForPassword(e);
                                    if (e.target.value.trim() !== "") {
                                        setFormErrors({ ...formErrors, newPassword: "" });
                                    }
                                }} />
                            <span className="warning_message">
                                {formErrors.newPassword}
                            </span>
                        </div>
                        <div className="idefault_popup_form_group mt-2">
                            <label>Confirm passsword</label>
                            <input type="password" placeholder="Confirm password"
                                name="confirmPassword"
                                value={changePasswordForm.confirmPassword}
                                onChange={e => {
                                    handleChangeForPassword(e);
                                    if (e.target.value.trim() !== "") {
                                        setFormErrors({ ...formErrors, confirmPassword: "" });
                                    }
                                }} />
                            <span className="warning_message">
                                {formErrors.confirmPassword}
                            </span>
                        </div>
                        <div className="idefault_popup_form_group mt-2">
                            <label>Application Pin</label>
                            <input type="password" placeholder="Enter application pin"
                                name="applicationPin"
                                value={changePasswordForm.applicationPin}
                                onChange={e => {
                                    handleChangeForPassword(e);
                                    if (e.target.value.trim() !== "") {
                                        setFormErrors({ ...formErrors, applicationPin: "" });
                                    }
                                }} />
                            <span className="warning_message">
                                {formErrors.applicationPin}
                            </span>
                            <Button className="procces_Button mt-3" onClick={handleChangePassword}>Save change</Button>
                        </div>
                    </div>
                </div>
            </Modal>
            <React.Fragment>
                <div className="page-content">
                    <Container>
                        <Breadcrumbs title="GET" breadcrumbItem="Profile" />
                        <Row>
                            <Col
                                md={{
                                    offset: 3,
                                    size: 6,
                                }}
                            >
                                <h1 className="text-center all_newPaeHeading">Your profile</h1>
                                <p className="text-center all_sub_new_heading">
                                    View, edit or manage all your account related data in one
                                    place
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" md="6" sm="12" xs="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-between">
                                            <div className="align-self-center flex-1">
                                                <div
                                                    className="text-muted "
                                                    style={{
                                                        display: "flex",
                                                        flexFlow: "column",
                                                        gap: "10px",
                                                    }}
                                                >
                                                    <h3 className="userprofile_left_card_heading">
                                                        {adminData && adminData.UserName}
                                                    </h3>
                                                    <h6 className="mb-1 userProfile_left_after_heading_text">
                                                        Please set your profile image,
                                                    </h6>

                                                    <p className="mb-0 userProfile_left_after_heading_text">
                                                        supported formate jepg, png, bmp, gif, or svg
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className=""
                                                style={{
                                                    width: "200px",
                                                    height: "200px",
                                                    position: "relative",
                                                }}
                                            >
                                                <img
                                                    src={userData.ProfileImage ? `${API_BASE_URL}/${userData?.id}/adminProfile/${adminData.Image}` : wheelLogo}
                                                    alt="profile"
                                                    className="avatar-md rounded-circle img-thumbnail fullwidth"
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        objectFit: "cover",
                                                    }}
                                                />
                                                    <div>
                                                        <img
                                                            src={userEditProfile}
                                                            alt="edit"
                                                            className="Editproflile_user"
                                                            onClick={handleImageClick}
                                                        />
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            style={{ display: 'none' }}
                                                            ref={fileInputRef}
                                                            onChange={handleFileChange}
                                                        />
                                                    </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <div
                                            className="d-flex justify-content-between mt-3 "
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "10px",
                                                }}
                                            >
                                                <span className="pt-0" style={{ fontSize: "18px" }}>
                                                    Wallet balance
                                                </span>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                    alignItems: "center",
                                                    gap: "10px",
                                                    margina: "auto"
                                                }}
                                            >
                                                <span style={{
                                                    color: "purple",
                                                    fontSize: "18px",
                                                    fontFamily: "Gilroy",
                                                }}>
                                                    {CURRENCY} {userData.Balance}
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="6" md="6" sm="12" xs="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-between">
                                            <div className="align-self-center flex-1">
                                                <div
                                                    className="text-muted "
                                                    style={{
                                                        display: "flex",
                                                        flexFlow: "column",
                                                        gap: "15px",
                                                    }}
                                                >
                                                    <h3 className="userprofile_left_card_heading">
                                                        {userData && userData.VPA}
                                                    </h3>
                                                    <h6 className="mb-1 userProfile_left_after_heading_text">
                                                        Here&rsquo;s your unique QR Code
                                                    </h6>

                                                    <p className="mb-0 userProfile_left_after_heading_text me-3">
                                                        Use this for sending and receving payments, You can
                                                        print this also
                                                    </p>
                                                    <div className="d-flex gap-3">
                                                        <ReactToPrint
                                                            trigger={() => (
                                                                <Button type="button" style={{ width: '50%' }} className="procces_Button">
                                                                    Print code
                                                                </Button>
                                                            )}
                                                            content={() => componentRef.current}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className=""
                                                style={{ width: "200px", height: "200px" }}
                                                id="printContent"
                                            >
                                                <ReactToPrint content={() => componentRef.current} />
                                                <ComponentToPrint ref={componentRef} userData={userData} />
                                            </div>

                                        </div>



                                        <div
                                            className="d-flex justify-content-between mt-3"
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "10px",
                                                }}
                                            >
                                                <img src={lock} alt="lock" />
                                                <span className="pt-2 userProfile_left_after_heading_text">
                                                    Change password
                                                </span>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                    alignItems: "center",
                                                    gap: "10px",
                                                    fontFamily: "Gilroy",
                                                }}
                                            >
                                                <span className="pt-2 userProfile_left_after_heading_text">
                                                    **********
                                                </span>
                                                    <img
                                                        src={edit}
                                                        alt="edit"
                                                        onClick={() => setIsPassword(true)}
                                                        style={{ width: "25px" }}
                                                    />
                                            </div>
                                        </div>
                                        <div
                                            className="d-flex justify-content-between mt-3 mb-3 "
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "10px",
                                                }}
                                            >
                                                <img src={lock} alt=" lock" />
                                                <span className="pt-2 userProfile_left_after_heading_text">
                                                    Reset pin
                                                </span>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                    alignItems: "center",
                                                    gap: "10px",
                                                }}
                                            >
                                                <span className="pt-2 userProfile_left_after_heading_text">
                                                    **********
                                                </span>
                                                {actionData === null || (actionData?.Profile?.action === true) ? (
                                                    <img
                                                        src={edit}
                                                        alt="edit"
                                                        onClick={() => setIsChangePin(true)}
                                                        style={{ width: "25px" }}
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Card className="p-3">
                            <CardBody>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <div className="From_register_Gropu_for_input">
                                            <div className="Form_register_Input">
                                                <input
                                                    type="text"
                                                    name="UserName"
                                                    value={formDatass.UserName}
                                                    onChange={onChange}
                                                />
                                                <label className={formDatass.UserName ? 'Active' : ''}>FullName</label>
                                            </div>
                                            <div className="Form_register_Input">
                                                <input
                                                    type="text"
                                                    name="Email"
                                                    value={formDatass.Email}
                                                    // onChange={onChange}
                                                    disabled
                                                />
                                                <label className={formDatass.Email ? 'Active' : ''}>
                                                    Email
                                                </label>
                                            </div>
                                            <div className="Form_register_Input">
                                                <input
                                                    type="text"
                                                    name="Mobile"
                                                    value={formDatass.Mobile}
                                                    // onChange={onChange}
                                                    disabled
                                                />
                                                <label className={formDatass.Mobile ? 'Active' : ''}>Phone Number</label>
                                            </div>
                                            <div className="Form_register_Input">
                                                <input
                                                    type="text"
                                                    name="Role"
                                                    value={formDatass.Role}
                                                    // onChange={onChange}
                                                    disabled
                                                />
                                                <label className={formDatass.Role ? 'Active' : ''}>
                                                    Role
                                                </label>
                                            </div>
                                            <div className="Form_register_Input">
                                                <input
                                                    type="text"
                                                    name="Designation"
                                                    value={formDatass.Designation}
                                                    onChange={onChange}
                                                />
                                                <label className={formDatass.Designation ? 'Active' : ''}>
                                                    Designation
                                                </label>
                                            </div>

                                            <div className="Form_register_Input">
                                                <input
                                                    type="text"
                                                    name="Department"
                                                    value={formDatass.Department}
                                                    onChange={onChange}
                                                />
                                                <label className={formDatass.Department ? 'Active' : ''}>
                                                    Department
                                                </label>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                                <Col md="3" className="ms-3">
                                    <Button
                                        style={{ fontSize: "18px" }}
                                        className="procces_Button"
                                        onClick={() => handleUpdateTheData('personal')}
                                    >
                                        Update
                                    </Button>
                                </Col>
                        </Card>

                    </Container>
                </div>
            </React.Fragment>
        </>
    );
}
export default SubAdminUserprofile;


import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

// new pages
import Transaction from "pages/Transactions/Transaction";
import DepositMoney from "pages/DepositMoney/DepositMoney";
import WithdrawalsList from "pages/Withdrawals/WithdrawalsList";
import withdrawalsSetting from "pages/Withdrawals/withdrawalsSetting";
import Payments from "pages/Payments/Payments";
import Disputes from "pages/Disputes/Disputes";
import setting from "pages/Settings/setting";
import SendMoney from "pages/SendMoney/SendMoney";
import RequestMoney from "pages/RequestMoney/RequestMoney";
import WithdrawMoney from "pages/WithdrawMoney/WithdrawMoney";
import campaigns from "pages/Campaigns/campaigns";
import createOffer from "pages/Campaigns/createOffer";
import campaignsCoupon from "pages/Campaigns/campaignsCoupon";
import getdashboard from "pages/Get-Dashboard/getDashboard";
import MerchantRegistration from "pages/Get-Register/MerchantRegistration";
import Userprofile from "pages/UserProfile/UserProfile";
import PrintOut from "pages/DepositMoney/PrintOut";
import ManageUserGroup from "pages/UserGroups/ManageUserGroups";
import AddUserGroup from "pages/UserGroups/AddUserGroup";
import ManageRoles from "pages/ManageRoles/ManageRoles";
import AddRole from "pages/ManageRoles/AddRole";
import DepositHistory from "pages/DepositMoney/DepositHistory";
import SendHistory from "pages/SendMoney/SendMoneyHistory";
import RequestHistory from "pages/RequestMoney/RequestHistory";
import MerchantOnboarding from "pages/Authentication/RegisterNewDesign";
import General from "pages/UserGroups/general";
import Notifications from "pages/notifications/notification";
import Registration from "pages/Authentication/Registration";
import CompleteRegistration from "pages/Authentication/CompleteRegistration";
import GetRegistration from "pages/Authentication/Registration";
import CookiePolicyContnent from "pages/Cookies/CookiesContent";
import RefundPayments from "pages/Payments/RefundPayments";
import PagesFaqs from "pages/Utility/pages-faqs";
import CampaignRedeemed from "pages/Campaigns/campaignsRedeemed";
import TermsAndCondition from "pages/Policy/TermsAndCondition";
import PrivacyPlicy from "pages/Policy/PrivacyPolicy";
import SubAdminUserprofile from "pages/UserProfile/subAdminProfile";
import { components } from "react-select/dist/react-select.cjs.prod";
import ActiveLog from "pages/Activity/ActiveLog";

const authProtectedRoutes = [
  
  // New pages
  { path: "/transaction", component: Transaction },
  { path: "/deposit-money", component: DepositMoney },
  {path:"/deposit-history", component :DepositHistory},
  // withdrawals
  { path: "/withdrawalslist", component: WithdrawalsList },
  { path: "/withdrawals-setting", component: withdrawalsSetting },
  { path: "/payments", component: Payments },
  { path: "/payment-details/:id?", component: RefundPayments },
  { path: "/disputes", component: Disputes },
  { path: "/verification", component: setting },
  { path: "/send-money", component: SendMoney },
  {path:"/send-money-history", component:SendHistory},
  { path: "/request-money", component: RequestMoney },
  {path:"/request-money-history", component:RequestHistory},
  { path: "/withdraw-money", component: WithdrawMoney },
  { path: "/campaigns", component: campaigns },
  { path: "/create-offer/:id?", component: createOffer },
  {path:"/campaign-redeemed", component:CampaignRedeemed},
  { path: "/campaign-coupon", component: campaignsCoupon },
  {path:"/merchant-register", component : MerchantRegistration},
  {path:"/user-profile", component : Userprofile},
  {path:"/print-out", component : PrintOut},
  {path:"/manage-user-groups", component:ManageUserGroup},
  {path:"/add-user-group/:id?", component: AddUserGroup},
  {path:"/manage-roles", component :ManageRoles},
  {path:"/add-role/:id?", component : AddRole},
  {path:"/general", component :General},
  {path:"/Notifications",component:Notifications},
  {path:"/get-dashboard", component : getdashboard},
  {path:"/sub-admin-profile", component:SubAdminUserprofile},
  {path:"/active-log", component:ActiveLog},

  { path: "/", exact: true, component: () => <Redirect to="/get-dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  {path:"/merchant-onboarding",component : MerchantOnboarding},
  {path:'/registration-form', component: CompleteRegistration},
  {path:"/registration", component : GetRegistration},  
  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  { path: "/auth-lock-screen", component: LockScreen },
  {path:"/cookies", component:CookiePolicyContnent},
  {path:"/terms-condition", component:TermsAndCondition},
  {path:"/privacy-policy", component:PrivacyPlicy}
]

export { authProtectedRoutes, publicRoutes };

import { gMerchantPolicy } from 'Context/ContextApi';
import GetFooter from 'components/Common/getFooter';
import Footer from 'components/VerticalLayout/Footer';
import React, { useEffect, useState } from 'react';

const TermsAndCondition = () => {
    const [data, setData] = useState({})
    const effact = "effact";
    useEffect(() => { fData() }, [effact])
    const fData = async () => {
        try {
            const formData = {
                id: "df3fd90b-ba6c-430c-be0e-0b1457ee3894"
            }
            const response = await gMerchantPolicy(formData)
            setData(response?.result)
        } catch (error) {
            console.log(error);
        }
    }
    document.title = "GET Platform | Terms & Condition"
    return (
        <React.Fragment>
            <div className='container policy-main-container '>
                <h1>Terms Condition</h1>
                <div dangerouslySetInnerHTML={{ __html: data?.Description }} />
            </div>
            <GetFooter />

        </React.Fragment>
    );
};

export default TermsAndCondition;

import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import "../../pages/DepositMoney/DepositeMoneyStyle.css";
import print from "../../assets/images/NewImage/printing.png";
import success from "../../assets/images/NewImage/Invoice-Logo.png";
import bank from "../../assets/images/NewImage/bank.png"
import PrintOut from "pages/DepositMoney/PrintOut";
import { checkTaxCalclations, fetchUserProfile, getMerchantBankDetailsForSelect, getMerchantBankDetailsUsingNameapi,  postWalletToBankTransfer } from "Context/ContextApi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ReactToPrint from "react-to-print";
import { Sendmoneyprint } from "pages/SendMoney/Sendmoneyprint";
import { CURRENCY } from "components/Common/Currency";
import { useData } from "Context/ContextProvider";

function WithdrawMoney() {
  const {iPAddress} = useData();
  const [currentStep, setCurrentStep] = useState(1);
  const [valueForBankDetails, setValueForBankDetails] = useState([])
  const [popupOpen, setPopupOpen] = useState(false)
  const [pin, setPin] = useState("")
  const [pinError, setPinError] = useState('');
  const [transactionDetails, setTransactionDetails] = useState({})
  const [taxes, setTaxes] = useState([])
  const [netTotal, setNetTotal] = useState('')
  const [netTotalAfterSuccess, setNetTotalAfterSuccess] = useState('')
  const [showPrintUI, setShowPrintUI] = useState(false);
  const [actionData, setActionData] = useState(null)
  const [isPinButton, setIsPinbutton] = useState(true)
  console.log(taxes, "taxes");
  const [sendForm, setSendForm] = useState({
    currency: "ZiG",
    amount: "",
    bankName: ""
  })
  const [bankDetails, setBankDetails] = useState([])
  const [validationErrors, setValidationErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSendForm({ ...sendForm, [name]: value });
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  }
  const validateForm = () => {
    let errors = {};
    // if (!sendForm.currency) {
    //   errors.currency = "Select currency";
    // }
    if (!sendForm.amount || isNaN(sendForm.amount) || sendForm.amount <= 0) {
      errors.amount = "Please enter a valid amount greater than zero.";
    } else {
      // if (sendForm.currency === "USD" && parseFloat(sendForm.amount) < 5) {
      //   errors.amount = "Minimum amount for USD is 5";
      // } 
    //   else if (sendForm.currency === "ZWD" && sendForm.amount < 1000) {
    //     errors.amount = "Minimum amount for ZWD is 1000";
    //   }
    }
    if (!sendForm.bankName) {
      errors.bankName = "Please select bank";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleProceed = nextStep => {
    if (nextStep >= 1 && nextStep <= 3 && validateForm()) {
      setCurrentStep(nextStep);
    } else if (nextStep === 0) {
      setCurrentStep(1);
    }
  };


  const handlePopup = () => {
    setPopupOpen(true)
  }
  const handleCheckAndDeposit = () => {
    if (!pin || isNaN(pin) || pin.length !== 4) {
      setPinError('PIN must be a 4-digit number.');
      return;
    }
    setPinError('');
    walletToBankTransafer()
  }

  const walletToBankTransafer = async () => {
    setIsPinbutton(false)
    const selectedBank = bankDetails[0];
    const formData = {
      amount: sendForm.amount,
      paymentMode: 'bank',
      currency: CURRENCY,
      bankId: selectedBank ? selectedBank.BankNameId : '',
      pin: pin,
      IPAddress:iPAddress
    };
    try {
      const response = await postWalletToBankTransfer(formData)
      if (response.status === "success") {
        handleProceed(currentStep + 1);
        setPopupOpen(false)
        toastr.success(response.message)
        setPin("")
        setTransactionDetails(response.transactionDetails)
      } else {
        toastr.warning(response.message)
      }
      setPin('')
      setNetTotalAfterSuccess(response.netTotal)
      setIsPinbutton(true)
    } catch (error) {
      console.log(error);
      setPin('')
      setIsPinbutton(true)
    }
  }

  // bank details fetch
  useEffect(() => {
    fetchaBankDetails()
  }, [sendForm.bankName])
  const fetchaBankDetails = async () => {
    const formData = {
      bankName: sendForm.bankName
    }
    try {
      const response = await getMerchantBankDetailsUsingNameapi(formData)
      setBankDetails(response.result)
    } catch (error) {
      console.log(error);
    }
  }


  // get merchant bank details for the select
  useEffect(() => {
    fetchMerchantBankDetails()
  }, [])
  const fetchMerchantBankDetails = async () => {
    try {
      const response = await getMerchantBankDetailsForSelect()
      setValueForBankDetails(response.result)
    } catch (error) {
      console.log(error);
    }
  }

  // check Tax Calculations
  const fecthTaxesFrombackend = async () => {
    try {
      const formData = {
        Amount: parseFloat(sendForm.amount),
        Module: 'b86ee3d8-1987-48ab-92c1-dcd3188d3cf4',
        Module_Type: '54855c66-02dc-429f-a2e6-e5d83efacfe5',
        Nature: 'BUSINESS'
      }
      const response = await checkTaxCalclations(formData)
      console.log(response, "response");
      setTaxes(response.result)
      setNetTotal(response.netTotal)
    } catch (error) {
      console.log(error);
    }
  }

  const handleResertForm =()=>{
    setSendForm({
      amount:"",
      remarks:"",
      userName:""
    })
    setPin('')
  }
  
  let effact = "effect"
  useEffect(() => {
    fetchData();
  }, [effact]);

  const fetchData = async () => {
    try {
      const response = await fetchUserProfile();
      let jsonparse = response?.result?.subResult ? JSON.parse(response?.result?.subResult?.Permissions) : null;
      setActionData(jsonparse)
    } catch (error) {
      console.log(error);
    }
  };

  const componentRef = useRef();

  document.title = "GET Platform  | Withdraw-money"
  return (
    <>
      {showPrintUI && <PrintOut onClose={() => setShowPrintUI(false)} />}
      {!showPrintUI && (
        <React.Fragment>
          <Modal
            isOpen={popupOpen}
            role="dialog"
            autoFocus={true}
            centered
          >
            <div className="modal-content">
              <div className="modal-header border-bottom-0">
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setPopupOpen(false)}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="text-center mb-4">
                  <div className="row justify-content-center">
                    <div className="col-xl-8">
                      <h4 className="model_heading_text">Verify Your pin</h4>
                      <FormGroup>
                        <Label for="pin" className="generate_pin_label">PIN:</Label>
                        <Input type="password" name="pin" id="pin" placeholder="Enter your pin" 
                      className="css_for_generatePin_input" min="0" 
                       value={pin} onChange={(e) => setPin(e.target.value)} />
                        {pinError && <small className="text-danger">{pinError}</small>}
                      </FormGroup>
                      {isPinButton&&(
                      <Button className="model_confirm_button" type="button" onClick={handleCheckAndDeposit}>
                        Confirm
                      </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <div
            className="page-content"
            style={{ marginLeft: "5%", marginRight: "5%" }}
          >
            <div className="container-fluid">
              <Breadcrumbs title="GET" breadcrumbItem="Withdraw money" />
              <Container className="mt-5">
                <Card style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                  <Row>
                    <Col
                      md={{
                        offset: 3,
                        size: 6,
                      }}
                    >
                      <h1 className="text-center mb-4 heading_for_progress_bar">WITHDRAW MONEY</h1>
                      <p className="text-center steps_text_progress_bar">Step {currentStep} of 3</p>
                      {currentStep === 1 && (
                        <h4 className="text-center after_heading_description_In_progress">Create withdrawal</h4>
                      )}
                      {currentStep === 2 && (
                        <h4 className="text-center after_heading_description_In_progress">Confirm withdrawal</h4>
                      )}
                      {currentStep === 3 && (
                        <h4 className="text-center after_heading_description_In_progress">Withdraw complete</h4>
                      )}

                      <div className="progress_bar_main mt-4">
                        <div
                          className={`progress_bar_one ${currentStep >= 1 ? "Progress_bar_active" : ""
                            }`}
                        ></div>
                        <div
                          className={`progress_bar_one ${currentStep >= 2 ? "Progress_bar_active" : ""
                            }`}
                        ></div>
                        <div
                          className={`progress_bar_one ${currentStep === 3 ? "Progress_bar_active" : ""
                            }`}
                        ></div>
                      </div>
                      {currentStep === 1 && (
                        <p className="text-center mt-4 progress_bar_description_text">
                          Accumulated wallet funds can simply be withdrawn at any
                          time, to your paypal ID or back account. Setting up the
                          withdrawal settings is must before proceeding to make a
                          withdraw.
                        </p>
                      )}
                      {currentStep === 2 && (
                        <p className="text-center mt-4 progress_bar_description_text">
                          Please take a look before you confirm. After the
                          confirmation the administrator review the withdrawal and
                          fund amunt to your Paypal or Bank account.
                        </p>
                      )}
                    </Col>
                  </Row>
                  {currentStep === 1 && (
                    <Row>
                      <Col
                        md={{
                          offset: 3,
                          size: 6,
                        }}
                      >

                        <div className="form_gruap_for_deposit">
                          <div className="form_group_for_inside_in_deposit">
                            <label>Payment method</label>
                            <select name="bankName" value={sendForm.bankName} onChange={handleChange}>
                              <option selected hidden>Select Bank</option>
                              {valueForBankDetails.map((item, index) => (
                                <option key={index} value={item.BankName}>{item.BankName}</option>
                              ))}

                            </select>
                            {validationErrors.bankName && (
                              <span className="error-message">{validationErrors.bankName}</span>
                            )}
                          </div>
                          <div className="form_group_for_inside_in_deposit">
                            <label>Currency</label>
                            <select name="currency" value={sendForm.currency} onChange={handleChange}>
                              <option value={CURRENCY}>{CURRENCY}</option>
                            </select>
                            {validationErrors.currency && (
                              <span className="error-message">{validationErrors.currency}</span>
                            )}
                          </div>
                          <div className="form_group_for_inside_in_deposit">
                            <label>Amount</label>
                            <input type="text" placeholder="Enter amount" name="amount" min="0"  value={sendForm.amount} onChange={handleChange} />
                            {validationErrors.amount && (
                              <span className="error-message">{validationErrors.amount}</span>
                            )}
                          </div>
                          {actionData === null || (actionData?.Withdrawal?.action=== true) ? (
                          <Button
                               className="procces_Button"
                            onClick={() =>{ handleProceed(currentStep + 1)
                            ,fecthTaxesFrombackend()}}
                          >
                            Proceed
                          </Button>
                          ):null}
                        </div>
                      </Col>
                    </Row>
                  )}
                  {currentStep === 2 && (
                    <Row>
                      <Col
                        md={{
                          offset: 3,
                          size: 6,
                        }}
                      >
                        <img src={bank} alt="bank" className="success_image" />
                        <h3>Details</h3>
                        <div className="deposit_money_secondStep_form">
                          {bankDetails?.map((item, index) => (
                            <div className="deposite_moneySecondStep_form" key={index}>
                              <div className="deposite_money_second_step_text deposite_money_second_step_text_border_line">
                                <span>Bank account holders name</span>
                                <span>{item.Beneficiary}</span>
                              </div>
                              <div className="deposite_money_second_step_text deposite_money_second_step_text_border_line">
                                <span>Bank account number / IBAN</span>
                                <span>{item.AccNumber}</span>
                              </div>
                              <div className="deposite_money_second_step_text">
                                <span>SWIFT Code </span>
                                <span>{item.Swift}</span>
                              </div>
                            </div>
                          ))}
                          {/* <h3>Currency</h3> */}
                          <div className="deposit_money_secondStep_form mt-3">
                            <div className="deposite_money_second_step_text  ">
                              <span>{(taxes[0]?.Title)} </span>
                              <span>{CURRENCY} {parseFloat(taxes[0]?.Value)}</span>
                            </div>
                            <div className="deposite_money_second_step_text  ">
                              <span>Tax </span>
                            </div>
                            {taxes[1]?.Tax?.map((item, index) => (
                              <div className="transaction_details ps-4" key={index}>
                                <div className="transaction_detailsForTaxDetals">
                                  <div className="transaction_detail_Title_name">
                                    <label>{item.Particular}</label>
                                    {item.Percentage && (
                                      <small>{item.Percentage} %</small>
                                    )}
                                  </div>
                                  <div className="transaction_detail_Amount">
                                    <span>{CURRENCY} {parseFloat(item.NetTotal).toFixed(2)}</span>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="transaction_details deposite_money_second_step_text_border_line ">
                              <span>Net Tax </span>
                              <span>{CURRENCY} {parseFloat(taxes[2]?.Value)}</span>
                            </div>
                            <div className="transaction_details deposite_money_second_step_text_border_line">
                              <span>Net Total </span>
                              <span>{CURRENCY} {parseFloat(taxes[3]?.Value)}</span>
                            </div>
                          </div>
                          <Button
                               className="procces_Button mt-3"
                            onClick={() => {
                              handlePopup()
                            }}

                          >
                            Confirm & Deposit
                          </Button>
                          <span
                            className="text-center"
                            onClick={() => handleProceed(currentStep - 1)}
                          >
                            Back
                          </span>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {currentStep === 3 && (
                    <Row>
                      <Col
                        md={{
                          offset: 3,
                          size: 6,
                        }}
                      >
                        <div className="deposite_money_step_three_main">
                          <img
                            src={success}
                            alt="success"
                            className="success_image"
                          />
                          <h5 className="text-center">Success!</h5>
                          <p className="text-center">
                            Your withdrawal process is successfully complete.
                          </p>
                          <div className="deposit_money_step_three_box">
                            <h5>Amount Withdrawn</h5>
                            <h1>{CURRENCY} {parseFloat(netTotalAfterSuccess)}</h1>
                          </div>
                          <div className="deposit_monet_step_three_button">
                           <ReactToPrint
                              trigger={() => (
                                <Button type="button" className="deposit_monet_step_three_print procces_Button">
                                  <img src={print} alt="print" />
                                  Print code
                                </Button>
                              )}
                              content={() => componentRef.current}
                            />
                            <Button
                              className="deposit_monet_step_three_deposit_again"
                              onClick={() =>{ handleProceed(1),handleResertForm()}}
                            >
                              Withdraw again
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Card>
              </Container>
            </div>
          </div>
        </React.Fragment>)}
      <ReactToPrint content={() => componentRef.current} />
      <div style={{ display: "none" }}>
        <Sendmoneyprint ref={componentRef} transactionDetails={transactionDetails} />
      </div>
    </>
  );
}
export default WithdrawMoney;

import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useRef } from "react";
import { Button, Card, CardBody, Col, Container, Form, Row, ButtonToggle, Modal } from "reactstrap";
import Qrmodel from "../../assets/images/NewImage/qr-code-file-bangla-mobile-code-0.png";
import lock from "../../assets/images/NewImage/verrouiller-alt.png";
import edit from "../../assets/images/NewImage/editer.png";
import avatar from "../../assets/images/users/avatar-1.jpg";
import "./UserProfile.css";
import { useState } from "react";
import userEditProfile from "../../assets/images/NewImage/user-avatar.png";
import cancelImg from "../../assets/images/NewImage/x-button.png";
import { ResetMerchantPin, currencyConvert, fetchUserProfile, generateotpForTheMerchantSecreatPin, updateAndChangeThePassword, updateTheUserProfile, updateprofileData } from "Context/ContextApi";
import { useEffect } from "react";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import 'react-toggle/style.css';
import ReactToPrint from "react-to-print";
import { ComponentToPrint } from "./PrintCode";
import wheelLogo from "../../assets/images/NewImage/Vault_Wheel.png"
import { CURRENCY } from "components/Common/Currency";

const API_BASE_URL = process.env.REACT_APP_API_URL;
function Userprofile() {
  const [userData, setUserData] = useState({});
  const [isPassword, setIsPassword] = useState(false);
  const [isUSDToggled, setUSDToggled] = useState(true);
  const [personalSelfieDoc, setPersonalSelfieDoc] = useState([])
  const [isChangePin, setIsChangePin] = useState(false)
  const [isButtonVisible, setButtonVisibility] = useState(true)
  const [secondsRemaining, setSecondsRemaining] = useState(60);
  const [actionData, setActionData] = useState({})
  const [changePasswordForm, setChangePasswordForm] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    applicationPin: "",
  })
  const [changeResetPinForm, setChangeResetPinForm] = useState({
    newPin: "",
    confirmPin: "",
    changePinOTP: ""
  })
  const [formErrors, setFormErrors] = useState({});
  const [formErrorsPin, setFormErrorsPin] = useState({})
  const fileInputRef = useRef(null);
  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setPersonalSelfieDoc(selectedFile)
    if (selectedFile) {
      handleChange(selectedFile);
    }
  };

  const [formDatass, setFormData] = useState({
    Title: "",
    FullName: "",
    Alias: "",
    Email: "",
    Mobile: "",
    NationalIDno: "",
    AddressProofNo: "",

    PersonalAddressLine1: "",
    PersonalAddressLine2: "",
    FlatNo: "",
    PersonalNearby: "",
    PersonalCity: "",
    PersonalProviance: "",
    PersonalCountry: "",
    PersonalZipcode: "",

    BusinessName: "",
    BusinessAddressLine1: "",
    BusinessAddressLine2: "",
    BusinessNearby: "",
    BusinessCity: "",
    BusinessProviance: "",
    BusinessCountry: "",
    BusinessZipcode: "",

  });

  const onChange = e => {
    setFormData({ ...formDatass, [e.target.name]: e.target.value });
  };
  const effact = "effact"
  useEffect(() => {
    fetchData();
  }, [effact]);
  const fetchData = async () => {
    try {
      const response = await fetchUserProfile();
      const result = response.result.result;
      setUserData(result);
      setFormData({ ...formDatass, ...response.result?.result });
      let jsonparse = response?.result?.subResult ? JSON.parse(response?.result?.subResult?.Permissions) : null;
      setActionData(jsonparse)
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateTheData = async (type) => {
    try {
      const formData = {
        type: type,
        ...formDatass
      }
      const response = await updateprofileData(formData);
      toastr.success(response.message);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  // update the user profile

  const handleChange = async (item) => {
    try {
      const formData = new FormData()
      formData.append("ProfileImage", item)
      const response = await updateTheUserProfile(formData)
      toastr.success(response.message)
      fetchData();
    } catch (error) {
      console.log(error);
    }
  }

  const targetCurrency = isUSDToggled ? 'USD' : 'ZWD';

  // validation for change password model 
  const handleChangeForPassword = e => {
    const { name, value, } = e.target;
    setChangePasswordForm({ ...changePasswordForm, [name]: value });
  }
  const validateForChangePassword = () => {
    let errors = {};
    if (!changePasswordForm.oldPassword) {
      errors.oldPassword = "Please enter old password";
    }
    if (!changePasswordForm.newPassword) {
      errors.newPassword = "Please enter a password";
    } else if (changePasswordForm.newPassword.length < 8) {
      errors.newPassword = "Password must be at least 8 characters long";
    } else if (
      !/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        changePasswordForm.newPassword
      )
    ) {
      errors.newPassword =
        "Password should be a combination of alphabets, numbers, special characters, minimum 8 characters";
    }
    if (!changePasswordForm.confirmPassword) {
      errors.confirmPassword = "Please confirm your password";
    } else if (changePasswordForm.newPassword !== changePasswordForm.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }
    if (!changePasswordForm.applicationPin) {
      errors.applicationPin = "Enter application pin"
    }
    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };
  const handleChangePassword = async () => {
    try {
      if (!validateForChangePassword()) {
        return;
      }
      const formData = {
        oldPassword: changePasswordForm.oldPassword,
        newPassword: changePasswordForm.newPassword,
        applicationPin: changePasswordForm.applicationPin
      }
      const response = await updateAndChangeThePassword(formData)
      toastr.success(response.message)
      setIsPassword(false)
      setChangePasswordForm({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        applicationPin: ""
      })
    } catch (error) {
      console.log(error);
      toastr.warning(error.message)
    }
  }

  // generate otp
  const handleGenerateOTP = async () => {
    try {
      const response = await generateotpForTheMerchantSecreatPin()
      console.log(response);
      toastr.success(response.message)
      setButtonVisibility(false);
      setSecondsRemaining(60);
      startCountdown();
    } catch (error) {
      console.log(error);
    }
  };

  const startCountdown = () => {
    const countdownInterval = setInterval(() => {
      setSecondsRemaining((prevSeconds) => prevSeconds - 1);
    }, 1000);

    setTimeout(() => {
      setButtonVisibility(true);
      clearInterval(countdownInterval);
    }, 60000);
  };

  useEffect(() => {
    return () => {
      clearInterval(startCountdown);
    };
  }, []);

  // validation for change Reset pin modal 
  const handleChangeForResetPin = e => {
    const { name, value, } = e.target;
    setChangeResetPinForm({ ...changeResetPinForm, [name]: value });
  }
  const validateForResetPin = () => {
    let errors = {};
    if (!changeResetPinForm.newPin) {
      errors.newPin = "Please enter a pin";
    } else if (changeResetPinForm.newPin.length < 4) {
      errors.newPin = "Pin must be at least 4 characters long";
    } else if (!/^\d{4}$/.test(changeResetPinForm.newPin)) {
      errors.newPin = "Pin should be numeric and exactly 4 characters long";
    }

    if (!changeResetPinForm.confirmPin) {
      errors.confirmPin = "Please confirm your pin";
    } else if (changeResetPinForm.newPin !== changeResetPinForm.confirmPin) {
      errors.confirmPin = "Pins do not match";
    }
    if (!changeResetPinForm.changePinOTP) {
      errors.changePinOTP = "Enter application otp"
    }
    setFormErrorsPin(errors);

    return Object.keys(errors).length === 0;
  };
  
  const handleChangeResetPin = async () => {
    try {
      if (!validateForResetPin()) {
        return;
      }
      const formData = {
        pin: changeResetPinForm.newPin,
        otp: changeResetPinForm.changePinOTP
      }
      const response = await ResetMerchantPin(formData)
      toastr.success(response.message)
      setIsChangePin(false)
      setChangeResetPinForm({
        newPin: "",
        confirmPin: "",
        changePinOTP: ""
      })
    } catch (error) {
      console.log(error);
      toastr.warning(error.message)
    }
  }
  const componentRef = React.useRef();
  document.title = "GET Platform  | Profile";
  return (
    <>
      <Modal
        isOpen={isPassword}
        role="dialog"
        autoFocus={true}
        centered
      >
        <div className="modal-content">
          <div className="isDEfault_popup_content ">
            <div className="is_default_heading">
              <h3>Change passsword</h3>
              <img
                src={cancelImg}
                alt="cancel"
                onClick={() => setIsPassword(false)}
              />
            </div>
            <div className="idefault_popup_form_group mt-3">
              <label>Old password</label>
              <input type="password" placeholder="Old password"
                name="oldPassword"
                value={changePasswordForm.oldPassword}
                onChange={e => {
                  handleChangeForPassword(e);
                  if (e.target.value.trim() !== "") {
                    setFormErrors({ ...formErrors, oldPassword: "" });
                  }
                }} />
              <span className="warning_message">
                {formErrors.oldPassword}
              </span>
            </div>
            <div className="idefault_popup_form_group mt-2">
              <label>New passsword</label>
              <input type="password" placeholder="New password"
                name="newPassword"
                value={changePasswordForm.newPassword}
                onChange={e => {
                  handleChangeForPassword(e);
                  if (e.target.value.trim() !== "") {
                    setFormErrors({ ...formErrors, newPassword: "" });
                  }
                }} />
              <span className="warning_message">
                {formErrors.newPassword}
              </span>
            </div>
            <div className="idefault_popup_form_group mt-2">
              <label>Confirm passsword</label>
              <input type="password" placeholder="Confirm password"
                name="confirmPassword"
                value={changePasswordForm.confirmPassword}
                onChange={e => {
                  handleChangeForPassword(e);
                  if (e.target.value.trim() !== "") {
                    setFormErrors({ ...formErrors, confirmPassword: "" });
                  }
                }} />
              <span className="warning_message">
                {formErrors.confirmPassword}
              </span>
            </div>
            <div className="idefault_popup_form_group mt-2">
              <label>Application Pin</label>
              <input type="password" placeholder="Enter application pin"
                name="applicationPin"
                value={changePasswordForm.applicationPin}
                onChange={e => {
                  handleChangeForPassword(e);
                  if (e.target.value.trim() !== "") {
                    setFormErrors({ ...formErrors, applicationPin: "" });
                  }
                }} />
              <span className="warning_message">
                {formErrors.applicationPin}
              </span>
              <Button className="procces_Button mt-3" onClick={handleChangePassword}>Save change</Button>
            </div>
          </div>
        </div>
      </Modal>
      {/* change pin model */}
      <Modal
        isOpen={isChangePin}
        role="dialog"
        autoFocus={true}
        centered
      >
        <div className="modal-content">
          <div className="isDEfault_popup_content ">
            <div className="is_default_heading">
              <h3>Reset Pin</h3>

              <img
                src={cancelImg}
                alt="cancel"
                onClick={() => setIsChangePin(false)}
              />
            </div>

            <div className="idefault_popup_form_group mt-2">
              <label>New Pin</label>
              <input type="number" placeholder="New Pin"
                name="newPin"
                value={changeResetPinForm.newPin}
                onChange={e => {
                  handleChangeForResetPin(e);
                  if (e.target.value.trim() !== "") {
                    setFormErrors({ ...formErrors, newPin: "" });
                  }
                }} />
              <span className="warning_message">
                {formErrorsPin.newPin}
              </span>
            </div>
            <div className="idefault_popup_form_group mt-2">
              <label>Confirm pin</label>
              <input type="number" placeholder="Confirm Pin"
                name="confirmPin"
                value={changeResetPinForm.confirmPin}
                onChange={e => {
                  handleChangeForResetPin(e);
                  if (e.target.value.trim() !== "") {
                    setFormErrors({ ...formErrors, confirmPin: "" });
                  }
                }} />
              <span className="warning_message">
                {formErrorsPin.confirmPin}
              </span>

            </div>
            <div className="idefault_popup_form_group mt-3">
              <label>Request OTP</label>
              <div className="request_OTP_input">
                <input type="number" placeholder="Enter OTP"
                  name="changePinOTP"
                  value={changeResetPinForm.changePinOTP}
                  onChange={e => {
                    handleChangeForResetPin(e);
                    if (e.target.value.trim() !== "") {
                      setFormErrors({ ...formErrors, changePinOTP: "" });
                    }
                  }} />

                <Button className={`procces_Button ${isButtonVisible ? "disable_button" : ""}`} onClick={handleGenerateOTP}>Request OTP</Button>
              </div>
              <span className="warning_message">
                {formErrorsPin.changePinOTP}
              </span>
              {!isButtonVisible && (
                <small className="generate_pin_timer">{secondsRemaining > 0 ? `${secondsRemaining} sec` : ''}</small>
              )}
            </div>
            <div className="idefault_popup_form_group">
              <Button className="procces_Button mt-3" onClick={handleChangeResetPin}>Save change</Button>

            </div>
          </div>
        </div>
      </Modal>
      {/* )} */}
      <React.Fragment>
        <div className="page-content">
          <Container>
            <Breadcrumbs title="GET" breadcrumbItem="Profile" />
            <Row>
              <Col
                md={{
                  offset: 3,
                  size: 6,
                }}
              >
                <h1 className="text-center all_newPaeHeading">Your profile</h1>
                <p className="text-center all_sub_new_heading">
                  View, edit or manage all your account related data in one
                  place
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6" sm="12" xs="12">
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <div className="align-self-center flex-1">
                        <div
                          className="text-muted "
                          style={{
                            display: "flex",
                            flexFlow: "column",
                            gap: "10px",
                          }}
                        >
                          <h3 className="userprofile_left_card_heading">
                            {userData && userData.FullName}
                          </h3>
                          <h6 className="mb-1 userProfile_left_after_heading_text">
                            Please set your profile image,
                          </h6>

                          <p className="mb-0 userProfile_left_after_heading_text">
                            supported formate jepg, png, bmp, gif, or svg
                          </p>
                        </div>
                      </div>
                      <div
                        className=""
                        style={{
                          width: "200px",
                          height: "200px",
                          position: "relative",
                        }}
                      >
                        <img
                          src={userData.ProfileImage ? `${API_BASE_URL}/${userData?.id}/profile/${userData.ProfileImage}` : wheelLogo}
                          alt="profile"
                          className="avatar-md rounded-circle img-thumbnail fullwidth"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                        {actionData === null || (actionData?.MProfile?.action === true) ? (
                          <div>
                            <img
                              src={userEditProfile}
                              alt="edit"
                              className="Editproflile_user"
                              onClick={handleImageClick}
                            />
                            <input
                              type="file"
                              accept="image/*"
                              style={{ display: 'none' }}
                              ref={fileInputRef}
                              onChange={handleFileChange}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div
                      className="d-flex justify-content-between mt-3 "
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <span className="pt-0" style={{ fontSize: "18px" }}>
                          Wallet balance
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          gap: "10px",
                          margina: "auto"
                        }}
                      >
                        <span style={{
                          color: "purple",
                          fontSize: "18px",
                          fontFamily: "Gilroy",
                        }}>
                          {/* {currenncyRaates.currency || "USD"} {currenncyRaates.currency === "USD" ? "$" : currenncyRaates.currency === "ZWD" ? "Z$" : "$"}{currenncyRaates.convertedAmount || userData.Balance} */}
                          {CURRENCY} {userData.Balance}
                        </span>
                        <span
                          className="p-0"
                          style={{
                            color: "purple",
                            fontSize: "18px",
                            fontFamily: "Gilroy",
                          }}
                        >
                          {/* <Toggle
                            id='biscuit-status'
                            defaultChecked={isUSDToggled}
                            aria-labelledby='biscuit-label'
                            checked={isUSDToggled}
                            onChange={handleToggle}
                          /> */}
                        </span>

                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" md="6" sm="12" xs="12">
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <div className="align-self-center flex-1">
                        <div
                          className="text-muted "
                          style={{
                            display: "flex",
                            flexFlow: "column",
                            gap: "15px",
                          }}
                        >
                          <h3 className="userprofile_left_card_heading">
                            {userData && userData.VPA}
                          </h3>
                          <h6 className="mb-1 userProfile_left_after_heading_text">
                            Here&rsquo;s your unique QR Code
                          </h6>

                          <p className="mb-0 userProfile_left_after_heading_text me-3">
                            Use this for sending and receving payments, You can
                            print this also
                          </p>
                          <div className="d-flex gap-3">
                            <ReactToPrint
                              trigger={() => (
                                <Button type="button" style={{ width: '50%' }} className="procces_Button">
                                  Print code
                                </Button>
                              )}
                              content={() => componentRef.current}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className=""
                        style={{ width: "200px", height: "200px" }}
                        id="printContent"
                      >
                        <ReactToPrint content={() => componentRef.current} />
                        <ComponentToPrint ref={componentRef} userData={userData} />
                      </div>

                    </div>



                    <div
                      className="d-flex justify-content-between mt-3"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <img src={lock} alt="lock" />
                        <span className="pt-2 userProfile_left_after_heading_text">
                          Change password
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          gap: "10px",
                          fontFamily: "Gilroy",
                        }}
                      >
                        <span className="pt-2 userProfile_left_after_heading_text">
                          **********
                        </span>
                        {actionData === null || (actionData?.MProfile?.action === true) ? (
                          <img
                            src={edit}
                            alt="edit"
                            onClick={() => setIsPassword(true)}
                            style={{ width: "25px" }}
                          />
                        ) : null}
                      </div>
                    </div>
                    <div
                      className="d-flex justify-content-between mt-3 mb-3 "
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <img src={lock} alt=" lock" />
                        <span className="pt-2 userProfile_left_after_heading_text">
                          Reset pin
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <span className="pt-2 userProfile_left_after_heading_text">
                          **********
                        </span>
                        {actionData === null || (actionData?.MProfile?.action === true) ? (
                          <img
                            src={edit}
                            alt="edit"
                            onClick={() => setIsChangePin(true)}
                            style={{ width: "25px" }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Card className="p-3">
              <h4 className="personal_info_use_profile mb-2 mt-3 ms-4">
                Personal
              </h4>
              <CardBody>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="From_register_Gropu_for_input">
                      <div className="Form_register_Input">
                        <input
                          type="text"
                          name="FullName"
                          value={formDatass.FullName}
                          onChange={onChange}
                        />
                        <label className={formDatass.FullName ? 'Active' : ''}>FullName</label>
                      </div>
                      <div className="Form_register_Input">
                        <input
                          type="text"
                          name="Alias"
                          value={formDatass.Alias}
                          onChange={onChange}
                        />
                        <label className={formDatass.Alias ? 'Active' : ''}>Alias</label>
                      </div>

                      <div className="Form_register_Input">
                        <input
                          type="text"
                          name="Email"
                          value={formDatass.Email}
                        // onChange={onChange}
                        disabled
                        />
                        <label className={formDatass.Email ? 'Active' : ''}>
                          Email
                        </label>
                      </div>
                      <div className="Form_register_Input">
                        <input
                          type="text"
                          name="Mobile"
                          value={formDatass.Mobile}
                        // onChange={onChange}
                        disabled
                        />
                        <label className={formDatass.Mobile ? 'Active' : ''}>Phone Number</label>
                      </div>
                      <div className="Form_register_Input">
                        <input
                          type="text"
                          name="NationalIDno"
                          value={formDatass.NationalIDno}
                          // onChange={onChange}
                          disabled
                        />
                        <label className={formDatass.NationalIDno ? 'Active' : ''}>
                          National ID
                        </label>
                      </div>
                      <div className="Form_register_Input">
                        <input
                          type="text"
                          name="AddressProofNo"
                          value={formDatass.AddressProofNo}
                          onChange={onChange}
                        />
                        <label className={formDatass.AddressProofNo ? 'Active' : ''}>
                          Address Proof NO
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>

              </CardBody>
              {actionData === null || (actionData?.MProfile?.action === true) ? (
                <Col md="3" className="ms-3">
                  <Button
                    style={{ fontSize: "18px" }}
                    className="procces_Button"
                    onClick={() => handleUpdateTheData('personal1')}
                  >
                    Update
                  </Button>
                </Col>
              ) : null}
            </Card>
            <Card className="p-3">
              <h4 className="personal_info_use_profile mb-2 mt-3 ms-4">
                Address
              </h4>
              <CardBody>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="From_register_Gropu_for_input">
                      <div className="Form_register_Input">
                        <input
                          type="text"

                          name="PersonalAddressLine1"
                          value={formDatass.PersonalAddressLine1}
                          onChange={onChange}
                        />
                        <label className={formDatass.PersonalAddressLine1 ? 'Active' : ''}>
                          Personal Address Line 1
                        </label>
                      </div>

                      <div className="Form_register_Input">
                        <input
                          type="text"

                          name="PersonalAddressLine2"
                          value={formDatass.PersonalAddressLine2}
                          onChange={onChange}
                        />
                        <label className={formDatass.PersonalAddressLine2 ? 'Active' : ''}>
                          Personal Address Line 2
                        </label>
                      </div>
                      <div className="Form_register_Input">
                        <input
                          type="text"
                          name="FlatNo"
                          value={formDatass.FlatNo}
                          onChange={onChange}
                        />
                        <label className={formDatass.FlatNo ? 'Active' : ''}>
                          Flat No
                        </label>
                      </div>
                      <div className="Form_register_Input">
                        <input
                          type="text"
                          name="PersonalNearby"
                          value={formDatass.PersonalNearby}
                          onChange={onChange}
                        />
                        <label className={formDatass.PersonalNearby ? 'Active' : ''}>
                          Landmark / Nearby Area
                        </label>
                      </div>

                      <div className="Form_register_Input">
                        <input
                          type="text"

                          name="PersonalCity"
                          value={formDatass.PersonalCity}
                          onChange={onChange}
                        />
                        <label className={formDatass.PersonalCity ? 'Active' : ''}>
                          City
                        </label>
                      </div>

                      <div className="Form_register_Input">
                        <input
                          type="text"
                          name="PersonalProviance"
                          value={formDatass.PersonalProviance}
                          onChange={onChange}
                        />
                        <label className={formDatass.PersonalProviance ? 'Active' : ''}>
                          Proviance
                        </label>
                      </div>

                      <div className="Form_register_Input">
                        <input
                          name="PersonalZipcode"
                          value={formDatass.PersonalZipcode}
                          onChange={onChange}
                        />
                        <label className={formDatass.PersonalZipcode ? 'Active' : ''}>
                          Zipcode
                        </label>
                      </div>

                      <div className="Form_register_Input">
                        <input
                          type="text"
                          name="PersonalCountry"
                          value={formDatass.PersonalCountry}
                        // onChange={onChange}
                        />
                        <label className={formDatass.PersonalCountry ? 'Active' : ''}>
                          Country
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {actionData === null || (actionData?.MProfile?.action === true) ? (
                <Col md="3" className="ms-3">
                  <Button
                    style={{ fontSize: "18px" }}
                    className="procces_Button"
                    onClick={() => handleUpdateTheData('personal')}
                  >
                    Update
                  </Button>
                </Col>
              ) : null}
            </Card>
            <Card className="p-3">
              <h4 className="personal_info_use_profile mb-2 mt-3 ms-4">
                Business
              </h4>
              <CardBody>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="From_register_Gropu_for_input">
                      <div className="Form_register_Input">
                        <input
                          type="text"

                          name="BusinessName"
                          value={formDatass.BusinessName}
                          onChange={onChange}
                        />
                        <label className={formDatass.BusinessName ? 'Active' : ''}>
                          Business Name / Company Name
                        </label>
                      </div>
                      <div className="Form_register_Input">
                        <input
                          type="text"

                          name="BusinessAddressLine1"
                          value={formDatass.BusinessAddressLine1}
                          onChange={onChange}
                        />
                        <label className={formDatass.BusinessAddressLine1 ? 'Active' : ''}>
                          Business Address Line 1
                        </label>
                      </div>

                      <div className="Form_register_Input">
                        <input
                          type="text"
                          name="BusinessAddressLine2"
                          value={formDatass.BusinessAddressLine2}
                          onChange={onChange}
                        />
                        <label className={formDatass.BusinessAddressLine2 ? 'Active' : ''}>
                          Business Address Line 2
                        </label>
                      </div>

                      <div className="Form_register_Input">
                        <input
                          type="text"
                          name="BusinessNearby"
                          value={formDatass.BusinessNearby}
                          onChange={onChange}
                        />
                        <label className={formDatass.BusinessNearby ? 'Active' : ''}>
                          BusinessNearby
                        </label>
                      </div>

                      <div className="Form_register_Input">
                        <input
                          type="text"
                          name="BusinessCity"
                          value={formDatass.BusinessCity}
                          onChange={onChange}
                        />
                        <label className={formDatass.BusinessCity ? 'Active' : ''}>
                          City
                        </label>
                      </div>

                      <div className="Form_register_Input">
                        <input
                          type="text"
                          name="BusinessProviance"
                          value={formDatass.BusinessProviance}
                          onChange={onChange}
                        />
                        <label className={formDatass.BusinessProviance ? 'Active' : ''}>
                          Proviance
                        </label>
                      </div>

                      <div className="Form_register_Input">
                        <input
                          type="text"
                          name="BusinessZipcode"
                          value={formDatass.BusinessZipcode}
                          onChange={onChange}
                        />
                        <label className={formDatass.BusinessZipcode ? 'Active' : ''}>
                          Zipcode
                        </label>
                      </div>

                      <div className="Form_register_Input">
                        <input
                          type="text"
                          name="BusinessCountry"
                          value={formDatass.BusinessCountry}
                        // onChange={onChange}
                        />
                        <label className={formDatass.BusinessCountry ? 'Active' : ''}>
                          Country
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {actionData === null || (actionData?.MProfile?.action === true) ? (
                <Col md="3" className="ms-3">
                  <Button
                    style={{ fontSize: "18px" }}
                    className="procces_Button"
                    onClick={() => handleUpdateTheData('business')}
                  >
                    Update
                  </Button>
                </Col>
              ) : null}
            </Card>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default Userprofile;


import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useCallback, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Button, Card, Col, Form, Label, Row } from "reactstrap";
import { Link } from "react-router-dom"
import { fetchLogoOfmerchant, updateTheLogoOfMerchant } from "Context/ContextApi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import merchantWheel from "../../assets/images/NewImage/Vault_Wheel.png"

const API_BASE_URL = process.env.REACT_APP_API_URL;
const General = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [logoImage, setLogoImage] = useState({})
    const [errorMessage, setErrorMessage] = useState("")

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };
  

    const handleSumbit = async () => {
        try {
            if (selectedFiles.length === 0) {
                setErrorMessage("Please upload file");
                return;
            }
            const formData = new FormData()
            selectedFiles.forEach((file, index) => {
                formData.append(`MerchantLogo`, file);
              });
            const response = await updateTheLogoOfMerchant(formData)
            setSelectedFiles([])
            getchLogo()
            setErrorMessage("")
            toastr.success(response.message)
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getchLogo()
    }, [])
    const getchLogo = async () => {
        try {
            const response = await fetchLogoOfmerchant()
            console.log(response, "response");
            setLogoImage(response.result)
        } catch (error) {
            console.log(error);
        }
    }
        const handleAcceptedFiles = useCallback(async (files) => {
        const updatedFiles = files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );     
        const img = new Image();
        img.src = updatedFiles;
        img.onload = () => {
            console.log("1");
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            console.log(width, height);
            if (width == height) {
               return false;
            }
            return true;
        } 
        setSelectedFiles(updatedFiles);
    }, []);

    // Format file size
    document.title = "GET Platform  | general"
    return (
        <>
            <React.Fragment>
                <div
                    className="page-content"
                    style={{ marginLeft: "5%", marginRight: "5%" }}
                >
                    <div className="container-fluid">
                        <Breadcrumbs title="GET" breadcrumbItem="General" />
                        <Card style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                            <Row>
                                <Col
                                    md={{
                                        offset: 3,
                                        size: 6,
                                    }}
                                >
                                    <h1 className="text-center mb-4 heading_for_progress_bar">
                                        GENERAL
                                    </h1>
                                    <div className="create_ticket_form">
                                        <Label>Upload logo</Label>
                                        <Form>
                                            <Dropzone onDrop={handleAcceptedFiles} multiple>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div className="dropzone">
                                                        <div
                                                            className="dz-message needsclick"
                                                            {...getRootProps()}
                                                        >
                                                            <input {...getInputProps()} />
                                                            <div className="mb-3">
                                                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                            </div>
                                                            <h4>Drop files here or click to upload.</h4>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                            {errorMessage && <div className="Error_message_for_upload_file">
                                                <p>
                                                    {errorMessage}
                                                </p>
                                            </div>}
                                            <div
                                                className="dropzone-previews mt-3"
                                                id="file-previews"
                                            >
                                                {selectedFiles.map((f, i) => (
                                                    <Card
                                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                        key={i + "-file"}
                                                    >
                                                        <div className="p-2">
                                                            <Row className="align-items-center">
                                                                <Col className="col-auto">
                                                                    <img
                                                                        data-dz-thumbnail=""
                                                                        height="80"
                                                                        className="avatar-sm rounded bg-light"
                                                                        alt={f.name}
                                                                        src={f.preview}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <Link
                                                                        to="#"
                                                                        className="text-muted font-weight-bold"
                                                                    >
                                                                        {f.name}
                                                                    </Link>
                                                                    <p className="mb-0">
                                                                        <strong>{f.formattedSize}</strong>
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Card>
                                                ))}
                                            </div>
                                        </Form>
                                    </div>
                                    <Button
                                        className="w-100 mt-4 procces_Button"
                                        style={{ fontSize: "18px" }}
                                        onClick={handleSumbit}
                                    >
                                        {" "}
                                        Upload
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    md={{
                                        offset: 3,
                                        size: 6,
                                    }}
                                >
                                    {logoImage?.MerchantLogo ?(
                                    <div className="logo_preview">
                                        <img src={`${API_BASE_URL}/${logoImage?.id}/profile/${logoImage?.MerchantLogo}`} />
                                    </div>
                                    ):(
                                        <div className="logo_preview">
                                        <img src={merchantWheel} alt="image"/>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </div>
            </React.Fragment>
        </>
    )
}
export default General
import React from 'react';
import logoImage from "../../assets/images/NewImage/getr-logo-black.png"

const LoginFooter = () => {
    return (
        <div className='Login-footer-main-container' >
            <div className='Login-footer-main-content' >
                <a href="/login"><img src={logoImage} alt='image' /></a>
                <span>@2024 - 2025 GETransfer Pvt Ltd</span>
                <span>All rights reserved.</span>
            </div>
        </div>
    );
};

export default LoginFooter;
import React from 'react';

const PolicyFooter = () => {
    return (
        <div className="PolicyFooter-main mt-3">
            <div className="d-flex gap-3 text-center">
                <h6> <a href="/privacy-policy">Privacy Policy</a></h6>
                <h6>|</h6>
                <h6><a href="/terms-condition">Terms & Conditions</a></h6>
            </div>
        </div>
    );
};
export default PolicyFooter;

import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  InputGroup,
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import Breadcrumbs from "components/Common/Breadcrumb";
import { gMerchantActiveLog, } from "Context/ContextApi";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "jspdf-autotable";
import arrowicon from "../../assets/images/NewImage/skip-track.png"
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";


const ActiveLog = () => {
  const history = useHistory()
  const [data, setData] = useState([])
  console.log(data, "datas");

  const effact = "effact"

  useEffect(() => {
    fetchTheMerchantTransacrionHistory()
  }, [effact])



  const fetchTheMerchantTransacrionHistory = async () => {
    try {
      const response = await gMerchantActiveLog()
      setData(response.result)
    } catch (error) {
      console.log(error);
    }
  }

  const handleClickRoutes = (id) => {
    if (id) {
      history.push(`/payment-details/${id}`)
    }
  }

  const columns = [
    {
      dataField: "TimeStamp",
      text: "Date",
      sort: true,
      formatter: (cell, row) => {
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false,
        };
        return new Date(cell).toLocaleString("en-US", options);
      },
    },
    {
      dataField: "FullName",
      text: "FullName",
      sort: true,
    },
    {
      dataField: "Role",
      text: "Role",
      sort: true,
    },
    {
      dataField: "Section",
      text: "Section",
      sort: true
    },
    {
      dataField: "Action",
      text: "Action",
      sort: true,
      formatter: (cell, row) => (
        <span className={`underLine ${row.TransactionId === null ? "" : "underLineCss"}`} onClick={() => handleClickRoutes(row.TransactionId)}
        style={{
          width: "200px",
          overflow: "hidden",
          whiteSpace: "wrap",
          textOverflow: "ellipsis",
        }}>
          {cell}
        </span>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: data.length,
    custom: true,
  };
  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content"
        style={{ marginLeft: "5%", marginRight: "5%" }}>
        <Breadcrumbs title="GET" breadcrumbItem="Activity" />
        <Row>
          <Col
            md={{
              offset: 3,
              size: 6,
            }}
          >
            <h2
              className="text-center mt-5 mb-4 "
              style={{ fontFamily: "Gilroy", color: "#db9c45" }}
            >
              ACTIVITY
            </h2>
            <p className="text-center heading_description mb-5">
              History of all activity in your account
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
              className="mb-4"
            >
              <div style={{ width: "80%", display: "flex", gap: "10px", flexWrap:"wrap" }}>
                <div className="filtter_Date_range">
                <InputGroup >
                  <Flatpickr
                    className="date_picker"
                    placeholder="Select date range"
                    options={{
                      mode: "range",
                      dateFormat: "Y-m-d",
                    }}
                    onChange={handleDateChange}
                  />
                </InputGroup>
                </div>
                <select className="filtter_select_box" name="status" value={status} 
                onChange={(e) => {
                  const selectedStatus = e.target.value;
                  setStatus(selectedStatus);
                  fetchTheMerchantTransacrionHistory(selectedStatus, payment_type);
                }}
                >
                  <option defaultValue hidden>
                    Status
                  </option>
                  <option value="">All</option>
                  <option value="completed">Successfull</option>
                  <option value="pending"> Pending</option>
                  <option value="Rejected"> Rejected</option>
                  <option value="failed">Failed</option>
                </select>
                <select className="filtter_select_box" name="payment_type" value={payment_type} onChange={(e) => {
                  const selectedPaymentType = e.target.value;
                  setPayment_type(selectedPaymentType);
                  fetchTheMerchantTransacrionHistory(status, selectedPaymentType);
                }}>
                  <option defaultValue hidden>
                    Type
                  </option>
                  <option value="">All</option>
                  <option value="personal transfer">Personal </option>
                  <option value="merchant transfer"> Merchant </option>
                  <option value="topup">Topup</option>
                  <option value="GetMoney">Request</option>
                  <option value="bankwithdraw">Withdraw</option>
                </select>
              </div>
            </div> */}
            <Card className="mt-5">
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={columns}
                  // data={products}
                  data={data}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={data}
                      search
                      exportCSV
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable

                                  // id="my-table"
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  //   selectRow={selectRow}
                                  classes={
                                    "table align-middle table-nowrap"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  style={{
                                    fontWeight: "Gilroy",
                                  }}
                                  headerClasses={"custom-header-class"}
                                  rowStyle={{
                                    fontSize: "15px",
                                    fontFamily: "Gilroy",
                                    fontWeight: "400",
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  {...paginationProps}
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>

                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};



export default ActiveLog;

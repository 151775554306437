import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Route, Redirect } from "react-router-dom";
import { fetchForTheLockScreen, merchantVaulRegisterStatusCheck } from "Context/ContextApi";
import { useData } from "Context/ContextProvider";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  const { mData, fetchUserProfile } = useData();
  
  const [isLocked, setIsLocked] = useState(true);
  const [status, setStatus] = useState(true)
  useEffect(() => {
    const fetchRegisterStatus = async () => {
      try {
        const response = await merchantVaulRegisterStatusCheck();
        setStatus(response.result);
      } catch (error) {
        console.error(error);
      }
    };
    fetchRegisterStatus();
  }, []);

  useEffect(() => {
    const fetchLockStatus = async () => {
      try {
        const response = await fetchForTheLockScreen();
        setIsLocked(response.result); 
      } catch (error) {
        console.error(error);
      }
    };
    fetchLockStatus();
  }, []);



  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !localStorage.getItem("authUser") ) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        } else if (!isLocked && props.location.pathname !== "/auth-lock-screen") {
          return (
            <Redirect
              to={{ pathname: "/auth-lock-screen", state: { from: props.location } }}
            />
          );
        }
         else if (!status && props.location.pathname !== "/registration-form" ) {
          return (
            <Redirect
              to={{ pathname: "/registration-form", state: { from: props.location } }}
            />
          );
        };
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any
};

export default AppRoute;








// import React from "react"
// import PropTypes from 'prop-types'
// import { Route, Redirect } from "react-router-dom"
// let isLocked = ""
// const AppRoute = ({
//   component: Component,
//   layout: Layout,
//   isAuthProtected,
//   ...rest
// }) => (
//   <Route
//     {...rest}
//     render={props => {
//       if (isAuthProtected && !localStorage.getItem("authUser")&& !isLocked) {
//         return (
//           <Redirect
//             to={{ pathname: "/login", state: { from: props.location } }}
//           />
//         )
//       }
//       return (
//         <Layout>
//           <Component {...props} />
//         </Layout>
//       )
//     }}
//   />
// )

// AppRoute.propTypes = {
//   isAuthProtected: PropTypes.bool,
//   component: PropTypes.any,
//   location: PropTypes.object,
//   layout: PropTypes.any
// }

// export default AppRoute
